import React, { Fragment, ReactElement, useCallback, useEffect } from 'react';
import { Tab } from '@headlessui/react';
import {
  ShieldCheckIcon,
  BellIcon,
  BookOpenIcon,
} from '@heroicons/react/24/outline';

import { Notifications, Security, Tutorials } from './components';
import { Spinner } from 'src/ui/components';

import {
  useAuth,
  useGetStoredPermissions,
  useTitle,
  useProgressStore,
} from 'src/hooks';

import { classNames } from 'src/utils/className';
import { userPermissionStore } from 'src/store/userPermissionStore';
import { userOnBoardingStore } from 'src/store/userOnboardingStore';

type TabItemType = {
  label: string;
  icon: ReactElement;
  visible: boolean;
};

export default function Settings(): ReactElement {
  useTitle('Settings');

  const SpecifiedTwoFacterAuthMethod = localStorage.getItem(
    'SpecifiedTwoFacterAuthMethod',
  );

  const { isExternalUser } = useAuth();

  const permission = useGetStoredPermissions();

  const isUserPermissionLoading = userPermissionStore(
    useCallback(state => state.isLoading, []),
  );

  const updateAuthTypeFromSettings = userOnBoardingStore(
    useCallback(state => state.updateAuthTypeFromSettings, []),
  );

  const { mutations } = useProgressStore();

  const isSpinnerLoading =
    mutations.isResetPasswordLoading ||
    mutations.isChangeEmailLoading ||
    isUserPermissionLoading;

  /*security and notifications are available for external users only */
  const tabs: Array<TabItemType> = [
    {
      label: 'Security',
      icon: (
        <ShieldCheckIcon className="h-6 w-6 text-gray-400 dark:text-neutral-400" />
      ),
      visible: isExternalUser,
    },
    {
      label: 'Notifications',
      icon: (
        <BellIcon className="h-6 w-6 text-gray-400 dark:text-neutral-400" />
      ),
      visible: isExternalUser && permission?.CanViewDocumentTransmission,
    },
    {
      label: 'Tutorials',
      icon: (
        <BookOpenIcon className="h-6 w-6 text-gray-400 dark:text-neutral-400" />
      ),
      visible: true,
    },
  ];

  const visibleTabs = tabs.filter(tab => tab.visible === true).length;
  function renderTabList() {
    return (
      <div className={classNames(visibleTabs === 1 ? 'hidden md:block' : '')}>
        <Tab.List
          as="div"
          className="flex min-w-[200px] flex-row gap-2 overflow-x-auto rounded-lg bg-white p-1 shadow dark:bg-dark-dark md:flex-col md:p-2"
        >
          {tabs.map(
            (tabItem: TabItemType) =>
              tabItem?.visible && (
                <Tab
                  key={tabItem.label}
                  className={({ selected }) =>
                    classNames(
                      'group relative flex w-full min-w-max items-center justify-center gap-2 overflow-hidden rounded px-1 py-1.5 text-sm font-medium leading-5 md:justify-start md:px-2.5 md:py-2.5',
                      'before:absolute before:bottom-0 before:h-0.5 before:w-full md:before:left-0 md:before:top-0 md:before:h-full md:before:w-1',
                      'ring-white ring-opacity-60 ring-offset-2 ring-offset-light-focus focus:outline-none focus:ring-2 dark:ring-offset-dark-focus',
                      selected
                        ? 'bg-primary/[0.12] text-primary-600 before:bg-primary-500 dark:bg-primary/[0.25] dark:text-primary-300 [&>svg]:text-primary-600 dark:[&>svg]:text-primary-300'
                        : 'text-gray-800 hover:bg-gray-400/[0.12] dark:text-neutral-300 dark:hover:bg-black/[0.5]',
                    )
                  }
                >
                  {tabItem.icon}
                  <span>{tabItem.label}</span>
                </Tab>
              ),
          )}
        </Tab.List>
      </div>
    );
  }

  return (
    <main className="flex flex-1 flex-col overflow-y-auto overscroll-contain">
      <div className="flex items-center justify-between gap-4 border-t border-gray-200 bg-white px-2 py-1 shadow dark:border-neutral-700/50 dark:bg-neutral-800 sm:px-3 lg:px-4">
        <h2 className="truncate text-lg font-semibold text-gray-950 dark:text-white sm:text-xl sm:tracking-tight">
          Settings
        </h2>
      </div>
      <Tab.Group
        vertical={
          window.matchMedia('(min-width: 768px)').matches ? true : false
        }
        as="div"
        className={classNames(
          'container mx-auto grid h-full min-h-0 grid-cols-1  gap-2 p-2 sm:gap-4 sm:p-4 md:grid-cols-[auto_1fr] md:grid-rows-1 lg:gap-6 lg:p-6',
          visibleTabs === 1 ? 'grid-rows-[1fr]' : 'grid-rows-[auto_1fr]',
        )}
      >
        {renderTabList()}

        <>
          <Tab.Panels as={Fragment}>
            {isExternalUser && (
              <Tab.Panel>
                <Security />
              </Tab.Panel>
            )}

            {isExternalUser && permission?.CanViewDocumentTransmission && (
              <Tab.Panel>
                <Notifications />
              </Tab.Panel>
            )}
            <Tab.Panel className="h-full min-h-0 rounded-lg bg-white shadow dark:bg-dark-dark">
              <Tutorials />
            </Tab.Panel>
          </Tab.Panels>
        </>
      </Tab.Group>

      {isSpinnerLoading && (
        <div className="fixed inset-0 z-20 grid place-content-center">
          <Spinner size="large" />
        </div>
      )}
    </main>
  );
}
