import React, { ReactElement, SyntheticEvent, useRef } from 'react';
import {
  ArrowSmallDownIcon,
  ArrowSmallUpIcon,
  XMarkIcon,
} from '@heroicons/react/20/solid';
import { Disclosure } from '@headlessui/react';

import {
  Button,
  Select,
  TextField,
  MultiSelectCheckBox,
  MultiSelectInputType,
  DateRangePicker,
} from 'src/ui/components';

import { locList, sortList } from '../utils/callDocumentConstants';

import { CallDocumentFilterType } from '../types/filterOptionsType';

type PropsParamType = {
  filterOptions: CallDocumentFilterType;
  setValues: (type: keyof CallDocumentFilterType, value: string) => void;
  setCallTypes: (value: Array<MultiSelectInputType>) => void;
  setLoc: (value: Array<MultiSelectInputType>) => void;
  setTagStatus: (value: Array<MultiSelectInputType>) => void;
  tagStatusOptionList: Array<MultiSelectInputType> | undefined;
  callTypeOptionList: Array<MultiSelectInputType> | undefined;
  resetFilters: () => void;
};

function CallDocumentFilter({
  setValues,
  setLoc,
  setCallTypes,
  setTagStatus,
  filterOptions,
  tagStatusOptionList,
  callTypeOptionList,
  resetFilters,
}: PropsParamType): ReactElement {
  const wrapperRef = useRef<any>();

  function renderTextBoxes({
    title,
    placeholder,
    id,
    srOnlyLabel,
    key,
    value,
  }: {
    title: string;
    placeholder: string;
    id: string;
    key?: keyof CallDocumentFilterType;
    srOnlyLabel?: string;
    value?: string;
  }): ReactElement {
    return (
      <TextField
        inputSize="small"
        type="search"
        id={id}
        className="w-full sm:w-auto"
        placeholder={placeholder}
        title={title}
        srOnlyLabel={srOnlyLabel}
        onChange={(e: SyntheticEvent<HTMLInputElement>) =>
          key && setValues(key, e.currentTarget.value)
        }
        value={value}
      />
    );
  }

  function renderDatePicker({
    id,
    title,
    placeholder,
    key,
    value,
  }: {
    id: string;
    title: string;
    placeholder: string;
    key?: string;
    value?: string;
  }): ReactElement {
    return (
      <DateRangePicker
        rangeType="past"
        format="MM/DD/YYYY HH:mm:ss"
        size="small"
        placeholder={placeholder}
        value={value?.length ? value : []}
        setValues={setValues}
        keyValue={key}
        refferenceClass={wrapperRef}
      />
    );
  }

  function renderMultiSelectCheckbox({
    options,
    setValues,
    values,
    label,
  }: {
    options: Array<{ value: number; label: string | undefined }>;
    setValues: (values: Array<MultiSelectInputType>) => void;
    values: Array<MultiSelectInputType> | undefined;
    label: string;
  }): ReactElement {
    return (
      <MultiSelectCheckBox
        options={options}
        setSelectedOptions={setValues}
        selectedOptions={values}
        size="small"
        id={label?.toLocaleLowerCase().replaceAll(' ', '')}
        label={label}
      />
    );
  }

  function renderSort(): ReactElement {
    return (
      <div className="flex items-end gap-2">
        <Select
          inputSize="small"
          label="Sort by"
          id="filter-status"
          options={sortList}
          className="w-full sm:w-auto"
          onChange={e => {
            setValues('sortType', e.target.value);
          }}
          value={filterOptions?.sortType}
        />
        <Button
          variant={filterOptions?.sortOrder === 'asc' ? 'primary' : 'secondary'}
          size="small"
          onClick={() => setValues('sortOrder', 'asc')}
        >
          <ArrowSmallUpIcon className="h-5 w-5" aria-hidden="true" />
          <span className="sr-only">Sort in ascending order</span>
        </Button>
        <Button
          variant={
            filterOptions?.sortOrder === 'desc' ? 'primary' : 'secondary'
          }
          size="small"
          onClick={() => setValues('sortOrder', 'desc')}
        >
          <ArrowSmallDownIcon className="h-5 w-5" aria-hidden="true" />
          <span className="sr-only">Sort in descending order</span>
        </Button>
      </div>
    );
  }
  return (
    <Disclosure.Panel
      as="div"
      className="fixed bottom-0 left-0 top-0 z-10 flex w-full flex-col bg-white shadow dark:bg-neutral-800 sm:static sm:bottom-auto sm:left-auto sm:top-auto sm:block sm:w-auto"
    >
      {({ close }) => (
        <>
          <div className="flex items-center border-b border-b-light-light py-1 pe-1 ps-2 dark:border-b-dark-medium sm:hidden">
            <h3 className="grow text-lg font-medium">Filter</h3>
            <Button
              variant="subtle"
              onClick={() => {
                close();
              }}
            >
              <XMarkIcon className="h-5 w-5" />
            </Button>
          </div>
          <div
            className="relative min-h-0 grow overflow-y-auto p-2 pb-3 sm:overflow-y-visible sm:px-3 lg:px-4"
            ref={wrapperRef}
          >
            {renderSort()}
            <div
              className="my-4 border-b border-gray-200 dark:border-neutral-700/50"
              aria-hidden="true"
            />
            <div className="flex flex-wrap items-end gap-3">
              <div className="flex w-full flex-col gap-1 sm:w-auto">
                <label htmlFor="" className="text-xs font-medium">
                  Call ID - Case ID
                </label>
                {renderTextBoxes({
                  id: 'callId',
                  placeholder: 'Call ID',
                  title: 'Call Id',
                  srOnlyLabel: 'Call Id',
                  key: 'callId',
                  value: filterOptions?.callId,
                })}
                {renderTextBoxes({
                  id: 'caseId',
                  placeholder: 'Case ID',
                  title: 'Case Id',
                  srOnlyLabel: 'Case Id',
                  key: 'caseId',
                  value: filterOptions?.caseId,
                })}
              </div>
              <div className="flex w-full flex-col gap-1 sm:w-auto">
                <label htmlFor="" className="text-xs font-medium">
                  CID - PID
                </label>
                {renderTextBoxes({
                  id: 'cid',
                  placeholder: 'CID',
                  title: 'CID',
                  srOnlyLabel: 'CID',
                  key: 'cid',
                  value: filterOptions?.cid,
                })}
                {renderTextBoxes({
                  id: 'pid',
                  placeholder: 'PID',
                  title: 'PID',
                  srOnlyLabel: 'PID',
                  key: 'pid',
                  value: filterOptions?.pid,
                })}
              </div>
              <div className="flex w-full flex-col gap-1 sm:w-auto">
                <label htmlFor="" className="text-xs font-medium">
                  Date
                </label>
                {renderDatePicker({
                  id: 'startDate',
                  placeholder: 'Start date',
                  title: 'Start date',
                  key: 'startDate',
                  value: filterOptions.startDate,
                })}
                {renderDatePicker({
                  id: 'endDate',
                  placeholder: 'Uploaded date',
                  title: 'End date',
                  key: 'uploadedDate',
                  value: filterOptions.uploadedDate,
                })}
              </div>
              <div className="flex w-full flex-col gap-1 sm:w-auto">
                <label htmlFor="" className="text-xs font-medium">
                  Caller
                </label>
                {renderTextBoxes({
                  id: 'firstName',
                  placeholder: 'Caller first name',
                  title: 'Caller first name',
                  srOnlyLabel: 'Caller first name',
                  key: 'callerFirstName',
                  value: filterOptions.callerFirstName,
                })}
                {renderTextBoxes({
                  id: 'lastName',
                  placeholder: 'Caller last name',
                  title: 'Caller last name',
                  srOnlyLabel: 'Caller last name',
                  key: 'callerLastName',
                  value: filterOptions.callerLastName,
                })}
              </div>
              <div className="flex w-full flex-col gap-1 sm:w-auto">
                <label htmlFor="" className="text-xs font-medium">
                  Person of concern
                </label>
                {renderTextBoxes({
                  id: 'pocFirstName',
                  placeholder: 'POC first name',
                  title: 'POC first name',
                  srOnlyLabel: 'POC first name',
                  key: 'pocFirstName',
                  value: filterOptions.pocFirstName,
                })}
                {renderTextBoxes({
                  id: 'pocLastName',
                  placeholder: 'POC last name',
                  title: 'POC last name',
                  srOnlyLabel: 'POC last name',
                  key: 'pocLastName',
                  value: filterOptions.pocLastName,
                })}
              </div>
              <div className="flex w-full flex-col gap-1 sm:w-auto">
                <label htmlFor="calltype" className="text-xs font-medium">
                  Call type
                </label>
                {renderMultiSelectCheckbox({
                  options: callTypeOptionList ?? [],
                  setValues: setCallTypes,
                  values: filterOptions?.callType,
                  label: 'Call type',
                })}
              </div>
              <div className="flex w-full flex-col gap-1 sm:w-auto">
                <label htmlFor="levelofcare" className="text-xs font-medium">
                  Level of care
                </label>
                {renderMultiSelectCheckbox({
                  options: locList,
                  setValues: setLoc,
                  values: filterOptions?.levelOfCare,
                  label: 'Level of care',
                })}
              </div>
              <div className="flex w-full flex-col gap-1 sm:w-auto">
                <label
                  htmlFor="clinicalOutcome"
                  className="text-xs font-medium"
                >
                  Clinical outcome
                </label>
                {renderTextBoxes({
                  id: 'clinicalOutcome',
                  placeholder: 'Clinical outcome',
                  title: 'Clinical outcome',
                  srOnlyLabel: 'Clinical outcome',
                  key: 'clinicalOutcome',
                  value: filterOptions.clinicalOutcome,
                })}
              </div>
              <div className="flex w-full flex-col gap-1 sm:w-auto">
                <label htmlFor="" className="text-xs font-medium">
                  Sticky note - Tags
                </label>
                {renderTextBoxes({
                  id: 'stickyNotes',
                  placeholder: 'Sticky notes',
                  title: 'Sticky notes',
                  srOnlyLabel: 'Sticky notes',
                  key: 'stickyNotes',
                  value: filterOptions?.stickyNotes,
                })}
                <div>
                  {renderMultiSelectCheckbox({
                    options: tagStatusOptionList ?? [],
                    setValues: setTagStatus,
                    values: filterOptions?.tagStatus,
                    label: 'Select tag',
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-3 border-t border-t-light-light p-2 dark:border-b-dark-medium sm:hidden">
            <Button
              className="grow"
              variant="link"
              onClick={() => {
                resetFilters();
              }}
            >
              Reset
            </Button>

            <div className="flex gap-1">
              <Button
                className="grow"
                onClick={() => {
                  close();
                }}
              >
                Cancel
              </Button>
              <Button
                className="grow"
                variant="primary"
                onClick={() => {
                  close();
                }}
              >
                Apply
              </Button>
            </div>
          </div>
        </>
      )}
    </Disclosure.Panel>
  );
}
export { CallDocumentFilter };
