import React, { ReactElement, SyntheticEvent } from 'react';
import {
  ArrowSmallDownIcon,
  ArrowSmallUpIcon,
  XMarkIcon,
} from '@heroicons/react/20/solid';
import { Disclosure } from '@headlessui/react';

import { Select, TextField, CustomDatePicker, Button } from 'src/ui/components';

import {
  statusArray,
  formNameList,
  sortOptions,
} from '../utils/activityTrackerConstants';

import {
  ActivityTrackerFilterType,
  ActivityTrackerListType,
} from '../types/activityTrackerListType';

type ActivityLogFilterPageType = {
  filterOptions?: ActivityTrackerFilterType;
  setValues: (
    type: keyof ActivityTrackerFilterType,
    value: string | number,
  ) => void;
  setSelectedTracker: (item: ActivityTrackerListType | undefined) => void;
  resetFilters: () => void;
};

function ActivityTrackerFilter({
  filterOptions,
  setValues,
  setSelectedTracker,
  resetFilters,
}: ActivityLogFilterPageType): ReactElement {
  function renderSelectField({
    id,
    key,
    value,
    options,
    label,
  }: {
    id: string;
    key: keyof ActivityTrackerFilterType;
    value: number | string | undefined;
    options: Array<{ label: string; value: string | number }>;
    label: string;
  }) {
    return (
      <div className="flex w-full flex-col sm:w-auto">
        <label
          htmlFor=""
          className="block text-xs font-medium leading-6 text-gray-900 dark:text-white"
        >
          {label}
        </label>
        <Select
          inputSize="small"
          id="filter-status"
          className="w-full sm:w-auto"
          options={options}
          value={value}
          onChange={(e: SyntheticEvent<HTMLSelectElement>) => {
            setValues(key, e.currentTarget.value);
            setSelectedTracker(undefined);
          }}
        />
      </div>
    );
  }

  function renderDatePicker({
    id,
    key,
    value,
    title,
  }: {
    id: string;
    title: string;
    key?: string;
    value?: string | null | Date;
  }): ReactElement {
    return (
      <CustomDatePicker
        inputSize="small"
        placeholder="Submission date"
        id={id}
        value={value}
        onChange={e => {
          setValues('submittedOn', e);
          setSelectedTracker(undefined);
        }}
        hasMaxDate={true}
      />
    );
  }

  function renderTextField({
    label,
    id,
    value,
  }: {
    label: string;
    id: keyof ActivityTrackerFilterType;
    value: string | number | undefined;
  }) {
    return (
      <TextField
        inputSize="small"
        className="w-full sm:w-auto"
        type="search"
        label={label}
        placeholder={label}
        id={id}
        value={value}
        onChange={e => {
          setValues(id, e.target.value);
          setSelectedTracker(undefined);
        }}
      />
    );
  }

  function renderSort() {
    return (
      <div className="flex items-end gap-2">
        <Select
          inputSize="small"
          label="Sort by"
          id="filter-status"
          className="w-full sm:w-auto"
          options={sortOptions}
          value={filterOptions?.sortType}
          onChange={(e: SyntheticEvent<HTMLSelectElement>) =>
            setValues('sortType', e.currentTarget.value)
          }
        />
        <Button
          variant={filterOptions?.sortOrder === 'asc' ? 'primary' : 'secondary'}
          size="small"
          onClick={() => setValues('sortOrder', 'asc')}
        >
          <ArrowSmallUpIcon className="h-5 w-5" aria-hidden="true" />
          <span className="sr-only">Sort in ascending order</span>
        </Button>
        <Button
          variant={
            filterOptions?.sortOrder === 'desc' ? 'primary' : 'secondary'
          }
          size="small"
          onClick={() => setValues('sortOrder', 'desc')}
        >
          <ArrowSmallDownIcon className="h-5 w-5" aria-hidden="true" />
          <span className="sr-only">Sort in descending order</span>
        </Button>
      </div>
    );
  }

  return (
    <Disclosure.Panel
      as="div"
      className=" fixed bottom-0  left-0 top-0 z-10 flex w-full flex-col bg-white p-2 pb-3 shadow dark:bg-neutral-800 sm:static sm:left-auto sm:top-auto sm:block sm:w-auto  lg:px-4"
    >
      {({ close }) => (
        <>
          <div className="flex items-center  py-1 pe-1 ps-2  sm:hidden">
            <h3 className="grow text-lg font-medium">Filter</h3>
            <Button
              variant="subtle"
              onClick={() => {
                close();
              }}
            >
              <XMarkIcon className="h-5 w-5" />
            </Button>
          </div>

          <div className=' lg:px-4"> min-h-0 grow overflow-y-auto p-2 pb-3 sm:overflow-y-visible sm:px-3'>
            {renderSort()}
            <div
              className="my-4 border-b border-gray-200 dark:border-neutral-700/50"
              aria-hidden="true"
            ></div>
            <div className="flex flex-wrap gap-3">
              {renderTextField({
                label: 'Submission number',
                id: 'id',
                value: filterOptions?.id,
              })}
              {renderSelectField({
                id: 'formName',
                label: 'Form name',
                key: 'formName',
                options: formNameList,
                value: filterOptions?.formName,
              })}
              {renderTextField({
                label: 'First name',
                id: 'firstName',
                value: filterOptions?.firstName,
              })}
              {renderTextField({
                label: 'Last name',
                id: 'lastName',
                value: filterOptions?.lastName,
              })}
              <div className="flex w-full flex-col sm:w-auto">
                <label
                  htmlFor=""
                  className="block text-xs font-medium leading-6 text-gray-900 dark:text-white"
                >
                  Submission date
                </label>
                {renderDatePicker({
                  id: 'submittedOn',
                  key: 'submittedOn',
                  title: 'Submitted Date',
                  value: filterOptions?.submittedOn
                    ? new Date(filterOptions?.submittedOn)
                    : null,
                })}
              </div>
              {renderSelectField({
                id: 'Status',
                label: 'Status',
                key: 'status',
                options: statusArray,
                value: filterOptions?.status,
              })}
            </div>
          </div>
          <div className="flex flex-col gap-3 border-t border-t-light-light p-2 dark:border-b-dark-medium sm:hidden">
            <Button
              className="grow"
              variant="link"
              onClick={() => {
                resetFilters();
              }}
            >
              Reset
            </Button>
            <div className="flex gap-1">
              <Button
                className="grow"
                onClick={() => {
                  close();
                }}
              >
                Cancel
              </Button>
              <Button
                className="grow"
                variant="primary"
                onClick={() => {
                  close();
                }}
              >
                Apply
              </Button>
            </div>
          </div>
        </>
      )}
    </Disclosure.Panel>
  );
}

export { ActivityTrackerFilter };
