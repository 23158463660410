import React, {
  Fragment,
  ReactElement,
  useState,
  useCallback,
  useRef,
  useEffect,
} from 'react';
import { Menu, Transition } from '@headlessui/react';
import {
  EllipsisHorizontalIcon,
  ExclamationTriangleIcon,
  PencilIcon,
  PhoneIcon,
} from '@heroicons/react/20/solid';

import { Button } from 'src/ui/components';
import { PersonAlertModal } from './PersonAlerts';
import { ViewEditModal } from './ViewEditPersonDetails/ViewEditModal';
import { CallHistoryModal } from './CallHistoryModal';

import { classNames } from 'src/utils/className';

import { usePersonRepoStore } from '../store/personRepoStore';

import { useGetFetchRelatedCalls } from '../api/useGetFetchRelatedCalls';

import { SortDataType } from '../types/personAlertModalType';

function UserActions({
  showViewEditModal,
  setShowViewEditModal,
}: {
  showViewEditModal: boolean;
  setShowViewEditModal: (val: boolean) => void;
}): ReactElement {
  const [openAlertModal, setOpenAlertModal] = useState(false);
  const [openCallHistoryModal, setOpenCallHistoryModal] = useState(false);

  const setUserMode = usePersonRepoStore(
    useCallback(state => state.setUserMode, []),
  );

  const selectedPerson = usePersonRepoStore(
    useCallback(state => state.selectedPerson, []),
  );

  const tbodyRef = useRef() as React.MutableRefObject<HTMLTableSectionElement>;

  const [sortData, setSortData] = useState<SortDataType>({
    sortType: 0,
    sortOrder: 'desc',
    searchValue: '',
  });

  const {
    data,
    isLoading,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    isRefetching,
    isFetching,
  } = useGetFetchRelatedCalls(selectedPerson?.EntityId, sortData);

  const handleScroll = useCallback(
    (e: any) => {
      if (tbodyRef.current === e.target) {
        const bottom =
          e.target.clientHeight - 10 <
            e.target.scrollHeight - e.target.scrollTop &&
          e.target.scrollHeight - e.target.scrollTop <
            e.target.clientHeight + 10;
        if (bottom) {
          hasNextPage && fetchNextPage();
        }
      }
    },
    [fetchNextPage, hasNextPage],
  );
  useEffect(() => {
    window.addEventListener('scroll', handleScroll, true);
    return () => {
      window.removeEventListener('scroll', handleScroll, true);
    };
  }, [handleScroll]);

  const isSkeletonLoading = isLoading || isFetching;
  const isNonBlocking = isFetchingNextPage || isRefetching;
  return (
    <>
      <div className="relative hidden h-7 md:block">
        <div className="flex gap-x-4 gap-y-1 text-center">
          <Button
            variant="subtle"
            size="small"
            className="whitespace-nowrap"
            onClick={() => setOpenAlertModal(true)}
            disabled={!selectedPerson?.HasAlerts}
          >
            <ExclamationTriangleIcon
              className="-ml-0.5 h-5 w-5"
              aria-hidden="true"
            />
            Person alerts
          </Button>
          <Button
            variant="subtle"
            size="small"
            className="whitespace-nowrap"
            onClick={() => setOpenCallHistoryModal(true)}
            disabled={data?.pages[0]?.data?.length === 0 || isLoading}
          >
            <PhoneIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
            Call history
          </Button>
          <Button
            variant="subtle"
            size="small"
            className="whitespace-nowrap"
            onClick={() => {
              setShowViewEditModal(true);
              setUserMode('Edit');
            }}
          >
            <PencilIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
            View/Edit person details
          </Button>
        </div>
      </div>

      {/* responsive btns */}

      <Menu as="div" className="relative ml-3 md:hidden">
        <Menu.Button as={Button} variant="secondary" size="small">
          <EllipsisHorizontalIcon className="h-5 w-5" aria-hidden="true" />
        </Menu.Button>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-10 -mr-1 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-neutral-700">
            <>
              <Menu.Item
                as="a"
                className={classNames(
                  'block px-4 py-2 text-sm text-gray-800 aria-disabled:pointer-events-none aria-disabled:opacity-50 dark:text-white',
                )}
                onClick={() => setOpenAlertModal(true)}
                disabled={!selectedPerson?.HasAlerts}
              >
                Person alerts
              </Menu.Item>
              <Menu.Item
                as="a"
                className={classNames(
                  'block px-4 py-2 text-sm text-gray-800 aria-disabled:pointer-events-none aria-disabled:opacity-50 dark:text-white',
                )}
                onClick={() => setOpenCallHistoryModal(true)}
                disabled={data?.pages[0]?.data?.length === 0 || isLoading}
              >
                Call history
              </Menu.Item>
              <Menu.Item>
                <a
                  className={classNames(
                    // active ? 'bg-gray-100' : '',
                    'block px-4 py-2 text-sm text-gray-800 dark:text-white',
                  )}
                  onClick={() => setShowViewEditModal(true)}
                >
                  View/Edit person details
                </a>
              </Menu.Item>
            </>
          </Menu.Items>
        </Transition>
      </Menu>

      {showViewEditModal && (
        <ViewEditModal
          showModal={showViewEditModal}
          setShowModal={setShowViewEditModal}
        />
      )}

      {openAlertModal && (
        <PersonAlertModal
          openAlertModal={openAlertModal}
          setOpenAlertModal={setOpenAlertModal}
        />
      )}

      {/* Call history modal */}
      <CallHistoryModal
        openCallHistoryModal={openCallHistoryModal}
        setOpenCallHistoryModal={setOpenCallHistoryModal}
        sortData={sortData}
        setSortData={setSortData}
        data={data}
        tbodyRef={tbodyRef}
        isSkeletonLoading={isSkeletonLoading}
        isNonBlocking={isNonBlocking}
        isLoading={isLoading}
      />
    </>
  );
}

export { UserActions };
