import React, { ReactElement, useState, useEffect } from 'react';
import Select from 'react-select';
import Dropzone, { FileRejection } from 'react-dropzone';
import {
  DocumentTextIcon as DocumentTextIconLargeSolid,
  TrashIcon,
} from '@heroicons/react/24/solid';
import { Tab } from '@headlessui/react';

import { Button, TextField } from 'src/ui/components';
import { CreatableReactSelect } from 'src/ui/components/CreatableReactSelect';

import { useUploadFile } from '../api';

import { showSuccessToast } from 'src/utils/ToastNotification';
import {
  MAX_FILES,
  MAX_SECTIONS,
  dropzoneMsg,
  fileErrorMsg,
  fileFormatErrorMsg,
  fileUploadInfo,
  nameWarning,
  successMsg,
  sectionLimitInfoMsg,
  sectionLimitWarningMsg,
} from '../utils/constants';

import {
  SelectedOptionType,
  TutorialType,
  UploadType,
} from 'src/types/tutorialsType';

type UploadFileTabType = {
  setShowUploadModal: (val: boolean) => void;
  tutorialFiles: Array<TutorialType>;
  sections: Array<TutorialType>;
  files: any;
  setFiles: any;
  setSections: (
    value:
      | Array<TutorialType>
      | ((prevState: Array<TutorialType>) => Array<TutorialType>),
  ) => void;
  selectedOptions: SelectedOptionType | undefined;
  setSelectedOptions: (
    value:
      | SelectedOptionType
      | undefined
      | ((
          prevState: SelectedOptionType | undefined,
        ) => SelectedOptionType | undefined),
  ) => void;
  handleCreateOption: (inputValue: string) => TutorialType | undefined;
};

export function UploadFileTab({
  setShowUploadModal,
  tutorialFiles,
  sections,
  files,
  setFiles,
  setSections,
  selectedOptions,
  setSelectedOptions,
  handleCreateOption,
}: UploadFileTabType): ReactElement {
  const [warning, setWarning] = useState<string>('');

  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [simulatingProgress, setSimulatingProgress] = useState<boolean>(false);
  const [errorState, setErrorState] = useState({
    errorType: '',
    isError: false,
  });

  const { mutate: uploadFile } = useUploadFile();

  useEffect(() => {
    if (errorState.isError === true) {
      setShowUploadModal(true);
    }
  }, [errorState.isError, setShowUploadModal]);

  function simulateProgress() {
    setSimulatingProgress(true);
    const interval = setInterval(() => {
      setUploadProgress(prevProgress => {
        const currentProgress = prevProgress || 0;
        if (currentProgress < 100) {
          return currentProgress + 10;
        } else {
          clearInterval(interval);
          setSimulatingProgress(false);
          return currentProgress;
        }
      });
    }, 300);
  }

  function handleDrop(acceptedFiles: File[], rejectedFiles: FileRejection[]) {
    setWarning('');
    if (files.length + acceptedFiles.length > MAX_FILES) {
      setWarning(`You can only upload up to ${MAX_FILES} files.`);
      return;
    }
    const oversizedFiles = acceptedFiles.filter(
      (file: any) => file.size > 15 * 1024 * 1024,
    );
    if (rejectedFiles.length > 0) {
      setWarning(fileFormatErrorMsg);
      return;
    }
    if (oversizedFiles.length > 0) {
      setWarning(fileErrorMsg);
      return;
    }
    const isFileExists = tutorialFiles?.filter(item =>
      item.TutorialElements?.find(elemItem =>
        acceptedFiles?.find(
          accpetedItem => elemItem.Name === accpetedItem?.name,
        ),
      ),
    );
    if (isFileExists?.length) {
      setWarning(nameWarning);
      return;
    }
    const filesWithPreview = acceptedFiles.map(file =>
      Object.assign(file, { preview: URL.createObjectURL(file) }),
    );

    setFiles((prevFiles: any) => [...prevFiles, ...filesWithPreview]);
    setUploadProgress(0);
    simulateProgress();
  }

  function handleDragLeave(
    acceptedFiles: File[],
    rejectedFiles: FileRejection[],
  ) {
    // Same behavior as handleDrop, but triggered on drag leave event
    handleDrop(acceptedFiles, rejectedFiles);
  }

  function handleDragleaveEvent(e: any) {
    const items = e.dataTransfer?.items;
    if (items) {
      const acceptedFiles: File[] = [];
      const rejectedFiles: FileRejection[] = [];

      for (let i = 0; i < items.length; i++) {
        const item = items[i].getAsFile();
        if (item) {
          // Check if the file is a PDF and if it's within the size limit
          if (
            item.type === 'application/pdf' &&
            item.size <= 15 * 1024 * 1024
          ) {
            acceptedFiles.push(item);
          } else {
            rejectedFiles.push({
              file: item,
              errors: [
                {
                  code: 'file-invalid-type',
                  message: 'Invalid file type',
                },
              ],
            });
          }
        }
      }

      handleDragLeave(acceptedFiles, rejectedFiles);
    }
  }

  const handleCancel = () => {
    setFiles([]);
    setWarning('');
    setUploadProgress(0);
    setSimulatingProgress(false);
  };

  function handleFileChange() {
    const file = files[0];
    var fileName = file?.name;
    let fileModules = fileName.split('.');
    let fileExtension = fileModules[fileModules.length - 1];

    if (file.name != null && file.name != '' && file.size < 15728640) {
      let docParam: UploadType;
      let filePath = files[0].path;
      const reader = new FileReader();

      const totalDocsUploaded = tutorialFiles?.map(item =>
        item.TutorialElements?.map(elemItem => elemItem.URL === ''),
      ).length;

      const isFileExists = tutorialFiles?.filter(item =>
        item.TutorialElements?.find(elemItem => elemItem.Name === file?.name),
      );

      reader.onload = e => {
        if (tutorialFiles && totalDocsUploaded < 10) {
          if (!isFileExists?.length) {
            if (fileExtension === 'pdf') {
              const tutorialContainedSection = tutorialFiles?.find(
                item => item?.Name === selectedOptions?.label,
              )?.TutorialElements;

              docParam = {
                Title: fileName,
                Content: '',
                Container: '',
                StreamContent: reader?.result,
                SectionName: selectedOptions && selectedOptions?.label,
                SectionOrder: tutorialFiles?.length ? tutorialFiles?.length : 0,
                ElementOrder: tutorialContainedSection?.length
                  ? tutorialContainedSection?.length
                  : 0,
              };

              uploadFile(docParam, {
                onSuccess: () => {
                  showSuccessToast({ message: successMsg });
                  setFiles([]);
                },
              });
            } else {
              setErrorState({ errorType: fileFormatErrorMsg, isError: true });
            }
          } else {
            setErrorState({ errorType: nameWarning, isError: true });
          }
        }
      };
      reader.readAsDataURL(file);
    }
    setFiles([]);
  }

  function renderfileName() {
    return (
      <>
        <p id={files[0].name} className="text-balance text-xs">
          {files[0].name}
        </p>
        <Button
          variant="subtle-danger"
          size="small"
          onClick={e => {
            e.stopPropagation();
            handleCancel();
          }}
        >
          <TrashIcon className="size-5" />
        </Button>
      </>
    );
  }

  function renderProgressBar() {
    return (
      <>
        <div className="relative pt-1">
          <div className="mb-2 flex items-center justify-between">
            <div>
              <span className="inline-block rounded-full bg-primary-100 px-2 py-1 text-xs font-semibold uppercase text-primary-700 dark:bg-primary-900 dark:text-primary-200">
                {uploadProgress || 0}%
              </span>
            </div>
          </div>
          <div className="mb-2 flex h-2 overflow-hidden rounded bg-gray-200 text-xs dark:bg-neutral-600">
            <div
              style={{
                width: `${uploadProgress || 0}%`,
              }}
              className="flex flex-col bg-primary-500 text-center text-white"
            />
          </div>
        </div>
      </>
    );
  }

  function renderFooter() {
    return (
      <div className="flex gap-[1ch] bg-gray-100 p-2 px-2 py-4 dark:bg-neutral-700/50 sm:justify-end sm:p-3">
        {warning && (
          <p className="mt-2 text-balance text-xs text-red-600">{warning}</p>
        )}{' '}
        <Button
          variant="secondary"
          onClick={() => {
            setFiles([]);
            setShowUploadModal(false);
          }}
          className="grow sm:grow-0"
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          disabled={
            files?.length === 0 ||
            selectedOptions === undefined ||
            selectedOptions?.value === -1
          }
          onClick={() => {
            if (errorState.isError === false) {
              handleFileChange();
              setSections((prevOptions: any) => [
                ...prevOptions,
                selectedOptions,
              ]);
              setShowUploadModal(false);
            }
          }}
          className="grow sm:grow-0"
        >
          Upload
        </Button>
      </div>
    );
  }

  return (
    <Tab.Panel className="space-y-6">
      <div className="space-y-4 px-4">
        <Dropzone
          accept={{ 'application/pdf': [] }}
          onDrop={(acceptedFiles, rejectedFiles) => {
            handleDrop(acceptedFiles, rejectedFiles);
          }}
          onDragLeave={e => {
            handleDragleaveEvent(e);
          }}
        >
          {({ getRootProps, getInputProps }) => (
            <section className="mt-2 flex cursor-pointer justify-center rounded-lg border-2 border-dashed border-gray-500/25 bg-gray-50 px-4 py-6 text-center hover:border-primary-400 hover:bg-gray-200/40 dark:border-gray-200/25 dark:bg-neutral-800 dark:hover:border-primary-700 dark:hover:bg-neutral-700/50">
              <div {...getRootProps()} className="space-y-2">
                <input {...getInputProps()} />
                {!simulatingProgress && files && files.length === 0 && (
                  <>
                    <DocumentTextIconLargeSolid
                      aria-hidden="true"
                      className="mx-auto size-10 text-gray-300"
                    />

                    <p className="text-balance text-sm font-semibold">
                      {dropzoneMsg}
                    </p>
                    <p className="text-balance text-xs leading-5 text-gray-600 dark:text-neutral-400">
                      {fileUploadInfo}
                    </p>
                  </>
                )}

                {!simulatingProgress &&
                  files &&
                  files.length > 0 &&
                  renderfileName()}
              </div>
              {!warning && simulatingProgress && renderProgressBar()}
            </section>
          )}
        </Dropzone>
        <div className="space-y-1">
          <span>Add to</span>
          {sections?.length < 5 ? (
            <CreatableReactSelect
              value={selectedOptions}
              options={sections?.map((x: TutorialType) => ({
                label: x.Name,
                value: x.Id,
              }))}
              setSelectedOptions={(selectedOption: any) => {
                setSelectedOptions(selectedOption);
              }}
              onCreateOption={handleCreateOption}
            />
          ) : (
            <Select
              classNamePrefix="multiselect-dropdown"
              menuPlacement="top"
              options={sections?.map((x: TutorialType) => ({
                label: x.Name,
                value: x.Id,
              }))}
              closeMenuOnSelect={true}
              isSearchable={true}
              onChange={(e: any) => {
                setSelectedOptions(e);
              }}
            />
          )}
          <span className="inline-block text-xs text-gray-600 dark:text-neutral-400">
            {sections?.length < MAX_SECTIONS
              ? `${sectionLimitInfoMsg}`
              : `${sectionLimitWarningMsg}`}
          </span>
        </div>
      </div>
      {renderFooter()}
    </Tab.Panel>
  );
}
