import React, { Fragment, useEffect, useState } from 'react';

import { XMarkIcon } from '@heroicons/react/20/solid';
import { Transition, Dialog, Tab } from '@headlessui/react';

import { AddUrlTab, UploadFileTab } from './index';

import { classNames } from 'src/utils/className';
import { TutorialType, SelectedOptionType } from 'src/types/tutorialsType';

function AddTutorialModal({
  showUploadModal,
  setShowUploadModal,
  tutorialFiles,
  selectedOptions,
  setSelectedOptions,
}: {
  showUploadModal: boolean;
  setShowUploadModal: (val: boolean) => void;
  tutorialFiles: Array<TutorialType>;
  selectedOptions: SelectedOptionType | undefined;
  setSelectedOptions: (val: any) => void;
}) {
  const [sections, setSections] = useState<Array<TutorialType>>(tutorialFiles);
  const [files, setFiles] = useState<any>([]);
  const [url, setUrl] = useState<string>('');
  const [textDisplay, setTextDisplay] = useState<string>('');

  useEffect(() => {
    if (tutorialFiles && sections) {
      setSections(tutorialFiles);
    }
  }, [tutorialFiles, sections]);

  function handleCreateOption(inputValue: string) {
    const newOption = {
      Id: sections?.length + 1,
      IsActive: true,
      Name: inputValue.length > 100 ? inputValue.slice(0, 100) : inputValue,
      Order: 0,
      TutorialElements: [],
      UpdatedBy: '',
      UpdatedOn: '',
    };

    setSelectedOptions({ label: newOption?.Name, value: newOption?.Id });
    return newOption;
  }

  return (
    <>
      <Transition appear show={showUploadModal} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            setShowUploadModal(false);
            setUrl('');
            setTextDisplay('');
            setFiles([]);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="pr-dialog__backdrop" />
          </Transition.Child>

          <div className="pr-dialog__wrapper">
            <div className="pr-dialog__panel__container">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="pr-dialog__panel">
                  <div className="flex items-center justify-between gap-3 border-b p-4 dark:border-dark-medium">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6"
                    >
                      Add tutorial
                    </Dialog.Title>
                    <button
                      type="button"
                      className=" text-gray-400 hover:text-gray-500 dark:text-neutral-500 dark:hover:text-neutral-300 sm:right-4 sm:top-4"
                      onClick={() => {
                        setShowUploadModal(false);
                        setUrl('');
                        setTextDisplay('');
                        setFiles([]);
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>

                  <Tab.Group as="div" className="space-y-4">
                    <div className="px-4 pt-4">
                      <Tab.List className="flex space-x-1 rounded-xl bg-gray-200 p-1 dark:bg-neutral-900">
                        <Tab
                          className={classNames(
                            'w-full rounded-lg py-2 text-sm font-medium leading-5',
                            'ring-white/60 ring-offset-2 ring-offset-light-focus focus:outline-none focus:ring-2 dark:ring-primary/30 dark:ring-offset-dark-focus',
                            'ui-not-selected:text-gray-500 ui-not-selected:hover:bg-gray-100 ui-not-selected:hover:text-gray-700 dark:ui-not-selected:hover:bg-neutral-800/50 dark:ui-not-selected:hover:text-neutral-400',
                            'ui-selected:bg-white ui-selected:text-primary-700 ui-selected:shadow dark:ui-selected:bg-neutral-700 dark:ui-selected:text-neutral-300',
                          )}
                          onClick={() => setFiles([])}
                        >
                          Add URL
                        </Tab>
                        <Tab
                          className={classNames(
                            'w-full rounded-lg py-2 text-sm font-medium leading-5',
                            'ring-white/60 ring-offset-2 ring-offset-light-focus focus:outline-none focus:ring-2 dark:ring-primary/30 dark:ring-offset-dark-focus',
                            'ui-not-selected:text-gray-500 ui-not-selected:hover:bg-gray-100 ui-not-selected:hover:text-gray-700 dark:ui-not-selected:hover:bg-neutral-800/50 dark:ui-not-selected:hover:text-neutral-400',
                            'ui-selected:bg-white ui-selected:text-primary-700 ui-selected:shadow dark:ui-selected:bg-neutral-700 dark:ui-selected:text-neutral-300',
                          )}
                          onClick={() => {
                            setUrl('');
                            setTextDisplay('');
                          }}
                        >
                          Upload file
                        </Tab>
                      </Tab.List>
                    </div>
                    <Tab.Panels>
                      <AddUrlTab
                        setShowUploadModal={setShowUploadModal}
                        tutorialFiles={tutorialFiles}
                        sections={sections}
                        setSections={setSections}
                        url={url}
                        setUrl={setUrl}
                        textDisplay={textDisplay}
                        setTextDisplay={setTextDisplay}
                        selectedOptions={selectedOptions}
                        setSelectedOptions={setSelectedOptions}
                        handleCreateOption={handleCreateOption}
                      />
                      <UploadFileTab
                        setShowUploadModal={setShowUploadModal}
                        tutorialFiles={tutorialFiles}
                        sections={sections}
                        files={files}
                        setFiles={setFiles}
                        setSections={setSections}
                        selectedOptions={selectedOptions}
                        setSelectedOptions={setSelectedOptions}
                        handleCreateOption={handleCreateOption}
                      />
                    </Tab.Panels>
                  </Tab.Group>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

export { AddTutorialModal };
