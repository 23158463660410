import React, { ReactElement, useCallback } from 'react';
import { useNavigate } from 'react-router';
import dayjs from 'dayjs';
import { useQueryClient } from 'react-query';

import { TooltipItem, Badge } from 'src/ui/components';

import { useExtracoverageRequestStore } from 'src/features/AddEditExtraCoverageRequest/store/extracoverageStore';
import { activeStyles } from '../utils/ExtraCoverageConstants';
import { convertTimeToPT } from 'src/features/AddEditExtraCoverageRequest/utils/coverageRequestHelper';

import { ExtraCoverageType } from '../types/extraCoverageRequestLists';
import { Timezone } from 'src/types/timezone';

type ALertListType = {
  coverageData: ExtraCoverageType;
  handleRowClick: (val: ExtraCoverageType) => void;
  selectedExtraCoverage: ExtraCoverageType | null;
  permission: any;
  ptTimeZone: Timezone | undefined;
};

function ExtraCoverageListItem({
  coverageData,
  handleRowClick,
  selectedExtraCoverage,
  permission,
  ptTimeZone,
}: ALertListType): ReactElement {
  const navigate = useNavigate();
  const queryClient: any = useQueryClient();

  const updateStoreByKey = useExtracoverageRequestStore(
    useCallback(state => state.updateStoreByKey, []),
  );
  const deleteCoverageDetails = useExtracoverageRequestStore(
    useCallback(state => state.deleteCoverageDetails, []),
  );

  return (
    <>
      <div
        tabIndex={0}
        onClick={() => {
          queryClient.invalidateQueries(['add-edit-coverage-request'], {
            refetchActive: false,
          });
          handleRowClick(coverageData);
        }}
        onDoubleClick={() => {
          queryClient.invalidateQueries(['add-edit-coverage-request'], {
            refetchActive: false,
          });
          deleteCoverageDetails();
          updateStoreByKey('isSaveClicked', false);
          permission?.CanApproveCoverageRequests &&
          (permission?.NonClinicalAdministrator || permission?.Administrators)
            ? navigate(`/ExtraCoverageRequests/Approve/${coverageData?.Id}`)
            : (coverageData?.ExtraCoverageRequestStatus === null ||
                coverageData?.ExtraCoverageRequestStatus?.Description ===
                  'New') &&
              navigate(`/ExtraCoverageRequests/Approve/${coverageData?.Id}`);
        }}
        className={`relative flex flex-wrap rounded bg-white shadow focus-visible:outline focus-visible:outline-[3px] focus-visible:outline-offset-[3px] focus-visible:outline-blue-600 dark:bg-neutral-800 dark:focus-visible:outline-blue-400 ${
          selectedExtraCoverage?.Id === coverageData?.Id ? activeStyles : ''
        }`}
      >
        <div className="flex flex-grow flex-wrap gap-2 p-2 lg:justify-between">
          <div className="min-w-[90px] basis-1/2 md:basis-[31%] lg:basis-[10%] ">
            <label className="block whitespace-nowrap text-[10px] font-medium uppercase tracking-wide opacity-70">
              EXTRA COVERAGE
            </label>
            <p className="text-xs font-bold tracking-wide text-black dark:text-white">
              {coverageData?.Id}
            </p>
          </div>
          {permission?.AllProtocallUsers && (
            <div className="min-w-[230px] basis-1/2 md:basis-[31%] lg:basis-[20%] ">
              <label className="block whitespace-nowrap text-[10px] font-medium uppercase tracking-wide opacity-70">
                APPROVER
              </label>
              <p className="break-words text-xs font-bold tracking-wide text-black dark:text-white">
                {coverageData?.Approver}
              </p>
            </div>
          )}

          <div className="min-w-[65px] basis-1/2 md:basis-[31%] lg:basis-[8%] ">
            <label className="block whitespace-nowrap text-[10px] font-medium uppercase tracking-wide opacity-70">
              STATUS
            </label>
            <p className="text-xs font-bold tracking-wide text-black dark:text-white">
              {coverageData?.ExtraCoverageRequestStatus?.Description}
            </p>
          </div>
          <div className="min-w-[55px] basis-1/2 md:basis-[31%] lg:basis-[8%] ">
            {coverageData?.Accounts?.length === 1 ? (
              <>
                {' '}
                <label className="block whitespace-nowrap text-[10px] font-medium uppercase tracking-wide opacity-70">
                  ACCOUNTS
                </label>
                <p className="mt-0.5 truncate text-xs font-bold tracking-wide text-black dark:text-white">
                  {coverageData?.Accounts[0]?.CID
                    ? coverageData?.Accounts[0]?.CID
                    : ''}
                </p>
              </>
            ) : (
              <TooltipItem
                label="ACCOUNTS"
                values={
                  coverageData?.Accounts?.length !== 0
                    ? coverageData?.Accounts?.map((item: any) => item.CID).join(
                        ', ',
                      )
                    : ''
                }
              />
            )}
          </div>
          <div className="min-w-[80px] basis-1/2 md:basis-[31%] lg:basis-[10%] ">
            <label className="block whitespace-nowrap text-[10px] font-medium uppercase tracking-wide opacity-70">
              COVERAGE DATE
            </label>
            <p className="text-xs font-bold tracking-wide text-black dark:text-white">
              {coverageData?.ExtraCoverageDate != null
                ? dayjs(coverageData?.ExtraCoverageDate).format('MM/DD/YYYY')
                : ''}
            </p>
          </div>
          <div className="min-w-[60px] basis-1/2 md:basis-[31%] lg:basis-[8%]">
            <label className="block whitespace-nowrap text-[10px] font-medium uppercase tracking-wide opacity-70">
              START TIME
            </label>
            <p className="text-xs font-bold tracking-wide text-black dark:text-white">
              {coverageData?.StartTime != null
                ? convertTimeToPT(
                    dayjs(coverageData?.ExtraCoverageDate).format('MM/DD/YYYY'),
                    coverageData?.StartTime.substring(0, 5),
                    coverageData?.TimeZone,
                    ptTimeZone,
                  )
                : ''}
            </p>
          </div>
          <div className="min-w-[60px] basis-1/2 md:basis-[31%] lg:basis-[8%] ">
            <label className="block whitespace-nowrap text-[10px] font-medium uppercase tracking-wide opacity-70">
              END TIME
            </label>
            <p className="text-xs font-bold tracking-wide text-black dark:text-white">
              {coverageData?.EndTime != null
                ? convertTimeToPT(
                    dayjs(coverageData?.ExtraCoverageDate).format('MM/DD/YYYY'),
                    coverageData?.EndTime.substring(0, 5),
                    coverageData?.TimeZone,
                    ptTimeZone,
                  )
                : ''}
            </p>
          </div>
          <div className="min-w-[135px] basis-1/2 md:basis-[31%] lg:basis-[11%] ">
            <label className="block whitespace-nowrap text-[10px] font-medium uppercase tracking-wide opacity-70">
              SUBMITTED ON
            </label>
            <p className="text-xs font-bold tracking-wide text-black dark:text-white">
              {coverageData?.SubmittedDate != null
                ? dayjs(coverageData?.SubmittedDate).format(
                    'MM/DD/YYYY H:mm:ss',
                  )
                : ''}
            </p>
          </div>
          {permission?.AllProtocallUsers && (
            <div className="flex min-w-[135px] basis-1/2 flex-wrap items-start justify-start gap-1 md:basis-[31%] lg:basis-[12%] ">
              {coverageData?.EmailSent === true ? (
                <Badge variant="info" className="whitespace-nowrap">
                  Email sent
                </Badge>
              ) : null}
              {coverageData?.AccountAlertPosted === true ? (
                <Badge variant="success" className="whitespace-nowrap">
                  Account alert posted
                </Badge>
              ) : null}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export { ExtraCoverageListItem };
