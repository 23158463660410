import React from 'react';
import Creatable, { useCreatable } from 'react-select/creatable';

import { MultiSelectInputType } from 'src/features/CallDocuments/types/callDocumentList';

type MultiSelectInput = {
  options: Array<MultiSelectInputType> | undefined;
  size?: string;
  label?: string;
  id?: string;
  setSelectedOptions?: (e: any) => void;
  onChange?: any;
  onCreateOption?: any;
  value?: any;
};

function CreatableReactSelect({
  options,
  setSelectedOptions,
  size,
  id,
  onChange,
  onCreateOption,
  value,
}: MultiSelectInput) {
  return (
    <Creatable
      id={id}
      options={options}
      value={value}
      closeMenuOnSelect={true}
      isSearchable={true}
      onChange={e => {
        setSelectedOptions && setSelectedOptions(e);
      }}
      onCreateOption={onCreateOption}
      classNamePrefix="multiselect-dropdown"
      className={
        size === 'small'
          ? 'multiselect-dropdown multiselect-dropdown--small'
          : 'multiselect-dropdown'
      }
      menuPlacement="top"
    />
  );
}

export { CreatableReactSelect };
