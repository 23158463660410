import React, { ReactElement, useCallback, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { DocumentTextIcon } from '@heroicons/react/20/solid';

import { Badge, Button } from 'src/ui/components';
import { ActivityTrackerScheduleCall } from './ActivityTrackerScheduleCall';

import { useAuth } from 'src/hooks';

import { statusArray } from '../utils/activityTrackerConstants';
import { getFormName, varientName } from '../utils/activityTrackerHelperFn';
import { activeStyles } from 'src/utils/appConstants';

import { useActivityStore } from '../store/activityTrackerStore';

import { ActivityTrackerListType } from '../types/activityTrackerListType';

type ActivityLogItemType = {
  activityTracker: ActivityTrackerListType;
  handleRowClick: (item: ActivityTrackerListType) => void;
  selectedTracker: ActivityTrackerListType | undefined;
  onPreview: (activityTracker: ActivityTrackerListType) => void;
};

function ActivityTrackerListItem({
  activityTracker,
  handleRowClick,
  selectedTracker,
  onPreview,
}: ActivityLogItemType): ReactElement {
  const [sliderOpenStatus, setSliderOpenStatus] = useState(false);
  const getScheduledCallDetails = useActivityStore(
    useCallback(state => state.getScheduledCallDetails, []),
  );
  const { token, tokenType } = useAuth();
  //get the form Status
  const formStatus = useMemo(() => {
    if (statusArray) {
      return statusArray?.find(
        item => item?.value === activityTracker?.FormStatus,
      )?.label;
    }
  }, [activityTracker?.FormStatus]);

  return (
    <div
      tabIndex={0}
      className={`relative flex flex-nowrap rounded bg-white shadow focus-visible:outline focus-visible:outline-[3px] focus-visible:outline-offset-[3px] focus-visible:outline-blue-600 dark:bg-neutral-800 dark:focus-visible:outline-blue-400 ${
        selectedTracker?.Id === activityTracker?.Id ? activeStyles : ''
      }`}
      onClick={() => handleRowClick(activityTracker)}
    >
      <div className="flex flex-grow flex-wrap justify-between gap-4 px-4 py-3">
        <div className="basis-[48%] md:basis-[30%] lg:basis-[10%]">
          <label className="block min-w-[42px]  whitespace-nowrap text-[10px] font-medium uppercase tracking-wide opacity-70">
            Submission Number
          </label>
          <p className="prevent-text-breakout break-words text-xs font-bold tracking-wide text-black dark:text-white">
            {activityTracker?.Id}
          </p>
        </div>

        <div className="basis-[48%] md:basis-[30%] lg:basis-[21%]">
          <label className="block min-w-[42px] whitespace-nowrap text-[10px] font-medium uppercase tracking-wide opacity-70">
            Form Name
          </label>
          <p className="prevent-text-breakout break-words text-xs font-bold tracking-wide text-black dark:text-white">
            {getFormName(activityTracker?.AccountId)}
          </p>
        </div>

        <div className="basis-[48%] md:basis-[30%] lg:basis-1/6">
          <label className="block min-w-[42px] whitespace-nowrap text-[10px] font-medium uppercase tracking-wide opacity-70">
            First Name
          </label>
          <p className="prevent-text-breakout break-words text-xs font-bold tracking-wide text-black dark:text-white">
            {activityTracker?.FirstName}
          </p>
        </div>
        <div className="basis-[48%] md:basis-[30%] lg:basis-1/6">
          <label className="block min-w-[42px] whitespace-nowrap text-[10px] font-medium uppercase tracking-wide opacity-70">
            Last Name
          </label>
          <p className="prevent-text-breakout break-words text-xs font-bold tracking-wide text-black dark:text-white">
            {activityTracker?.LastName}
          </p>
        </div>
        <div className="basis-[48%] md:basis-[30%] lg:basis-1/6">
          <label className="block min-w-[42px] whitespace-nowrap text-[10px] font-medium uppercase tracking-wide opacity-70">
            Submission Date
          </label>
          <p className="prevent-text-breakout break-words text-xs font-bold tracking-wide text-black dark:text-white">
            {' '}
            {activityTracker?.SubmittedOn != null
              ? dayjs(activityTracker?.SubmittedOn).format(
                  'MM/DD/YYYY HH:mm:ss',
                )
              : ''}
          </p>
        </div>
        <div className="flex basis-[48%] items-center md:basis-[30%] lg:basis-1/12 lg:justify-center">
          {formStatus !== 'All' &&
            (formStatus !== 'Pending' ? (
              <Badge
                variant={varientName(formStatus)}
                className="whitespace-nowrap"
              >
                {formStatus}
              </Badge>
            ) : null)}
        </div>
      </div>
      <div className="flex items-center justify-center border-l p-2 dark:border-neutral-700">
        <Button
          variant="subtle"
          size="small"
          title="Preview"
          className="grow"
          onClick={e => {
            e.stopPropagation();
            onPreview(activityTracker);
          }}
        >
          <DocumentTextIcon className="h-5 w-5" aria-hidden="true" />
          <span className="sr-only">Preview</span>
        </Button>
      </div>
      <ActivityTrackerScheduleCall
        sliderOpenStatus={sliderOpenStatus}
        setSliderOpenStatus={setSliderOpenStatus}
      />
    </div>
  );
}

export { ActivityTrackerListItem };
