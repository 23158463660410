import React, { ReactElement, Fragment, useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { Menu, Transition } from '@headlessui/react';
import { useNavigate } from 'react-router-dom';

import { useAuth, AuthInfo } from 'src/hooks';
import { AuthServiceSingleton } from 'src/utils/authenticationHelper/authService';
import { cancelWarningMsg } from 'src/utils/appConstants';
import { classNames } from '../utils/getClassNames';

import { useSaveChangesParamStore } from 'src/store/useSaveChangesParamStore';

export default function Profile(): ReactElement {
  const { userInfo }: AuthInfo = useAuth();
  const queryClient = useQueryClient();

  const userName: string =
    userInfo &&
    userInfo['http://schemas.microsoft.com/identity/claims/displayname'];

  const navigate = useNavigate();

  function getInitials(): string | undefined {
    const firstName = userInfo?.['given_name'];
    const lastName = userInfo?.['family_name'];

    if (firstName && lastName)
      return (firstName.charAt(0) + lastName.charAt(0)).toUpperCase();

    return firstName?.substring(0, 2).toUpperCase();
  }

  const isDirty = useSaveChangesParamStore(
    useCallback(state => state.isDirty, []),
  );
  const setIsDirty = useSaveChangesParamStore(
    useCallback(state => state.setIsDirty, []),
  );
  const setAllowFetch = useSaveChangesParamStore(
    useCallback(state => state.setAllowFetch, []),
  );

  const setIsSettingsPage = useSaveChangesParamStore(
    useCallback(state => state?.setIsSettingsPage, []),
  );

  const clearStore = useSaveChangesParamStore(
    useCallback(state => state.clearStore, []),
  );

  //navigating conditionally
  function customNavigation(event: any) {
    if (isDirty) {
      event.preventDefault();
      const confirmLeave = window.confirm(cancelWarningMsg);
      if (confirmLeave) {
        setIsSettingsPage(true);
        navigate('/Settings');
        setIsDirty(false);
        clearStore();
        setIsSettingsPage(false);
        queryClient.invalidateQueries(['add-edit-person-alert'], {
          refetchActive: false,
        });
        queryClient.invalidateQueries(['create-edit-user'], {
          refetchActive: false,
        });
        queryClient.invalidateQueries(['add-edit-coverage-request'], {
          refetchActive: false,
        });
      }
    } else {
      setIsDirty(false);
      navigate('/Settings');
    }
  }

  function signOutNavigation(event: any) {
    if (isDirty) {
      event.preventDefault();
      const confirmLeave = window.confirm(cancelWarningMsg);
      if (confirmLeave) {
        setIsSettingsPage(true);
        window.localStorage.clear();
        AuthServiceSingleton.getInstance().logout();
        setAllowFetch(false);
        clearStore();
      } else {
        setAllowFetch(true);
      }
    } else {
      window.localStorage.clear();
      AuthServiceSingleton.getInstance().logout();
      setAllowFetch(false);
    }
  }

  return (
    <Menu as="div" className="relative ml-3">
      <div>
        <Menu.Button
          title={userName}
          className="flex h-8 w-8 items-center justify-center rounded-full bg-gray-200 focus-visible:outline focus-visible:outline-[3px] focus-visible:outline-offset-[3px] focus-visible:outline-light-focus dark:bg-neutral-700 dark:focus-visible:outline-dark-focus"
        >
          <span className="sr-only">Open user menu</span>

          <span className="text-base font-semibold text-teal-600">
            {userName ? getInitials() : ''}
          </span>
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-20 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-neutral-700">
          <div className="border-b border-light-light px-4 py-2 pb-3 text-sm dark:border-neutral-600">
            Signed in as{' '}
            <span className="inline-block max-w-full truncate align-top font-semibold">
              {userName}
            </span>
          </div>
          <Menu.Item>
            {({ active }) => (
              <a
                className={classNames(
                  active ? 'bg-neutral-300 dark:bg-neutral-600' : '',
                  'block cursor-pointer px-4 py-2 text-sm text-neutral-700 dark:text-neutral-300',
                )}
                onClick={e => {
                  setIsSettingsPage(false);
                  customNavigation(e);
                }}
              >
                Settings
              </a>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <a
                className={classNames(
                  active ? 'bg-neutral-300 dark:bg-neutral-600' : '',
                  'block cursor-pointer px-4 py-2 text-sm text-neutral-700 dark:text-neutral-300',
                )}
                onClick={e => {
                  setIsSettingsPage(false);
                  signOutNavigation(e);
                }}
              >
                Sign out
              </a>
            )}
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
