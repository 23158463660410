import React, { ReactElement, SyntheticEvent } from 'react';
import {
  ArrowSmallDownIcon,
  ArrowSmallUpIcon,
  XMarkIcon,
} from '@heroicons/react/20/solid';
import { Disclosure } from '@headlessui/react';

import {
  Button,
  MultiSelectCheckBox,
  Select,
  TextField,
} from 'src/ui/components';

import { sortOptions, statusOptions } from '../utils/sortLists';

import { FilterOptionsType } from '../types/usersListType';
import { MultiSelectInputType } from 'src/types/MultiSelectInputType';

type UserFilterType = {
  setValues: (type: keyof FilterOptionsType, value: string) => void;
  setStatus: (value: Array<MultiSelectInputType>) => void;
  setPermission: (value: Array<MultiSelectInputType>) => void;
  filterOptions: FilterOptionsType;
  resetFilters: () => void;
  permissions: Array<{ label: string; value: number }> | undefined;
};

function UserFilter({
  setValues,
  setPermission,
  setStatus,
  filterOptions,
  resetFilters,
  permissions,
}: UserFilterType): ReactElement {
  function renderSort() {
    return (
      <div className="flex items-end gap-2">
        <Select
          inputSize="small"
          label="Sort by"
          id="filter-status"
          className="w-full sm:w-auto"
          options={sortOptions}
          value={filterOptions?.sortType}
          onChange={(e: SyntheticEvent<HTMLSelectElement>) =>
            setValues('sortType', e.currentTarget.value)
          }
        />
        <Button
          variant={filterOptions.sortOrder === 'asc' ? 'primary' : 'secondary'}
          size="small"
          onClick={() => setValues('sortOrder', 'asc')}
        >
          <ArrowSmallUpIcon className="h-5 w-5" aria-hidden="true" />
          <span className="sr-only">Sort in ascending order</span>
        </Button>
        <Button
          variant={filterOptions.sortOrder === 'desc' ? 'primary' : 'secondary'}
          size="small"
          onClick={() => setValues('sortOrder', 'desc')}
        >
          <ArrowSmallDownIcon className="h-5 w-5" aria-hidden="true" />
          <span className="sr-only">Sort in descending order</span>
        </Button>
      </div>
    );
  }

  function renderTextField({
    label,
    id,
    placeholder,
    value,
    key,
  }: {
    label: string;
    id: string;
    placeholder: string;
    value: string | number;
    key: keyof FilterOptionsType;
  }) {
    return (
      <TextField
        inputSize="small"
        type="search"
        className="w-full sm:w-auto"
        label={label}
        id={id}
        placeholder={placeholder}
        value={value}
        onChange={(e: SyntheticEvent<HTMLInputElement>) =>
          setValues(key, e.currentTarget.value)
        }
      />
    );
  }

  return (
    <Disclosure.Panel
      as="div"
      className="fixed bottom-0 left-0 top-0 z-10 flex w-full flex-col bg-white shadow dark:bg-neutral-800 sm:static sm:bottom-auto sm:left-auto sm:top-auto sm:block sm:w-auto"
    >
      {({ close }) => (
        <>
          <div className="flex items-center border-b border-b-light-light py-1 pe-1 ps-2 dark:border-b-dark-medium sm:hidden">
            <h3 className="grow text-lg font-medium">Filter</h3>
            <Button
              variant="subtle"
              onClick={() => {
                close();
              }}
            >
              <XMarkIcon className="h-5 w-5" />
            </Button>
          </div>
          <div className="min-h-0 grow overflow-y-auto p-2 pb-3 sm:overflow-y-visible sm:px-3 lg:px-4">
            {renderSort()}
            <div
              className="my-4 border-b border-gray-200 dark:border-neutral-700/50"
              aria-hidden="true"
            ></div>
            <div className="flex flex-wrap gap-3">
              {renderTextField({
                label: 'User name',
                id: 'username',
                placeholder: 'User name',
                value: filterOptions.userName,
                key: 'userName',
              })}
              {renderTextField({
                label: 'First name',
                id: 'first-name',
                placeholder: 'First name',
                value: filterOptions.firstName,
                key: 'firstName',
              })}
              {renderTextField({
                label: 'Last name',
                id: 'last-name',
                placeholder: 'Last name',
                value: filterOptions.lastName,
                key: 'lastName',
              })}
              {renderTextField({
                label: 'CID',
                id: 'cid',
                placeholder: 'CID',
                value: filterOptions.cid,
                key: 'cid',
              })}
              {renderTextField({
                label: 'PID',
                id: 'pid',
                placeholder: 'PID',
                value: filterOptions.pid,
                key: 'pid',
              })}
              <div className="flex w-full flex-col gap-1 sm:w-auto">
                <label htmlFor="calltype" className="text-xs font-medium">
                  Permission
                </label>
                <MultiSelectCheckBox
                  options={permissions ?? []}
                  setSelectedOptions={setPermission}
                  selectedOptions={filterOptions?.permission}
                  size="small"
                  id="permission"
                  label="permission"
                />
              </div>
              <div className="flex w-full flex-col gap-1 sm:w-auto">
                <label htmlFor="calltype" className="text-xs font-medium">
                  Status
                </label>
                <MultiSelectCheckBox
                  options={statusOptions ?? []}
                  setSelectedOptions={setStatus}
                  selectedOptions={filterOptions?.status}
                  size="small"
                  id="status"
                  label="status"
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-3 border-t border-light-light p-2 dark:border-dark-medium sm:hidden">
            <Button
              className="grow"
              variant="link"
              onClick={() => {
                resetFilters();
              }}
            >
              Reset
            </Button>
            <div className="flex gap-1">
              <Button
                className="grow"
                onClick={() => {
                  close();
                }}
              >
                Cancel
              </Button>
              <Button
                className="grow"
                variant="primary"
                onClick={() => {
                  close();
                }}
              >
                Apply
              </Button>
            </div>
          </div>
        </>
      )}
    </Disclosure.Panel>
  );
}

export { UserFilter };
