import React, { ReactElement, useRef, useState } from 'react';
import * as TooltipRadix from '@radix-ui/react-tooltip';
import { InformationCircleIcon } from '@heroicons/react/20/solid';

export type TooltipType = {
  trigger: string | ReactElement;
  content: string | ReactElement;
  delay?: number;
};

export function Tooltip({
  trigger,
  content,
  delay = 0,
}: TooltipType): ReactElement {
  const [open, setOpen] = useState(false);

  return (
    <>
      <TooltipRadix.Provider>
        <TooltipRadix.Root
          open={open}
          delayDuration={delay}
          onOpenChange={() => setOpen && setOpen(!open)}
        >
          <TooltipRadix.Trigger
            asChild
            onBlur={() => setOpen && setOpen(false)}
            onClick={e => {
              setOpen && setOpen(true);
            }}
          >
            {trigger}
          </TooltipRadix.Trigger>
          <TooltipRadix.Portal>
            <TooltipRadix.Content className="z-[100] select-none rounded border border-gray-400 bg-white px-3 py-2 text-sm shadow-xl dark:border-transparent dark:bg-neutral-700">
              <div className="max-h-60 max-w-[300px] overflow-y-auto">
                {content}
              </div>
              <TooltipRadix.Arrow className="fill-gray-400 dark:fill-neutral-700" />
            </TooltipRadix.Content>
          </TooltipRadix.Portal>
        </TooltipRadix.Root>
      </TooltipRadix.Provider>
    </>
  );
}
