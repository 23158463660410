import React, {
  DragEvent,
  ReactElement,
  useState,
  Fragment,
  useRef,
  useEffect,
  useMemo,
} from 'react';
import { usePopper } from 'react-popper';
import { XMarkIcon, PlusIcon } from '@heroicons/react/20/solid';
import { Transition, Dialog } from '@headlessui/react';

import { Alert, Button, EmptyState, Spinner } from 'src/ui/components';
import { TutorialsSection, AddTutorialModal } from './index';

import { useAuth, useGetDownloadTutorial, useProgressStore } from 'src/hooks';
import { useGetStoredPermissions } from 'src/hooks';
import { usePostSaveOrder, useGetTutorials } from '../api';

import {
  fileDoesNotExistMsg,
  fileErrorMsg,
  MAX_FILES,
  fileLimitInfoMsg,
  fileLimitWarningMsg,
} from '../utils/constants';
import { classNames } from 'src/utils/className';
import { base64ToArrayBuffer } from 'src/utils/convertToBinaryFormat';
import { showErrorToast, showSuccessToast } from 'src/utils/ToastNotification';

import { SelectedOptionType } from 'src/types/tutorialsType';
import { DownloadFileType, GetTutorials } from 'src/types/tutorialsType';

export function Tutorials(): ReactElement {
  const { mutations } = useProgressStore();
  const {
    data: tutorialFiles,
    isLoading: isTutorialsLoading,
    refetch,
  } = useGetTutorials();
  const { mutate: downloadData, isLoading: isDownloading } =
    useGetDownloadTutorial();
  const { mutate: saveOrders, isLoading: isSavingOrder } = usePostSaveOrder();

  const permission = useGetStoredPermissions();
  const { isExternalUser } = useAuth();

  const [showModal, setShowModal] = useState<boolean>(false);
  const [errorState, setErrorState] = useState({
    errorType: '',
    isError: false,
  });
  const [fileError, setFileError] = useState(false);
  const [data, setData] = useState<GetTutorials>();
  const [showUploadModal, setShowUploadModal] = useState<boolean>(false);
  const [selectedOptions, setSelectedOptions] = useState<SelectedOptionType>();
  //mobile section drag and drop
  const [draggingIndex, setDraggingIndex] = useState<any>(null);
  const [hoverIndex, setHoverIndex] = useState(null);
  let [referenceElement, setReferenceElement] = useState<any>(null);
  let [popperElement, setPopperElement] = useState<any>(null);
  const [arrowElement, setArrowElement] = useState<any>(null);

  const sectionRefs: any = useRef([]);

  const isSpinnerLoading =
    mutations.isUploading ||
    mutations.isDeleting ||
    isDownloading ||
    isTutorialsLoading ||
    isSavingOrder ||
    mutations.isPostOrderSaving;

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [{ name: 'arrow', options: { element: arrowElement } }],
    placement: 'top',
  });

  useEffect(() => {
    if (tutorialFiles) setData(tutorialFiles);
  }, [tutorialFiles]);

  const disabledcondition = useMemo(() => {
    const totalLength = data?.reduce(
      (acc, obj) =>
        acc + (obj.TutorialElements ? obj.TutorialElements.length : 0),
      0,
    );
    if (totalLength) return totalLength;
    else return 0;
  }, [data]);

  function handleDownloadDoc(documentName: string) {
    downloadData(documentName, {
      onSuccess: (data: DownloadFileType) => {
        if (
          data?.Status === 'Success' &&
          data?.FileContent !== null &&
          data?.FileName !== null &&
          data?.FileContent !== '' &&
          data?.FileName !== '' &&
          data?.FileName !== undefined &&
          data?.FileContent !== undefined
        ) {
          let docFile = base64ToArrayBuffer(data?.FileContent);
          let blob = new Blob([docFile], {
            type: 'pdf',
          });

          const link = document.createElement('a');
          const url = window.URL.createObjectURL(blob);
          link.href = url;
          const fileName = data?.FileName;
          link.download = fileName;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
          refetch();
        } else {
          showErrorToast({
            message: fileDoesNotExistMsg,
          });
        }
      },
      onError: (errorMsg: any) => {
        showErrorToast({ message: errorMsg?.message });
      },
    });
  }

  //function for handling  save order
  function saveOrder() {
    const params = data?.map((item, index) => ({
      sectionName: item?.Name,
      sectionOrder: index,
      NewSectionName: '',
      elementOrders: item?.TutorialElements?.map((tItem, tIndex) => ({
        elementName: tItem?.Name,
        elementOrder: tIndex,
        NewElementName: '',
      })),
    }));

    saveOrders(params, {
      onSuccess: () => {
        showSuccessToast({ message: 'Your changes were saved successfully.' });
      },
    });
  }

  // Section Drag and Drop Handlers
  function handleSectionDragStart(
    e: DragEvent<HTMLDivElement>,
    sectionId: any,
  ) {
    if (isExternalUser) return;
    e.dataTransfer.setData('draggedSectionId', sectionId);
  }

  function handleSectionDrop(e: DragEvent<HTMLDivElement>, dropSectionId: any) {
    if (isExternalUser) return;
    e.preventDefault(); // Prevent default behavior
    const draggedSectionId = parseInt(
      e.dataTransfer.getData('draggedSectionId'),
    );
    const draggedSectionIndex =
      data?.findIndex(section => section.Id === draggedSectionId) || 0;
    const dropSectionIndex =
      data?.findIndex(section => section.Id === dropSectionId) || 0;
    const updatedData = data ? [...data] : [];
    const [draggedSection] = updatedData.splice(draggedSectionIndex, 1);

    updatedData.splice(dropSectionIndex, 0, draggedSection);
    const newData = updatedData?.map((item, index) => ({
      ...item,
      Order: index,
    }));
    setData(newData);
  }

  const handleTouchStart = (e: any, index: number) => {
    if (isExternalUser) return;
    setDraggingIndex(index);
  };

  const handleTouchMove = (e: any) => {
    if (isExternalUser) return;
    if (draggingIndex === null) return;
    const touch = e.targetTouches[0];

    // Calculate hover index based on touch Y position
    let newHoverIndex = null;
    sectionRefs.current.forEach((ref: any, idx: any) => {
      const rect = ref?.getBoundingClientRect();
      if (touch.clientY > rect.top && touch.clientY < rect.bottom) {
        newHoverIndex = idx;
      }
    });
    setHoverIndex(newHoverIndex);
  };

  const handleTouchEnd = () => {
    if (isExternalUser) return;
    if (
      draggingIndex !== null &&
      hoverIndex !== null &&
      hoverIndex !== draggingIndex
    ) {
      const newSections = data ? [...data] : [];
      const draggedItem = newSections.splice(draggingIndex, 1)[0];
      newSections.splice(hoverIndex, 0, draggedItem);
      setData(newSections);
    }
    setDraggingIndex(null);
    setHoverIndex(null);
  };

  // Subsection Drag and Drop Handlers
  function handleSubDragStart(
    e: DragEvent<HTMLDivElement>,
    sectionId: any,
    subSectionId: any,
  ) {
    if (isExternalUser) return;
    e.dataTransfer.setData('sectionId', sectionId);
    e.dataTransfer.setData('subId', subSectionId);
  }

  function handleSubDrop(
    e: DragEvent<HTMLDivElement>,
    sectionId: any,
    index: any,
  ) {
    if (isExternalUser) return;
    const draggedSectionId = parseInt(e.dataTransfer.getData('sectionId'));
    const draggedSubsectionId = parseInt(e.dataTransfer.getData('subId'));

    if (draggedSectionId !== sectionId) {
      return; // Prevent dragging between different sections
    }

    const section = data?.find(section => section.Id === sectionId);
    const draggedItemIndex: any = section?.TutorialElements.findIndex(
      sub => sub.Id === draggedSubsectionId,
    );
    const [draggedItem]: any = section?.TutorialElements.splice(
      draggedItemIndex,
      1,
    );
    section?.TutorialElements.splice(index, 0, draggedItem);

    let tempData = data;
    tempData?.forEach(item => {
      item.TutorialElements.forEach((detail, index) => {
        detail.Order = index;
      });
    });

    if (data) setData(tempData);
  }

  function fileExistModal(): ReactElement {
    return (
      <Transition appear show={errorState.isError || fileError} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => setShowModal(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="relative w-full max-w-md transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all dark:bg-neutral-800">
                  <Dialog.Title
                    as="h3"
                    className="pl-4 pr-16 pt-4 font-medium leading-6 text-gray-900 dark:text-white"
                  >
                    {errorState.isError === true
                      ? errorState.errorType
                      : fileError == true
                      ? fileErrorMsg
                      : ''}
                  </Dialog.Title>
                  <button
                    type="button"
                    className="absolute right-4 top-4 text-gray-400 hover:text-gray-500 sm:right-4 sm:top-4"
                    onClick={() => {
                      setFileError(false);
                      setErrorState({ errorType: '', isError: false });
                    }}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                  <div className="flex gap-[1ch] bg-gray-50 p-2 px-2 py-4 dark:bg-neutral-700/50 sm:justify-end sm:p-3">
                    <Button
                      variant="primary"
                      onClick={() => {
                        setErrorState({ errorType: '', isError: false });
                        setFileError(false);
                      }}
                      className="grow sm:grow-0"
                    >
                      Ok
                    </Button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    );
  }

  return (
    <div className="flex h-full flex-col divide-y divide-light-light dark:divide-dark-medium">
      {fileExistModal()}
      {isSpinnerLoading && (
        <div className="bg-gray-900/8  absolute inset-0 z-50 grid   place-items-center  backdrop-blur-sm">
          <Spinner size="large" />
        </div>
      )}
      <h3 className="px-3 py-2 text-xl font-medium md:px-4 md:py-3">
        Tutorials
      </h3>
      <div
        className={classNames(
          'min-h-0 grow space-y-3 overflow-auto p-3',
          data?.length === 0 ? 'grid place-content-center' : '',
        )}
      >
        {data &&
          data?.map((section, sectionIndex) => (
            <TutorialsSection
              key={section.Id}
              section={section}
              index={sectionIndex}
              handleSectionDragStart={handleSectionDragStart}
              handleSectionDrop={handleSectionDrop}
              handleSubDragStart={handleSubDragStart}
              handleSubDrop={handleSubDrop}
              handleTouchStart={handleTouchStart}
              handleTouchMove={handleTouchMove}
              handleTouchEnd={handleTouchEnd}
              handleDownloadDoc={handleDownloadDoc}
              data={data}
              sectionRefs={sectionRefs}
              setData={setData}
            />
          ))}
        {!isTutorialsLoading && tutorialFiles?.length === 0 && (
          <EmptyState message="No information available" />
        )}
      </div>

      {permission?.AdministratorUsers && (
        <>
          <div className="flex flex-col gap-[1ch] rounded-b-lg bg-gray-50 p-4 dark:bg-neutral-700/50 sm:justify-end sm:p-3 md:flex-row">
            <Alert variant="info">
              {disabledcondition >= MAX_FILES
                ? `${fileLimitWarningMsg}`
                : `${fileLimitInfoMsg}`}
            </Alert>

            <div className="flex gap-[1ch]">
              <Button
                variant="primary"
                className="grow md:grow-0"
                disabled={disabledcondition >= MAX_FILES}
                onClick={() => {
                  setShowUploadModal(true);
                  setSelectedOptions({ label: '', value: -1 });
                }}
              >
                <PlusIcon className="size-5" />
                <span>Add tutorial</span>
              </Button>
              <Button
                variant="primary"
                className="grow md:grow-0"
                onClick={() => saveOrder()}
              >
                <span>Save</span>
              </Button>
            </div>
          </div>
          <AddTutorialModal
            showUploadModal={showUploadModal}
            setShowUploadModal={setShowUploadModal}
            tutorialFiles={data || []}
            selectedOptions={selectedOptions}
            setSelectedOptions={setSelectedOptions}
          />
        </>
      )}
    </div>
  );
}
