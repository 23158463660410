import { create } from 'zustand';
import { produce } from 'immer';

import {
  fetchCountryDetails,
  fetchPersonAlertsDetails,
} from './personAlertApiStore';
import { useSaveChangesParamStore } from 'src/store/useSaveChangesParamStore';
import { fetchCaller } from 'src/store/apiStore/fetchCaller';

import { formatDateToRange } from 'src/utils/datepickerHelper';
import { commonFormatterFunction } from '../utils/dataComparisonHelper';
import { addressInfo, convertToNumber } from '../utils/personStoreHelper';
import {
  calculateAgeAsString,
  countryOptions,
  formattedPhoneNumber,
  getComments,
  isPhnIsInvalidOrNot,
  isTinymceEditorContentEmpty,
  isUsPhoneNumber,
} from 'src/utils/common';

import { AccountType } from 'src/types/AccountInfo';
import { GetPersonAlertsType } from '../types/personAlertsAccounts';
import { PersonAlertStore } from '../types/personAlertUser';
import { AddressType } from '../types/findPersonInformation';

export const usePersonAlertStore = create<PersonAlertStore>((set, get) => ({
  alertDetails: {
    alertDate: '',
    Reasons: [],
    Person: {},
    CounselorOrCaseManager: '',
    SpecialProgramsParticipant: '',
    SituationDescription: '',
    TelephoneInterventionDescription: '',
  },
  countryInfo: [],
  accountsInfo: undefined,
  userMode: '',
  isEditApiLoading: false,
  hasCallLimit: false,
  isCallLimitExist: false,
  isPersonInformationLoading: false,
  isSaveClicked: false,
  showPrimaryNumberError: false,
  showSecondaryNumberError: false,
  initialAlert: {},
  currentData: {},
  newPerson: false,
  personApiCalled: false,

  updateAlertDetails: (key: string, value: any) => {
    set(
      produce(state => {
        state.alertDetails = { ...state.alertDetails, [key]: value };
      }),
    );
  },

  //updating person object values
  updatePersonDetails: (key: string, value: string) => {
    set(
      produce(state => {
        state.alertDetails.Person = {
          ...state.alertDetails.Person,
          [key]: value,
        };
      }),
    );
  },

  //to update Primaryaddress and phone details of person object
  updateOtherDetails: (
    key: string,
    value: any,
    PersonDetailKey: string | undefined,
  ) => {
    set(
      produce(state => {
        if (PersonDetailKey !== '') {
          if (
            state.alertDetails?.Person?.PersonAlertDetails === undefined ||
            state.alertDetails?.Person?.PersonAlertDetails === null
          ) {
            state.alertDetails.Person = {
              ...state?.alertDetails?.Person,
            };
            state.alertDetails.Person.PersonAlertDetails = {
              ...state?.alertDetails?.Person?.PersonAlertDetails,
              [`${PersonDetailKey}`]: {
                [key]: value,
              },
            };
          } else {
            state.alertDetails.Person.PersonAlertDetails[`${PersonDetailKey}`] =
              {
                ...state?.alertDetails?.Person?.PersonAlertDetails[
                  `${PersonDetailKey}`
                ],
                [key]: value,
              };
          }
        } else {
          state.alertDetails.Person.PersonAlertDetails = {
            ...state?.alertDetails?.Person?.PersonAlertDetails,
            [key]: value,
          };
        }
      }),
    );
  },

  //searched address data binding
  updateAddressDetails: (item: AddressType) => {
    const address = addressInfo(item);
    set(
      produce(state => {
        if (state?.alertDetails?.Person?.PersonAlertDetails === undefined) {
          state.alertDetails.Person = {
            ...state?.alertDetails?.Person,
          };
          state.alertDetails.Person.PersonAlertDetails = {
            ['PrimaryAddress']: {
              ...address,
            },
          };
        } else {
          state.alertDetails.Person.PersonAlertDetails.PrimaryAddress = {
            ...address,
          };
        }
      }),
    );
  },

  //searched person data binding
  updatePersonInformation: async (
    token: string,
    tokenType: string,
    accountId: number | undefined,
    personId: number,
  ) => {
    if (accountId && personId) {
      set(
        produce(state => {
          state.isPersonInformationLoading = true;
        }),
      );

      const response = await fetchCaller({
        token,
        tokenType,
        accountId,
        personId,
      });
      const countryList = countryOptions(get().countryInfo);
      const primaryPhone = response?.PrimaryPhone;
      const secondaryPhone = response?.SecondaryPhone;

      set(
        produce(state => {
          state.isPersonInformationLoading = false;
          state.showPrimaryNumberError = primaryPhone.Number
            ? isPhnIsInvalidOrNot(
                primaryPhone.Number,
                primaryPhone.CountryId ?? 1,
                countryList,
              )
            : false;
          state.showSecondaryNumberError = isPhnIsInvalidOrNot(
            secondaryPhone.Number,
            secondaryPhone.CountryId ?? 1,
            countryList,
          );

          state.alertDetails.Person = {
            ...response,
          };

          if (response?.PersonAlertDetails === null) {
            state.alertDetails.Person.PersonAlertDetails = {
              ['PrimaryAddress']: { ...response?.PrimaryAddress },
              ['PrimaryPhone']: {
                ...response?.PrimaryPhone,
                Number: setPhoneNumber(
                  primaryPhone?.Number,
                  primaryPhone?.CountryId,
                ),
                CountryId: primaryPhone?.CountryId ?? 1,
                Comments: getComments(
                  primaryPhone,
                  primaryPhone?.CountryId,
                  get().countryInfo,
                ),
              },
              ['SecondaryPhone']: {
                ...response?.SecondaryPhone,
                Number: setPhoneNumber(
                  secondaryPhone?.Number,
                  secondaryPhone?.CountryId,
                ),
                CountryId: secondaryPhone?.CountryId ?? 1,
                Comments: getComments(
                  secondaryPhone,
                  secondaryPhone?.CountryId,
                  get().countryInfo,
                ),
              },
              ['Age']: response?.Age,
              ['Birthdate']: response?.Birthdate
                ? new Date(response?.Birthdate)
                : '',
            };
          } else {
            state.alertDetails.Person.PersonAlertDetails.Birthdate =
              response?.Birthdate ? new Date(response?.Birthdate) : '';
            state.alertDetails.Person.PersonAlertDetails.PrimaryPhone = {
              ...response.PrimaryPhone,
              Number: setPhoneNumber(
                primaryPhone?.Number,
                primaryPhone?.CountryId,
              ),
              CountryId: primaryPhone?.CountryId ?? 1,
              Comments: getComments(
                primaryPhone,
                primaryPhone?.CountryId,
                get().countryInfo,
              ),
              //id key cannot be modified
              Id: response?.PersonAlertDetails?.PrimaryPhone?.Id,
            };

            state.alertDetails.Person.PersonAlertDetails.SecondaryPhone = {
              ...response.SecondaryPhone,
              Number: setPhoneNumber(
                response?.SecondaryPhone?.Number,
                response.SecondaryPhone?.CountryId,
              ),
              Comments: getComments(
                secondaryPhone,
                secondaryPhone?.CountryId,
                get().countryInfo,
              ),
              CountryId: response?.SecondaryPhone?.CountryId ?? 1,
              Id: response?.PersonAlertDetails?.SecondaryPhone?.Id,
            };
          }

          if (response?.PersonCallLimit !== null) {
            state.hasCallLimit = true;
          }
        }),
      );
    }
  },

  //edit alert data binding
  getPersonAlerts: async ({
    token,
    tokenType,
    accountId,
    id,
  }: Partial<GetPersonAlertsType>) => {
    const countryCode = await fetchCountryDetails({ token, tokenType });
    set(
      produce(state => {
        state.countryInfo = countryCode;
      }),
    );

    if (
      (get().userMode === 'Edit' &&
        useSaveChangesParamStore.getState().isDirty === false) ||
      useSaveChangesParamStore.getState().isSettingsPage === true
    ) {
      set(
        produce(state => {
          state.isEditApiLoading = true;
        }),
      );

      const response = await fetchPersonAlertsDetails({
        token: token,
        tokenType: tokenType,
        accountId: convertToNumber(accountId),
        id: convertToNumber(id),
      });

      if (response) {
        set(
          produce(state => {
            state.accountsInfo = response?.Person?.Account;
            state.alertDetails = {
              ...response,
              alertDate: formatDateToRange(
                response?.StartDate,
                response?.EndDate,
              ),
            };

            state.hasCallLimit = response?.Person?.PersonCallLimit !== null;
            state.isCallLimitExist = response?.Person?.PersonCallLimit !== null;
            if (response?.Person?.Birthdate) {
              state.alertDetails.Person.PersonAlertDetails.Birthdate = new Date(
                response?.Person?.Birthdate,
              );
            }
            // If `countryid` is null, then fill the `comments` field with the `phonenumber` from both primary and secondary.
            const primaryPhone = response?.Person?.PrimaryPhone;
            const secondaryPhone = response?.Person?.SecondaryPhone;

            if (primaryPhone) {
              state.alertDetails.Person.PersonAlertDetails.PrimaryPhone.Comments =
                getComments(
                  primaryPhone,
                  primaryPhone?.CountryId,
                  get().countryInfo,
                );
            }
            if (secondaryPhone) {
              state.alertDetails.Person.PersonAlertDetails.SecondaryPhone.Comments =
                getComments(
                  secondaryPhone,
                  secondaryPhone?.CountryId,
                  get().countryInfo,
                );
            }

            //automatic calculation of age when birthdate is there and age is missing from api
            if (
              (response?.Person?.Birthdate !== '' ||
                response?.Person?.Birthdate !== null) &&
              (response?.Person?.Age === '' ||
                response?.Person?.Age === null) &&
              response?.Person?.Age !== null &&
              response?.Person?.Birthdate !== null
            ) {
              const age = calculateAgeAsString(response?.Person?.Birthdate);
              state.alertDetails.Person.PersonAlertDetails.Age = age;
            }
          }),
        );
      }
      const storeValues = usePersonAlertStore.getState();
      const val = commonFormatterFunction(storeValues);
      set(
        produce(state => {
          state.initialAlert = val;
        }),
      );
    }
    if (
      get().userMode !== 'Edit' &&
      (useSaveChangesParamStore.getState().isDirty === false ||
        useSaveChangesParamStore.getState().isSettingsPage === true)
    ) {
      const storeValues = usePersonAlertStore.getState();
      const val = commonFormatterFunction(storeValues);
      set(
        produce(state => {
          state.initialAlert = val;
        }),
      );
    }

    set(
      produce(state => {
        state.isEditApiLoading = false;
        state.personApiCalled = true;
      }),
    );
  },

  checkDataHasChanged: () => {
    const store = usePersonAlertStore.getState();

    set({
      currentData: commonFormatterFunction(store),
    });

    if (Object.keys(get().initialAlert)?.length === 0) return true;

    if (get().personApiCalled === false && get().userMode === 'Edit') {
      useSaveChangesParamStore.getState().setIsDirty(false);
      return false;
    }

    if (
      JSON.stringify(get().currentData) === JSON.stringify(get().initialAlert)
    ) {
      useSaveChangesParamStore.getState().setIsDirty(false);
      return true;
    } else if (Object.keys(get().initialAlert).length > 0) {
      useSaveChangesParamStore.getState().setIsDirty(true);
      return false;
    } else if (
      get().userMode !== 'Edit' &&
      Object.keys(get().initialAlert).length === 0
    ) {
      useSaveChangesParamStore.getState().setIsDirty(false);
      const store = usePersonAlertStore.getState();
      set({
        initialAlert: commonFormatterFunction(store),
      });
      return false;
    } else {
      return false;
    }
  },

  updateAccountInfo: (value: AccountType | undefined) => {
    set(
      produce(state => {
        state.accountsInfo = value;
      }),
    );
  },

  updateUserMode: (value: string) => {
    set(
      produce(state => {
        state.userMode = value;
      }),
    );
  },

  setNewPerson: (val: boolean) => {
    set(
      produce(state => {
        state.newPerson = val;
      }),
    );
  },

  setSaveClicked: (value: boolean) => {
    set(
      produce(state => {
        state.isSaveClicked = value;
      }),
    );
  },

  enableAlertTabs: (type: string): boolean => {
    switch (type) {
      case 'accounts-tab':
        return get().accountsInfo !== undefined;
      case 'personInformation-tab':
        return (
          get().alertDetails?.alertDate !== undefined &&
          get().alertDetails?.alertDate !== '' &&
          get().alertDetails?.Person?.FirstName !== undefined &&
          get().alertDetails?.Person?.FirstName !== '' &&
          get().alertDetails?.Person?.LastName !== undefined &&
          get().alertDetails?.Person?.LastName !== ''
        );
      case 'alert-tab':
        return (
          get().alertDetails?.SituationDescription !== undefined &&
          get().alertDetails?.SituationDescription !== '' &&
          !isTinymceEditorContentEmpty(
            get().alertDetails?.SituationDescription,
          ) &&
          (get().hasCallLimit === false ||
            (get()?.hasCallLimit === true &&
              get().alertDetails?.Person?.PersonCallLimit?.MaxCallCount !==
                undefined &&
              get().alertDetails?.Person?.PersonCallLimit?.MaxCallCount !==
                '' &&
              get().alertDetails?.Person?.PersonCallLimit?.MaxCallCount > 0 &&
              get().alertDetails?.Person?.PersonCallLimit?.ResetInterval !==
                undefined &&
              get().alertDetails?.Person?.PersonCallLimit?.ResetInterval !==
                '' &&
              get().alertDetails?.Person?.PersonCallLimit?.TimeZoneId !== '' &&
              get().alertDetails?.Person?.PersonCallLimit?.TimeZoneId !==
                undefined &&
              get().alertDetails?.Person?.PersonCallLimit?.TimeZoneId !== '-1'))
        );
      default:
        return false;
    }
  },

  updateCallLimit: (key: string, value: any) => {
    set(
      produce(state => {
        if (state.alertDetails?.Person === undefined) {
          state.alertDetails.Person = {
            ...state?.alertDetails?.Person,
          };
          state.alertDetails.Person['PersonCallLimit'] = {
            [key]: value,
          };
        } else {
          state.alertDetails.Person.PersonCallLimit = {
            ...state?.alertDetails?.Person?.PersonCallLimit,
            [key]: value,
          };
        }
      }),
    );
  },

  updateHasCallLimit: (value: boolean) => {
    set(
      produce(state => {
        state.hasCallLimit = value;

        if (value === false) {
          if (state.alertDetails.Person !== undefined) {
            state.alertDetails.Person.PersonCallLimit = null;
          } else
            state.alertDetails.Person = {
              ...state?.alertDetails?.Person,
            };
        }
      }),
    );
  },

  //setting the state values in to empty
  deleteAlertDetails: () => {
    set((state: any) => {
      return {
        ...state,
        alertDetails: {
          Person: undefined,
          alertDate: '',
          StartDate: '',
          EndDate: '',
          Reasons: [],
        },
        hasCallLimit: false,
        isSaveClicked: false,
        accountsInfo: undefined,
        showPrimaryNumberError: false,
        showSecondaryNumberError: false,
        initialAlert: {},
        newPerson: false,
        personApiCalled: false,
      };
    });
  },

  deletePersonDetails: () => {
    set((state: any) => {
      return {
        ...state,
        alertDetails: {
          ...state.alertDetails,
          Person: {},
        },
      };
    });
  },

  disableSave: (): boolean => {
    const alertDetails = get().alertDetails;
    if (
      alertDetails?.StartDate === undefined ||
      alertDetails?.EndDate === undefined ||
      alertDetails?.Person?.FirstName === '' ||
      alertDetails?.Person?.FirstName === undefined ||
      alertDetails?.Person?.LastName === undefined ||
      alertDetails?.Person?.LastName === '' ||
      alertDetails.SituationDescription == '' ||
      alertDetails.SituationDescription == undefined
    )
      return true;
    return false;
  },

  updatePhnNumberError: (key: string, value: boolean) => {
    set(() => ({
      [key]: value,
    }));
  },

  setPersonApiCalled: (val: boolean) => {
    set(
      produce(state => {
        state.personApiCalled = val;
      }),
    );
  },
}));

function setPhoneNumber(phnNumber: any, countryId: any) {
  if (countryId === null) return formattedPhoneNumber(phnNumber, 1);
  return phnNumber;
}
