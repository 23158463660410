import React, { ReactElement, useCallback } from 'react';

import {
  DateOfBirthPicker,
  Select,
  TextField,
  inputLabelClasses,
} from 'src/ui/components';

import { useGetGenders } from '../../../api';

import { usePersonRepoStore } from '../../../store/personRepoStore';
import {
  formatSelectOptionWithoutSelect,
  shouldDisableDate,
} from 'src/utils/common';
import { minDobDate } from 'src/utils/datepickerHelper';

import { TextFieldType } from 'src/types/textFileType';

export function PersonDetails({ refValue }: { refValue: any }) {
  const { data: genderList } = useGetGenders();

  const genders = formatSelectOptionWithoutSelect(
    genderList,
    'Id',
    'Description',
    true,
    true,
  );

  const personDetails = usePersonRepoStore(
    useCallback(state => state.personDetails, []),
  );

  const updatePersonDetails = usePersonRepoStore(
    useCallback(state => state.updatePersonDetails, []),
  );

  const enableSaveBtn = usePersonRepoStore(
    useCallback(state => state.enableSaveBtn, []),
  );

  const isSaveClicked = usePersonRepoStore(
    useCallback(state => state.isSaveClicked, []),
  );

  const userMode = usePersonRepoStore(useCallback(state => state.userMode, []));

  function handleClose(value: any) {
    if (
      value instanceof Date &&
      !isNaN(value.getTime()) &&
      !shouldDisableDate(value)
    ) {
      return;
    } else {
      updatePersonDetails('Birthdate', null, '');
    }
  }

  function renderTextField({
    id,
    value,
    key,
    label,
    type,
    className,
    maxLength,
  }: TextFieldType): ReactElement {
    return (
      <TextField
        id={id}
        label={label}
        type={type}
        className={className}
        disabled={userMode === 'Edit'}
        required={key === 'FirstName' || key === 'LastName'}
        value={value}
        onChange={e => {
          key && updatePersonDetails(key, e.target.value, '');
        }}
        maxLength={maxLength}
      />
    );
  }

  return (
    <div className="space-y-3">
      <div className="flex grow flex-wrap items-center gap-x-4 gap-y-2  pr-2">
        {isSaveClicked && !enableSaveBtn() && (
          <div className="h-3 w-3">
            <span className="relative flex h-3 w-3 items-center justify-center">
              <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-red-400 opacity-75" />
              <span className="relative inline-flex h-2 w-2 rounded-full bg-red-500" />
            </span>
          </div>
        )}
        <h4 className="text-xl  text-black dark:text-white">
          Personal information
        </h4>
      </div>
      <div className="grid  grid-cols-1 gap-x-6 gap-y-3  sm:grid-cols-2 md:grid-cols-6 lg:grid-cols-4 ">
        {renderTextField({
          id: 'prefix',
          label: 'Prefix',
          key: 'Prefix',
          value: personDetails?.Prefix,
          maxLength: 100,
        })}
        {renderTextField({
          id: 'firstName',
          label: 'First name',
          key: 'FirstName',
          value: personDetails?.FirstName,
          className: 'md:col-span-2 lg:col-auto',
          maxLength: 400,
        })}
        {renderTextField({
          id: 'lastName',
          label: 'Last name',
          key: 'LastName',
          value: personDetails?.LastName,
          className: 'md:col-span-2 lg:col-auto',
          maxLength: 400,
        })}
        {renderTextField({
          id: 'suffix',
          label: 'Suffix',
          key: 'Suffix',
          value: personDetails?.Suffix,
          maxLength: 100,
        })}
      </div>
      <div className="grid grid-cols-1 items-end gap-x-6 gap-y-3 sm:grid-cols-2 xl:grid-cols-4">
        {renderTextField({
          id: 'alternativeName',
          label: 'Alternative name',
          key: 'PreferredName',
          value: personDetails?.PreferredName,
          maxLength: 256,
        })}
        <div className="relative [&>*]:w-full  ">
          <label htmlFor="date-birth" className={inputLabelClasses}>
            Date of birth
          </label>

          <DateOfBirthPicker
            onChange={e => updatePersonDetails('Birthdate', e, '')}
            value={personDetails?.Birthdate ? personDetails?.Birthdate : null}
            disabled={userMode === 'Edit'}
            handleClose={() => {
              handleClose(personDetails?.Birthdate);
            }}
            container={refValue}
            onKeyDown={e => {
              if (e.key === 'Tab') {
                if (
                  new Date(e.target.value) > new Date() ||
                  new Date(e.target.value) < minDobDate
                ) {
                  updatePersonDetails('Birthdate', null, '');
                }
              }
            }}
          />
        </div>
        <Select
          id="gender"
          options={genders}
          label="Gender"
          onChange={e => updatePersonDetails('GenderId', e.target.value, '')}
          value={personDetails?.GenderId}
          disabled={userMode === 'Edit'}
        />
        {renderTextField({
          id: 'tagName',
          label: 'Tags',
          key: 'Tags',
          value: personDetails?.Tags !== null ? personDetails?.Tags : '',
          maxLength: 100,
        })}
      </div>
    </div>
  );
}
