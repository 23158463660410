import React, { ReactElement, useRef } from 'react';
import {
  BookOpenIcon,
  PaperClipIcon,
  ArrowDownTrayIcon,
  ChevronDownIcon,
  ArrowDownTrayIcon as ArrowDownTrayIconSolid,
  ArrowTopRightOnSquareIcon,
} from '@heroicons/react/20/solid';

import {
  FileIcon,
  Link2Icon,
  DownloadIcon as DownloadIconRadix,
  OpenInNewWindowIcon as OpenInNewWindowIconRadix,
} from '@radix-ui/react-icons';

import { Tooltip } from 'src/ui/components/Tooltip';

import { classNames } from 'src/utils/className';

import { Button, Alert, Spinner } from 'src/ui/components';

import { useGetTutorials, useGetDownloadTutorial } from 'src/hooks';

import { base64ToArrayBuffer } from 'src/utils/convertToBinaryFormat';
import { showErrorToast } from 'src/utils/ToastNotification';

import {
  DownloadFileType,
  TutorialElementType,
  TutorialType,
} from 'src/types/tutorialsType';
import { Disclosure, Transition } from '@headlessui/react';
import { fileDoesNotExistMsg } from 'src/features/Settings/utils/constants';

function Tutorials() {
  const tutorialsListWrapperRef = useRef<HTMLDivElement>(null);

  const {
    data: tutorialFiles,
    refetch,
    isLoading: isTutorialsLoading,
  } = useGetTutorials();

  console.log('tutorialFiles', tutorialFiles);

  const { mutate: downloadData, isLoading: isDownloading } =
    useGetDownloadTutorial();

  //on download
  function handleDownloadDoc(documentName: string) {
    downloadData(documentName, {
      onSuccess: (data: DownloadFileType) => {
        if (
          data?.Status === 'Success' &&
          data?.FileContent !== null &&
          data?.FileName !== null &&
          data?.FileContent !== '' &&
          data?.FileName !== '' &&
          data?.FileName !== undefined &&
          data?.FileContent !== undefined
        ) {
          let docFile = base64ToArrayBuffer(data?.FileContent);
          let blob = new Blob([docFile], {
            type: 'pdf',
          });

          const link = document.createElement('a');
          const url = window.URL.createObjectURL(blob);
          link.href = url;
          const fileName = data?.FileName;
          link.download = fileName;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
          refetch();
        } else {
          showErrorToast({
            message: fileDoesNotExistMsg,
          });
        }
      },
      onError: (errorMsg: any) => {
        showErrorToast({ message: errorMsg?.message });
      },
    });
  }

  function renderTutorialSection(section: TutorialType) {
    return (
      <div>
        <Disclosure
          as="div"
          className="rounded-md border ui-not-open:shadow-sm dark:border-dark-medium"
          key={section.Id}
        >
          {({ open }) => (
            <>
              <div
                className={classNames(
                  'flex',
                  open ? 'border-b dark:border-dark-medium' : '',
                )}
              >
                <div className="flex min-w-0 grow items-center justify-between px-1 py-1.5 md:px-3">
                  <div className="flex min-w-0 items-center gap-1">
                    <Disclosure.Button
                      as={Button}
                      variant="link"
                      size="small"
                      className="!block truncate !text-base"
                    >
                      {section.Name}
                    </Disclosure.Button>
                    <span className="rounded-3xl bg-gray-200 px-2 py-0.5 text-center text-xs font-bold leading-none dark:bg-neutral-700">
                      {section.TutorialElements.length}
                    </span>
                  </div>
                </div>
                <Disclosure.Button
                  as={Button}
                  variant="subtle"
                  aria-hidden={section.TutorialElements.length === 0}
                  className={classNames(
                    'rounded-s-none border-l !px-2 ui-open:rounded-br-none dark:border-dark-medium md:!px-3',
                    section.TutorialElements.length === 0
                      ? 'pointer-events-none invisible'
                      : '',
                  )}
                >
                  <ChevronDownIcon className="size-5 ui-open:rotate-180 ui-open:transform" />
                </Disclosure.Button>
              </div>

              {section?.TutorialElements?.length > 0 ? (
                <Transition
                  enter="transition duration-100 ease-out"
                  enterFrom="transform scaleY-95 opacity-0"
                  enterTo="transform scaleY-100 opacity-100"
                  leave="transition duration-75 ease-out"
                  leaveFrom="transform scaleY-100 opacity-100"
                  leaveTo="transform scaleY-95 opacity-0"
                >
                  <Disclosure.Panel
                    ref={tutorialsListWrapperRef}
                    className="max-h-72 space-y-3 overflow-auto rounded-b-md bg-gray-200/50 p-3 shadow-inner dark:bg-neutral-900"
                  >
                    {section?.TutorialElements?.map(
                      (tutorial: TutorialElementType, index: number) =>
                        renderTutorialItem(tutorial, index, section.Name),
                    )}
                  </Disclosure.Panel>
                </Transition>
              ) : null}
            </>
          )}
        </Disclosure>
      </div>
    );
  }

  function renderTutorialItem(
    tutorial: TutorialElementType,
    index: number,
    sectionName: string,
  ) {
    return (
      <div
        key={tutorial.Id}
        className="flex rounded-md border bg-white dark:border-dark-medium dark:bg-neutral-800"
      >
        <div className="flex grow items-center justify-between px-2 py-1">
          <div className="flex items-center gap-2 self-center">
            {tutorial.URL !== '' ? (
              <Link2Icon className="hidden size-[15px] shrink-0 text-gray-400 sm:inline-block" />
            ) : (
              <FileIcon className="hidden size-[15px] shrink-0 text-gray-400 sm:inline-block" />
            )}
            <span className="text-sm font-semibold">{tutorial.Name}</span>
          </div>

          {/* Menu for responsive */}
          {renderResponsiveTutorialMenu(tutorial)}

          {renderElementAction(tutorial)}
        </div>
      </div>
    );
  }

  function renderElementAction(tutorial: TutorialElementType) {
    return (
      <div className="hidden items-center gap-4 md:flex">
        {tutorial.URL === '' ? (
          <Tooltip
            delay={500}
            trigger={
              <Button
                variant="subtle"
                size="small"
                onClick={() => {
                  handleDownloadDoc(tutorial?.Name);
                }}
              >
                <ArrowDownTrayIconSolid className="size-5" />
              </Button>
            }
            content="Download document"
          />
        ) : (
          <Tooltip
            delay={500}
            trigger={
              <Button
                variant="subtle"
                size="small"
                onClick={() => {
                  const url = tutorial?.URL;
                  if (
                    url.indexOf('http://') === 0 ||
                    url.indexOf('https://') === 0
                  ) {
                    window.open(tutorial?.URL, '_blank');
                  } else {
                    window.open(`https://${tutorial?.URL}`, '_blank');
                  }
                }}
              >
                <ArrowTopRightOnSquareIcon className="size-5" />
              </Button>
            }
            content="Go to the page"
          />
        )}
      </div>
    );
  }

  function renderResponsiveTutorialMenu(tutorial: TutorialElementType) {
    return (
      <div className="md:hidden">
        {tutorial.URL !== '' ? (
          <button
            className={classNames(
              'group flex w-full items-center rounded-md px-2 py-2 text-sm font-semibold',
              'data-[highlighted]:bg-gray-200 data-[highlighted]:outline-none dark:data-[highlighted]:bg-neutral-600 dark:data-[highlighted]:text-white',
            )}
            title="Open link"
            onClick={() => {
              const url = tutorial?.URL;
              if (
                url.indexOf('http://') === 0 ||
                url.indexOf('https://') === 0
              ) {
                window.open(tutorial?.URL, '_blank');
              } else {
                window.open(`https://${tutorial?.URL}`, '_blank');
              }
            }}
          >
            <OpenInNewWindowIconRadix
              className="mr-2 size-[15px]"
              aria-hidden="true"
            />
          </button>
        ) : (
          <button
            className={classNames(
              'group flex w-full items-center rounded-md px-2 py-2 text-sm font-semibold',
              'data-[highlighted]:bg-gray-200 data-[highlighted]:outline-none dark:data-[highlighted]:bg-neutral-600 dark:data-[highlighted]:text-white',
            )}
            title="Download document"
            onClick={() => handleDownloadDoc(tutorial?.Name)}
          >
            <DownloadIconRadix
              className="mr-2 size-[15px]"
              aria-hidden="true"
            />
          </button>
        )}
      </div>
    );
  }

  return (
    <div className="container mx-auto flex max-w-2xl flex-col justify-center gap-y-8 px-4 py-3 md:h-full md:px-6 md:py-5">
      <div className="space-y-1">
        {(isTutorialsLoading || isDownloading) && (
          <div className="bg-gray-900/8  absolute inset-0 z-50 grid   place-items-center  backdrop-blur-sm">
            <Spinner size="large" />
          </div>
        )}
        <div
          className="mb-2 hidden h-10 w-10 items-center justify-center rounded border-primary-600 bg-primary-100/50 p-2 dark:bg-primary-600/20 sm:flex"
          aria-hidden="true"
        >
          <BookOpenIcon className="h-5 w-5 text-primary-500 dark:text-primary-300" />
        </div>
        <h2 className="text-xl font-medium md:text-2xl">Tutorial</h2>
        <p className="text-xs text-gray-500 dark:text-neutral-400 md:text-sm">
          To help you get started, we&apos;ve included several tutorial
          documents below.
        </p>
      </div>
      <div className="space-y-2">
        <Alert variant="info">
          <span className="text-sm !leading-none md:text-base">
            You can always find these tutorials under settings page.
          </span>
        </Alert>
        <div className="max-h-[55vh] space-y-3 overflow-y-scroll rounded-md border border-gray-300 bg-white p-3 dark:divide-neutral-700 dark:border-neutral-700 dark:bg-neutral-800">
          {tutorialFiles?.map(section => renderTutorialSection(section))}
        </div>
      </div>
    </div>
  );
}
export { Tutorials };
