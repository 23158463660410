import React, {
  ReactElement,
  Fragment,
  useRef,
  useState,
  useCallback,
  useEffect,
} from 'react';
import {
  FunnelIcon,
  ArrowPathIcon,
  ArrowDownTrayIcon,
  EllipsisHorizontalIcon,
  PlusIcon,
  PencilIcon,
  PrinterIcon,
} from '@heroicons/react/20/solid';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { InformationCircleIcon } from '@heroicons/react/20/solid';
import { useNavigate, useParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';

import {
  Button,
  EmptyState,
  Spinner,
  Skeleton,
  Select,
} from 'src/ui/components';
import { AlertListItem, Filter, PreviewModal } from './components/index';

import {
  useGetPersonAlerts,
  usePersonAlertsDownload,
  useGetPersonAlertPreview,
} from './api';
import { TPage } from './api/useGetPersonAlerts';
import { useGetCountry, useGetStoredPermissions, useTitle } from 'src/hooks';

import { usePersonAlertFilter } from './utils/useFilterReducer';
import { pageLength } from 'src/utils/appConstants';
import { showSuccessToast, showErrorToast } from 'src/utils/ToastNotification';
import { downloadSuccessMsg, options } from './utils/personAlertConstants';
import { classNames } from 'src/utils/className';
import { usePersonAlertStore } from '../AddEditPersonAlert/store/personAlertStore';
import { localTzMsg } from 'src/utils/appConstants';

import { PersonAlertListType, ActionButtonsType } from './types/personAlerts';
import { useSaveChangesParamStore } from 'src/store/useSaveChangesParamStore';

export default function PersonAlerts(): ReactElement {
  useTitle('Person Alerts');

  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const tbodyRef = useRef() as React.MutableRefObject<HTMLTableSectionElement>;

  const { id: reqAlertId } = useParams();

  const permissions = useGetStoredPermissions();

  const actionBtns: Array<ActionButtonsType> = [
    {
      label: 'Edit alert',
      action: onEditAlert,
      icon: <PencilIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />,
      id: 'edit',
      isVisible: permissions?.CanSetUpPersonAlerts,
    },
    {
      label: 'Preview',
      action: onPreview,
      icon: <PrinterIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />,
      id: 'preview',
      isVisible: permissions?.CanViewPersonAlerts,
    },
  ];

  const [selectedAlert, setSelectedAlert] = useState<
    PersonAlertListType | undefined
  >();
  const [documentPreviewModal, setDocumentPreviewModal] =
    useState<boolean>(false);

  const deleteAlertDetails = usePersonAlertStore(
    useCallback(state => state?.deleteAlertDetails, []),
  );

  const updateUserMode = usePersonAlertStore(
    useCallback(state => state?.updateUserMode, []),
  );

  const { filterOptions, setValues, resetFilters, filterCount } =
    usePersonAlertFilter();

  const {
    data: previewData,
    refetch: previewRefetch,
    isRefetching: isPreviewRefetching,
    isLoading: isPreviewLoading,
  } = useGetPersonAlertPreview(
    selectedAlert?.Person?.Account?.Id,
    selectedAlert?.Id,
    reqAlertId ? true : false,
  );

  const {
    data,
    isLoading: isListLoading,
    isFetching,
    isFetchingNextPage,
    isRefetching,
    hasNextPage,
    fetchNextPage,
  } = useGetPersonAlerts(filterOptions);

  const { mutate: downloadcsv, isLoading: isDownloadCsvLoading } =
    usePersonAlertsDownload();

  const { data: countryList } = useGetCountry();

  const isSkeletonLoading = isListLoading || isFetching;
  const isNonBlockingLoader =
    isFetchingNextPage ||
    isRefetching ||
    isDownloadCsvLoading ||
    isPreviewLoading;

  const handleScroll = useCallback(
    (e: any) => {
      if (tbodyRef.current === e.target) {
        const bottom =
          e.target.clientHeight - 10 <
            e.target.scrollHeight - e.target.scrollTop &&
          e.target.scrollHeight - e.target.scrollTop <
            e.target.clientHeight + 10;
        if (bottom) {
          hasNextPage && fetchNextPage();
        }
      }
    },
    [fetchNextPage, hasNextPage],
  );

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, true);
    return () => {
      window.removeEventListener('scroll', handleScroll, true);
    };
  }, [handleScroll]);

  // to prevent persisting selection when filter is applied.
  useEffect(() => {
    if (filterCount > 0) {
      setSelectedAlert(undefined);
    }
  }, [filterCount]);

  function handleRowClick(item: PersonAlertListType) {
    item.Id !== selectedAlert?.Id
      ? setSelectedAlert(item)
      : setSelectedAlert(undefined);
  }

  function handleDownloadAsCsv() {
    let pageParam = 0;
    downloadcsv(
      { pageParam, filterOptions },
      {
        onSuccess: () => {
          showSuccessToast({
            message: downloadSuccessMsg,
          });
        },
        onError: error => {
          showErrorToast({
            message: error.message,
          });
        },
      },
    );
  }

  function onEditAlert() {
    deleteAlertDetails();
    navigate(
      `/PersonAlert/Edit/${selectedAlert?.Id}?accountId=${selectedAlert?.Person?.AccountId}`,
    );
    queryClient.invalidateQueries(['add-edit-person-alert'], {
      refetchActive: false,
    });
  }

  useEffect(() => {
    if (reqAlertId !== undefined && reqAlertId !== null) {
      const filteredData = data?.pages
        .map(item =>
          item.data.filter(dataItem => dataItem.Id === parseInt(reqAlertId)),
        )
        .flat();
      if (filteredData) {
        setSelectedAlert(filteredData[0]);
        setDocumentPreviewModal(true);
        previewRefetch();
      }
    }
  }, [reqAlertId, data?.pages, previewRefetch]);

  function onPreview() {
    setDocumentPreviewModal(true);
    previewRefetch();
  }

  function disclosureContent(): ReactElement {
    return (
      <Disclosure>
        {({ open }) => (
          <>
            <div className="relative flex flex-col flex-wrap items-start justify-between gap-x-4 gap-y-2 border-t border-gray-200 bg-white px-2 py-1 shadow dark:border-neutral-700/50 dark:bg-neutral-800 sm:px-0 md:flex-row md:items-center">
              <h2 className="truncate text-lg font-semibold text-gray-950 dark:text-white sm:px-3 sm:text-xl sm:tracking-tight lg:px-4">
                Person alerts
              </h2>
              <div className="flex w-full flex-1 justify-between gap-3 sm:px-3 md:w-auto lg:px-4">
                <div className="mt-1 flex items-center gap-6 sm:mt-0 md:gap-4">
                  <div className="relative inline-flex">
                    <Disclosure.Button
                      as={Button}
                      size="small"
                      variant={open ? 'primary' : 'subtle'}
                    >
                      <FunnelIcon
                        className="-ml-0.5 h-5 w-5"
                        aria-hidden="true"
                      />
                      <span className="hidden sm:inline-block">Filter</span>
                    </Disclosure.Button>
                    <span className="absolute right-0 top-0 -mr-1.5 -mt-1 flex h-5 w-5">
                      {filterCount > 0 && (
                        <span
                          className={`relative inline-flex h-5 w-5 items-center justify-center rounded-full bg-red-600 text-xs font-bold leading-none text-white`}
                        >
                          {filterCount}
                        </span>
                      )}
                    </span>
                  </div>
                  <Button
                    variant="subtle"
                    size="small"
                    onClick={() => {
                      resetFilters();
                      setSelectedAlert(undefined);
                    }}
                  >
                    <ArrowPathIcon
                      className="-ml-0.5 h-5 w-5"
                      aria-hidden="true"
                    />
                    <span className="hidden lg:block">Reset</span>
                  </Button>

                  <div className="w-px self-stretch border border-light-light dark:border-dark-medium" />
                  <div className="flex items-center gap-1 sm:gap-2">
                    <label htmlFor="tag-status" className="text-xs font-medium">
                      Status
                    </label>
                    <Select
                      inputSize="small"
                      id="tag-status"
                      options={options}
                      className="w-auto"
                      value={filterOptions?.status}
                      onChange={e => {
                        setValues('status', e.target.value);
                        setSelectedAlert(undefined);
                      }}
                    />
                  </div>
                </div>

                <div className="flex items-center gap-3 sm:gap-2 md:gap-1">
                  {permissions?.CanSetUpPersonAlerts && (
                    <Button
                      variant="primary"
                      size="small"
                      onClick={() => {
                        navigate('/PersonAlert/Create');
                        updateUserMode('');
                        deleteAlertDetails();
                        queryClient.invalidateQueries(
                          ['add-edit-person-alert'],
                          {
                            refetchActive: false,
                          },
                        );
                        useSaveChangesParamStore.getState().setIsDirty(false);
                      }}
                    >
                      <PlusIcon
                        className="-ml-0.5 h-5 w-5"
                        aria-hidden="true"
                      />
                      <span className="hidden sm:block">New alert</span>
                    </Button>
                  )}
                  {data && data.pages[0].data?.length ? (
                    <Button
                      variant="subtle"
                      size="small"
                      onClick={() => {
                        handleDownloadAsCsv();
                      }}
                    >
                      <ArrowDownTrayIcon
                        className="-ml-0.5 h-5 w-5"
                        aria-hidden="true"
                      />
                      <span className="hidden sm:block">Download as CSV</span>
                    </Button>
                  ) : null}
                </div>
              </div>
            </div>

            <Filter
              filterOptions={filterOptions}
              setValues={setValues}
              resetFilters={resetFilters}
            />
          </>
        )}
      </Disclosure>
    );
  }

  function renderCount(): ReactElement {
    return (
      <>
        {data?.pages &&
          data.pages.length > 0 &&
          data?.pages[0]?.data?.length > 0 &&
          data.pages[data.pages.length - 1]?.data.length && (
            <p>
              Showing 1 to{' '}
              <strong>
                {' '}
                {data?.pages[data?.pages?.length - 1]?.data?.length ===
                pageLength
                  ? data?.pages?.length * pageLength
                  : (data?.pages?.length - 1) * pageLength +
                    data?.pages[data?.pages?.length - 1]?.data.length}{' '}
              </strong>{' '}
              of{' '}
              <strong>
                {' '}
                {data?.pages[data?.pages?.length - 1]?.recordsFiltered}
              </strong>{' '}
              entries (filtered from{' '}
              <strong> {data?.pages[0]?.recordsTotal} </strong> total entries)
              {isFetching ? ' | Processing' : ''}
            </p>
          )}
      </>
    );
  }

  function functionalityButtons(): ReactElement {
    return (
      <Disclosure>
        <div className="relative flex min-h-[46px] items-center justify-between gap-4 p-2 sm:px-3 lg:px-4">
          <div className="flex min-w-0 grow flex-wrap gap-x-6 gap-y-1 text-xs text-gray-600 dark:text-neutral-400 md:justify-between md:text-sm">
            <div>{renderCount()}</div>
          </div>

          <div className="hidden gap-2 md:flex">
            {selectedAlert &&
              actionBtns.map(
                (item, index) =>
                  item?.isVisible && (
                    <Button
                      variant="subtle"
                      key={index}
                      size="small"
                      className="whitespace-nowrap"
                      onClick={item.action}
                      id={item.id}
                    >
                      {item.icon}
                      {item.label}
                    </Button>
                  ),
              )}
          </div>
          {/* Actions on mobile resolution - start */}
          {selectedAlert && (
            <Menu as="div" className="relative ml-3 md:hidden">
              <Menu.Button as={Button} variant="secondary" size="small">
                <EllipsisHorizontalIcon
                  className="h-5 w-5"
                  aria-hidden="true"
                />
              </Menu.Button>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 -mr-1 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-neutral-700">
                  <>
                    {actionBtns.map((item, index) => (
                      <Menu.Item
                        key={`btn-${item.label}-${index}`}
                        as="a"
                        className={classNames(
                          // !btn?.enable ? 'bg-gray-100' : '',
                          'block px-4 py-2 text-sm text-gray-800   ui-disabled:opacity-60 dark:text-white',
                        )}
                        onClick={item.action}
                        disabled={!item?.isVisible}
                      >
                        {item.label}
                      </Menu.Item>
                    ))}
                  </>
                </Menu.Items>
              </Transition>
            </Menu>
          )}
        </div>
      </Disclosure>
    );
  }

  return (
    <>
      <main className="flex flex-1 flex-col overflow-y-auto overscroll-contain">
        {disclosureContent()}
        <p className="flex min-w-0 items-center justify-end gap-1 border-t bg-blue-50 p-1 font-medium  text-sky-700 shadow dark:border-neutral-700 dark:bg-neutral-800 dark:text-sky-300">
          <InformationCircleIcon
            className="-ml-0.5 hidden h-4 w-4 shrink-0 md:inline-block"
            aria-hidden="true"
          />
          <span className="text-xs">{localTzMsg}</span>
        </p>

        <div className="min-h-[46px] gap-1">
          {data && data.pages[0].data?.length ? functionalityButtons() : null}
        </div>

        <div
          className="relative flex-1 space-y-2 overflow-y-scroll px-2 pb-2 sm:px-3 sm:pb-3 lg:px-4 lg:pb-4"
          ref={tbodyRef}
        >
          {!isListLoading && data?.pages ? (
            <>
              {data?.pages.map((page: TPage, index: number) => (
                <Fragment key={`${page} - ${index + 1}`}>
                  {page?.data && page?.data?.length ? (
                    page?.data.map(
                      (alertItem: PersonAlertListType, indx: number) => (
                        <AlertListItem
                          key={`${alertItem.Id}-${indx + 1}`}
                          alertItem={alertItem}
                          handleRowClick={handleRowClick}
                          selectedAlert={selectedAlert}
                          setSelectedAlert={setSelectedAlert}
                          previewRefetch={previewRefetch}
                          setDocumentPreviewModal={setDocumentPreviewModal}
                        />
                      ),
                    )
                  ) : (
                    <div className="absolute inset-0 grid place-content-center">
                      <EmptyState message="No information available" />
                    </div>
                  )}
                </Fragment>
              ))}
            </>
          ) : (
            isSkeletonLoading && <Skeleton count={8} />
          )}
          {isNonBlockingLoader && (
            <div className="fixed inset-0 z-20 grid place-content-center">
              <Spinner size="large" />
            </div>
          )}
        </div>

        {documentPreviewModal && !isPreviewLoading && (
          <PreviewModal
            documentPreviewModal={documentPreviewModal}
            setDocumentPreviewModal={setDocumentPreviewModal}
            previewData={previewData}
            setSelectedAlert={setSelectedAlert}
            countryList={countryList}
            reqAlertId={reqAlertId}
          />
        )}
      </main>
    </>
  );
}
