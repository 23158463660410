import React, {
  ReactElement,
  useRef,
  Fragment,
  useState,
  useEffect,
} from 'react';
import { useQueryClient } from 'react-query';
import { Editor } from '@tinymce/tinymce-react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/20/solid';

import { TinymceEditor } from 'src/ui/components/TinymceEditor';
import {
  inputLabelClasses,
  DateRangePicker,
  Button,
  Spinner,
} from 'src/ui/components';

import { usePostAccountAlert } from 'src/features/ExtraCoverageRequests/api';

import { useExtracoverageRequestStore } from '../store/extracoverageStore';
import { applicationThemeStore } from 'src/store/applicationThemeStore';

import { postAlertMsg } from '../utils/coverageRequestConstants';
import { formatToUtcWithOffset } from 'src/utils/datepickerHelper';
import { showErrorToast, showSuccessToast } from 'src/utils/ToastNotification';
import {
  isNullOrUndefined,
  isTinymceEditorContentEmpty,
} from 'src/utils/common';
import { getAlertDetailsForGrid } from '../utils/coverageRequestHelper';

import { ExtraCoverageType } from 'src/features/ExtraCoverageRequests/types/extraCoverageRequestLists';
import { TimeZone } from 'src/types/AccountInfo';

function PreviewAccountAlert({
  Id,
  AccountAlertId,
  setSelectedExtraCoverage,
  selectedExtraCoverage,
  ptTimeZone,
}: {
  Id?: number;
  AccountAlertId?: number | null;
  setSelectedExtraCoverage?: (value: any) => void;
  selectedExtraCoverage?: ExtraCoverageType | null;
  ptTimeZone?: TimeZone;
}): ReactElement {
  const queryClient = useQueryClient();
  const msgEditorRef = useRef<any>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 3000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const isAlertPreviewModal = useExtracoverageRequestStore(
    React.useCallback(state => state.isAlertPreviewModal, []),
  );

  const isFromGrid = useExtracoverageRequestStore(
    React.useCallback(state => state.isFromGrid, []),
  );

  const updateStoreByKey = useExtracoverageRequestStore(
    React.useCallback(state => state.updateStoreByKey, []),
  );

  const alertModalInfo = useExtracoverageRequestStore(
    React.useCallback(state => state.alertModalInfo, []),
  );

  const setAlertModalInfo = useExtracoverageRequestStore(
    React.useCallback(state => state.setAlertModalInfo, []),
  );

  const deleteCoverageDetails = useExtracoverageRequestStore(
    React.useCallback(state => state.deleteCoverageDetails, []),
  );

  const previousAlertModalInfo = useExtracoverageRequestStore(
    React.useCallback(state => state.previousAlertModalInfo, []),
  );

  const { mutate: postAccountAlerts, isLoading: isSpinnerLoading } =
    usePostAccountAlert();

  //update the reason and comments based on preview modal
  function onUpdateAlertPreviewHandler() {
    if (!isFromGrid) updateStoreByKey('isAlertPreviewModal', false);
  }

  function onSendPostAccountAlert() {
    const alertDetails = getAlertDetailsForGrid(
      alertModalInfo?.dateRange,
      alertModalInfo?.message ?? '',
      selectedExtraCoverage?.TimeZone,
      ptTimeZone,
      // ...(AccountAlertId?),
    );

    if (Id)
      postAccountAlerts(
        {
          Id: Id,
          AccountAlert: AccountAlertId
            ? { ...alertDetails, Id: AccountAlertId }
            : alertDetails,
          isModified: AccountAlertId ? true : false,
        },
        {
          onSuccess: () => {
            setSelectedExtraCoverage && setSelectedExtraCoverage(null);
            queryClient.invalidateQueries('get_extra_coverage_requests');
            deleteCoverageDetails();
            showSuccessToast({ message: postAlertMsg });
          },
          onError: err => {
            showErrorToast({ message: err });
          },
        },
      );
  }

  const colourScheme = applicationThemeStore(
    React.useCallback(state => state.colourScheme, []),
  );

  function onCancelPostAccountAlert() {
    updateStoreByKey('isAlertPreviewModal', false);
    updateStoreByKey('isFromGrid', false);
    updateStoreByKey('alertModalInfo', previousAlertModalInfo);
  }

  return (
    <>
      <Transition appear show={isAlertPreviewModal} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => onCancelPostAccountAlert()}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="pr-dialog__backdrop" />
          </Transition.Child>

          <div className="pr-dialog__wrapper">
            <div className="pr-dialog__panel__container">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="pr-dialog__panel w-full max-w-4xl">
                  <div className="flex items-center justify-between gap-3 border-b p-4 dark:border-dark-medium">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6"
                    >
                      Alert details
                    </Dialog.Title>
                    <button
                      type="button"
                      className=" text-gray-400 hover:text-gray-500 dark:text-neutral-500 dark:hover:text-neutral-300 sm:right-4 sm:top-4"
                      onClick={() => onCancelPostAccountAlert()}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="space-y-4 p-4">
                    <div>
                      <label
                        htmlFor=""
                        className={[
                          inputLabelClasses,
                          'mb-1 after:ml-0.5 after:text-red-500 after:content-["*"] dark:after:text-red-400',
                        ].join(' ')}
                      >
                        Alert date
                      </label>
                      <DateRangePicker
                        rangeType="future"
                        placeholder=""
                        format="MM/DD/YYYY HH:mm:ss"
                        value={alertModalInfo?.dateRange ?? ''}
                        setValues={setAlertModalInfo}
                        keyValue="dateRange"
                        title="Effective date - Expiration date"
                        id="date-picker"
                      />
                    </div>
                    <div className="">
                      <label htmlFor="" className={inputLabelClasses}>
                        Alert message
                      </label>
                      <TinymceEditor
                        reference={(instance: Editor | null) => {
                          msgEditorRef.current = instance;
                        }}
                        onChange={(content: string) => {
                          setAlertModalInfo('message', content);
                        }}
                        value={alertModalInfo?.message}
                        height="270"
                        name={'Message'}
                        key={colourScheme}
                      />
                    </div>
                  </div>

                  <div className="flex justify-end gap-3 bg-gray-100 px-4 py-3 dark:bg-neutral-700/30">
                    <Button onClick={() => onCancelPostAccountAlert()}>
                      Cancel
                    </Button>
                    <Button
                      variant="primary"
                      onClick={() => {
                        isFromGrid
                          ? onSendPostAccountAlert()
                          : onUpdateAlertPreviewHandler();
                      }}
                      disabled={
                        isNullOrUndefined(alertModalInfo?.dateRange) ||
                        isNullOrUndefined(alertModalInfo?.message) ||
                        isTinymceEditorContentEmpty(
                          msgEditorRef?.current?.editor?.getContent(),
                        )
                      }
                    >
                      {isFromGrid ? 'Post alert' : 'Save'}
                    </Button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
          {(isSpinnerLoading || isLoading) && (
            <div className="pointer-events-none fixed inset-0 z-20 grid place-content-center">
              <Spinner size="large" />
            </div>
          )}
        </Dialog>
      </Transition>
    </>
  );
}
export { PreviewAccountAlert };
