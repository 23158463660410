import { useQuery, UseQueryResult } from 'react-query';

import { useAuth } from 'src/hooks';

import { getCallDocumentPreview, showNavigationPreview } from 'src/utils/urls';
import { useCallDocStore } from '../store/callDocStore';

import { CallDocumentsPreviewType } from '../types/callDocumentPreview';

async function fetchCallDocumentsPreview({
  token,
  tokenType,
  callDocumentId,
  isFromMail,
  navigationParam,
}: TVariables): Promise<any> {
  let url: string = '';
  isFromMail === true
    ? (url = showNavigationPreview(callDocumentId, navigationParam))
    : (url = getCallDocumentPreview(callDocumentId));
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      Authorization: `${tokenType} ${token}`,
    },
  });
  if (response?.status === 200) return response.json();
  else {
    throw { message: response.statusText };
  }
}

export type TResult = CallDocumentsPreviewType;

export type TVariables = {
  token: string;
  tokenType: string;
  callDocumentId: number;
  isFromMail: boolean;
  navigationParam: string;
};

export type TError = { message: string };

function useGetCallDocumentPreview(
  callDocumentId: number | undefined,
  isFromMail: boolean = false,
  navigationParam: string = '',
): UseQueryResult<TResult, TError> {
  const { token, tokenType } = useAuth();
  return useQuery(
    'get_call_documents_preview',
    async () => {
      if (callDocumentId) {
        const result = await fetchCallDocumentsPreview({
          token,
          tokenType,
          callDocumentId,
          isFromMail,
          navigationParam,
        });

        return result;
      }
    },
    {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      enabled: false,
      onSuccess: () => {
        if (isFromMail) {
          useCallDocStore.getState().setShowDocPreviewModal(true);
          useCallDocStore.getState().setIsPrevCallDocAvailable(true);
          useCallDocStore.getState().setIsNextCallDocAvailable(true);
        }
      },
      onError: err => {
        if (isFromMail) {
          useCallDocStore.getState().setShowDocPreviewModal(false);
          if (err.message === 'Call is Purged. File is Not available.') {
            useCallDocStore.getState().setShowConfirmation(true);
          } else {
            useCallDocStore.getState().setShowDocPreviewModal(true);
          }
          if (err.message === 'No previous call document found') {
            useCallDocStore.getState().setIsPrevCallDocAvailable(false);
          }
          if (err.message === 'No next call document found') {
            useCallDocStore.getState().setIsNextCallDocAvailable(false);
          }
        }
      },
    },
  );
}

export { useGetCallDocumentPreview };
