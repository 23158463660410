import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { PencilIcon, CheckIcon, XMarkIcon } from '@heroicons/react/20/solid';

import {
  TextField,
  Checkbox,
  Select,
  inputBaseClasses,
  inputSizeClasses,
  buttonBaseClasses,
  buttonSizeClasses,
  buttonVariantClasses,
  Alert,
} from 'src/ui/components';
import { calllimitAlertMsg } from './AlertMessages';

import { useGetTimezone } from 'src/hooks';

import { usePersonAlertStore } from '../../store/personAlertStore';
import { formatSelectOptions } from 'src/utils/common';
import { formatTime } from '../../utils/dateformattingHelper';

import { Timezone } from 'src/types/timezone';

function CallLimit(): ReactElement {
  const [isEditMode, setEditMode] = useState<boolean>(false);
  const [callCounter, setCallCount] = useState<any>();

  const userMode = usePersonAlertStore(
    useCallback(state => state.userMode, []),
  );

  const hasCallLimit = usePersonAlertStore(
    useCallback(state => state.hasCallLimit, []),
  );

  const isCallLimitPresent = usePersonAlertStore(
    useCallback(state => state?.isCallLimitExist, []),
  );

  const callLimitInfo = usePersonAlertStore(
    useCallback(state => state?.alertDetails?.Person?.PersonCallLimit, []),
  );

  const callsCalled = usePersonAlertStore(
    useCallback(
      state => state?.alertDetails?.Person?.PersonCallLimit?.CallsCalled,
      [],
    ),
  );

  const initailAlerts = usePersonAlertStore(
    useCallback(state => state.initialAlert, []),
  );

  const updateCallLimit = usePersonAlertStore(
    useCallback(state => state.updateCallLimit, []),
  );

  const updateHasCallLimit = usePersonAlertStore(
    useCallback(state => state.updateHasCallLimit, []),
  );

  const { data: timezone } = useGetTimezone();

  const timeZone = useMemo(() => {
    return formatSelectOptions(timezone, true);
  }, [timezone]);

  function getTimeZoneInfo(id?: string): Timezone | undefined {
    if (id) {
      const val = timezone?.find(x => x.Id === parseInt(id));
      if (val) return val;
    }
  }

  useEffect(() => {
    if (callsCalled !== undefined) {
      setCallCount(callsCalled);
    }
  }, [callsCalled]);

  function onSaveCounterHandler() {
    setEditMode(false);
    updateCallLimit('CallsCalled', callCounter);
  }

  function onCancelCounterHandler() {
    setEditMode(false);
    setCallCount(callsCalled);
  }

  function onEditCounterHandler() {
    setEditMode(true);
  }

  function renderCounterBtn(title: string, onChange: () => void): ReactElement {
    return (
      <>
        <button
          className={`${[
            buttonBaseClasses,
            buttonSizeClasses.small,
            buttonVariantClasses.subtle,
          ].join(
            ' ',
          )} !rounded-none ring-1 ring-inset ring-gray-300 dark:ring-neutral-700`}
          type="button"
          title={title}
          onClick={() => onChange()}
        >
          {title === 'Edit' ? (
            <PencilIcon className="-ml-0.5 h-4 w-4" aria-hidden="true" />
          ) : title === 'Save' ? (
            <CheckIcon className="-ml-0.5 h-4 w-4" aria-hidden="true" />
          ) : (
            <XMarkIcon className="-ml-0.5 h-4 w-4" aria-hidden="true" />
          )}
          <span className="sr-only">{title}</span>
        </button>
      </>
    );
  }

  function callCountEditor(): ReactElement {
    return (
      <>
        <div className="flex flex-wrap items-center gap-2">
          <span className="text-black dark:text-white">Caller has made</span>
          <div className="relative flex items-stretch">
            <input
              type="number"
              id="calls-called"
              className={`${[inputBaseClasses, inputSizeClasses.small].join(
                ' ',
              )} !w-[10ch] min-w-0 flex-auto !rounded-r-none`}
              value={callCounter}
              onChange={e => {
                setCallCount(e.target.value);
                updateCallLimit('CallsCalled', e.target.value);
              }}
              defaultValue={
                callCounter === null || callCounter === undefined
                  ? 0
                  : callCounter
              }
              min={0}
              disabled={!isEditMode}
            />

            {isEditMode && (
              <>
                {renderCounterBtn('Save', onSaveCounterHandler)}
                {renderCounterBtn('Cancel', onCancelCounterHandler)}
              </>
            )}
            {!isEditMode && renderCounterBtn('Edit', onEditCounterHandler)}
          </div>
          <span className="text-black dark:text-white">
            out of <strong>{initailAlerts?.maxCallCount}</strong> calls.
          </span>
          {callLimitInfo?.ResetInterval !== null &&
            callLimitInfo?.ResetInterval && (
              <p className="text-black dark:text-white">
                Call count resets at{' '}
                <strong>{formatTime(callLimitInfo?.ResetInterval)}</strong>{' '}
              </p>
            )}
        </div>
      </>
    );
  }

  return (
    <div className="space-y-5 border-t border-t-light-light pt-6 dark:border-t-dark-medium">
      <div className="space-y-3">
        <h3 className=" pb-1 text-lg font-medium text-black  dark:text-white">
          Call Limit
        </h3>
        <div></div>
        <div>
          <Checkbox
            id="calllimit"
            label="Set call limit"
            checked={hasCallLimit}
            onChange={e => updateHasCallLimit(e.target.checked)}
          ></Checkbox>
        </div>
      </div>

      {hasCallLimit && (
        <div className="grid grid-cols-1 gap-4 sm:gap-y-2 md:grid-cols-2">
          <div className="order-2 space-y-4 md:order-1">
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 sm:gap-y-2 lg:grid-cols-3">
              <TextField
                id="callcount"
                label="Calls"
                required
                type="number"
                value={callLimitInfo?.MaxCallCount}
                onChange={e => updateCallLimit('MaxCallCount', e.target.value)}
                defaultValue={
                  callLimitInfo?.MaxCallCount === null ||
                  callLimitInfo?.MaxCallCount === undefined
                    ? 0
                    : callLimitInfo?.MaxCallCount
                }
                min="0"
              />
              <TextField
                id="resettime"
                label="Reset time"
                required
                type="time"
                value={callLimitInfo?.ResetInterval}
                onChange={e => updateCallLimit('ResetInterval', e.target.value)}
              />
              <Select
                id="timezone"
                label="Time zone"
                options={timeZone}
                required
                value={callLimitInfo?.TimeZoneId}
                onChange={e => {
                  updateCallLimit('TimeZoneId', e.target.value);
                  updateCallLimit('TimeZone', getTimeZoneInfo(e.target.value));
                }}
              ></Select>
            </div>
            {userMode === 'Edit' && isCallLimitPresent && callCountEditor()}
          </div>
          <Alert variant="info" className="order-1 md:order-2">
            {calllimitAlertMsg}
          </Alert>
        </div>
      )}
    </div>
  );
}

export { CallLimit };
