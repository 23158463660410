import React, { ReactElement, useMemo, useCallback } from 'react';
import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';

import { Checkbox } from 'src/ui/components';
import EmailNotificationSettings from './EmailNotificationSettings';
import AccountQuestionSettings from './AccountQuestionSettings';

import {
  useGetPermissionGroupCode,
  useAuth,
  useGetPortalUserGroupCode,
  useGetStoredPermissions,
} from 'src/hooks';

import { useUserStore } from '../../store/userStore';

import { adminTooltipMsg } from '../../utils/userConstants';

import { PermissionGroupCodeType } from 'src/types/permissionGroupCode';

function UserSettings(): ReactElement {
  const { isExternalUser } = useAuth();

  const loggedInUserPermissions = useGetStoredPermissions();

  const { data: portalUserGroupCode } = useGetPortalUserGroupCode();

  const { data: permissions, isLoading: isPermissionLoading } =
    useGetPermissionGroupCode(portalUserGroupCode || '');

  const userSettingsPermissions = useUserStore(
    useCallback(state => state?.userSettingsPermissions, []),
  );

  const updatePermissions = useUserStore(
    useCallback(state => state?.updatePermissions, []),
  );

  const updateIncludeEmptyNotification = useUserStore(
    useCallback(state => state.updateIncludeEmptyNotification, []),
  );

  const enableTab = useUserStore(
    React.useCallback(state => state.enableSettings, []),
  );

  const isAllEmailNotificationLoading = useUserStore(
    React.useCallback(state => state.isAllEmailNotificationLoading, []),
  );

  const isSaveClicked = useUserStore(
    React.useCallback(state => state.isSaveClicked, []),
  );

  const initialUserData = useUserStore(
    React.useCallback(state => state.initialUserData, []),
  );

  const updateDefaultNotification = useUserStore(
    React.useCallback(state => state.updateDefaultNotification, []),
  );

  const updateCustomEmailNotificationSettings = useUserStore(
    useCallback(state => state.updateCustomEmailNotificationSettings, []),
  );

  const setSelectedOptions = useUserStore(
    useCallback(state => state?.setSelectedOptions, []),
  );

  const updateAccountQuestionSettings = useUserStore(
    useCallback(state => state?.updateAccountQuestionSettings, []),
  );

  const accountQuestionSettings = useUserStore(
    useCallback(state => state?.accountQuestionSettings, []),
  );

  const loggedUserDTPermission = useUserStore(
    useCallback(state => state?.loggedUserDTPermission, []),
  );

  const accountList = useUserStore(
    useCallback(state => state?.accountList, []),
  );
  const currentData = useUserStore(
    useCallback(state => state?.currentData, []),
  );

  const accountsInfo = useUserStore(
    useCallback(state => state?.accountsInfo, []),
  );

  const setSelectedRecords = useUserStore(
    useCallback(state => state?.setSelectedRecords, []),
  );

  const userMode = useUserStore(useCallback(state => state?.userMode, []));

  /*to check if documents is checked or not to show the section under Documents */
  const isDocumentsIncluded = useMemo(() => {
    let documentPresent = userSettingsPermissions?.findIndex(
      item => item?.PermissionName === 'Call Documents',
    );
    if (documentPresent === -1 || documentPresent === undefined) return false;
    else return true;
  }, [userSettingsPermissions]);

  /*handle which permission is checked */
  function handleUpdatePermissions(permissionType: string) {
    let permissionsArray =
      userSettingsPermissions !== undefined ? [...userSettingsPermissions] : [];
    const index = userSettingsPermissions?.findIndex(
      item => item?.PermissionName === permissionType,
    );
    if (index !== -1 && index !== undefined) permissionsArray?.splice(index, 1);
    else {
      let newPermission = permissions?.find(
        item => item?.Description === permissionType,
      );

      if (newPermission !== undefined && userSettingsPermissions !== undefined)
        permissionsArray = [
          ...userSettingsPermissions,
          {
            PermissionId: newPermission?.Id,
            PermissionName: newPermission?.Description,
          },
        ];
      else if (newPermission !== undefined) {
        let value = {
          PermissionId: newPermission?.Id,
          PermissionName: newPermission?.Description,
        };
        permissionsArray?.push(value);
      }
    }

    if (permissionsArray) updatePermissions([...permissionsArray]);
  }

  /*to show the check and uncheck mark in the permissions */
  function isPermissionChecked(description: string) {
    if (userSettingsPermissions === undefined) return false;
    else {
      let alreadyChecked = userSettingsPermissions?.findIndex(
        item => item?.PermissionName === description,
      );
      if (alreadyChecked !== -1) return true;
      else return false;
    }
  }

  function getAccountDetails(Id: number) {
    return accountList.find(x => x.AccountId === Id);
  }

  //resetting the account setting values when calldocuments changed
  function resetValues() {
    updateIncludeEmptyNotification &&
      updateIncludeEmptyNotification(initialUserData?.includeEmptyNotification);
    updateDefaultNotification &&
      updateDefaultNotification(initialUserData?.defaultEmailNotification);
    updateCustomEmailNotificationSettings &&
      updateCustomEmailNotificationSettings(
        initialUserData?.customEmailNotificationSettings,
      );
    setSelectedOptions && setSelectedOptions(initialUserData?.selectedOptions);
    setSelectedRecords && setSelectedRecords(initialUserData?.selectedRecordId);

    const updatedAccountSettings = accountQuestionSettings?.map(obj2 => {
      return {
        ...obj2,
        AccessTypeId:
          isExternalUser && userMode == ''
            ? loggedUserDTPermission.find(
                acc => acc.AccountId === obj2?.AccountId,
              ).AccessTypeId
            : getAccountDetails(obj2.AccountId)?.AccessTypeID,
      };
    });

    updateAccountQuestionSettings(updatedAccountSettings);
  }

  const isEnablePermission = useCallback(
    (claimType: string) => {
      if (
        isExternalUser &&
        (claimType === 'CanAdministerUsers' ||
          (claimType === 'CanSendCoverageRequests' &&
            !loggedInUserPermissions.CanSendCoverageRequests) ||
          (claimType === 'CanSetupPersonAlerts' &&
            !loggedInUserPermissions.CanSetUpPersonAlerts) ||
          (claimType === 'CanManagePortalAlerts' &&
            !loggedInUserPermissions.CanManagePortalAlerts) ||
          (claimType === 'CanViewPersonAlerts' &&
            !loggedInUserPermissions.CanViewPersonAlerts) ||
          (claimType === 'CanSendCoverageRequests' &&
            !loggedInUserPermissions.CanSendCoverageRequests) ||
          (claimType === 'DocumentTransmissionPermission' &&
            !loggedInUserPermissions.CanViewDocumentTransmission))
      ) {
        return false;
      } else {
        return true;
      }
    },
    [isExternalUser, loggedInUserPermissions],
  );

  function renderPermissionCheckbox(): ReactElement {
    return (
      <div>
        <label className="mb-0.5 block text-sm font-medium leading-6 text-gray-900 dark:text-white">
          Permissions
        </label>
        <div className="flex flex-wrap gap-4">
          {permissions &&
            permissions.length > 0 &&
            permissions?.map((permission: PermissionGroupCodeType) => {
              return (
                <Checkbox
                  id={permission?.Id?.toString()}
                  label={permission?.Description}
                  key={permission?.Id}
                  checked={isPermissionChecked(permission?.Description)}
                  onChange={e => {
                    handleUpdatePermissions(permission?.Description);
                    if (permission?.Id?.toString() === '7') {
                      resetValues();
                    }
                  }}
                  title={
                    !isEnablePermission(permission.ClaimType) && isExternalUser
                      ? adminTooltipMsg
                      : ''
                  }
                  disabled={!isEnablePermission(permission.ClaimType)}
                />
              );
            })}
        </div>
      </div>
    );
  }

  return (
    <>
      {!(isAllEmailNotificationLoading || isPermissionLoading) && (
        <Disclosure as="div" defaultOpen={userMode === 'Edit'} className="pb-4">
          {({ open }) => {
            return (
              <>
                <Disclosure.Button className="flex w-full items-center justify-between rounded-lg bg-white px-4 py-2 text-left shadow-sm hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-light-focus disabled:pointer-events-none disabled:cursor-not-allowed disabled:opacity-50 ui-open:rounded-b-none dark:bg-neutral-800 dark:hover:bg-neutral-800/70">
                  <div className="space-y-2">
                    <div className="flex items-center gap-2">
                      {!enableTab('permission-tab') && isSaveClicked && (
                        <div className="h-3 w-3">
                          <span className="relative flex h-3 w-3 items-center justify-center">
                            <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-red-400 opacity-75" />
                            <span className="relative inline-flex h-2 w-2 rounded-full bg-red-500" />
                          </span>
                        </div>
                      )}
                      <h3 className="font-medium">User settings</h3>
                    </div>
                  </div>
                  <ChevronDownIcon
                    className={`${
                      open ? 'rotate-180 transform' : ''
                    } h-5 w-5 text-gray-500 dark:text-white`}
                  />
                </Disclosure.Button>

                <Disclosure.Panel
                  className="rounded-b-lg border-t bg-gray-50 text-sm text-gray-500 shadow dark:border-neutral-700/50 dark:bg-neutral-800"
                  id="user-settings"
                >
                  <div className="space-y-10 p-4">
                    {renderPermissionCheckbox()}

                    {isDocumentsIncluded && <EmailNotificationSettings />}
                    {isDocumentsIncluded && (
                      <AccountQuestionSettings open={open} />
                    )}
                  </div>
                </Disclosure.Panel>
              </>
            );
          }}
        </Disclosure>
      )}
    </>
  );
}

export { UserSettings };
