import { file } from '@babel/types';
import {
  QueryClient,
  UseMutationResult,
  useMutation,
  useQueryClient,
} from 'react-query';

import { useAuth, useProgressStore } from 'src/hooks';

import { deleteSection, deleteTutorial } from 'src/utils/urls';

async function deleteFile({
  token,
  tokenType,
  fileNameId,
  type,
}: {
  token: string;
  tokenType: string;
  fileNameId: number;
  type: string;
}): Promise<any> {
  const response = await fetch(
    type === 'section' ? deleteSection(fileNameId) : deleteTutorial(fileNameId),
    {
      method: 'DELETE',
      headers: {
        Authorization: `${tokenType} ${token}`,
        'Content-Type': 'application/json',
      },
    },
  );
  return response;
}

type TError = {
  message: string;
};

function useDeleteTutorialFile(): UseMutationResult<
  void,
  TError,
  { fileNameId: number | undefined; type: string },
  unknown
> {
  const queryClient = useQueryClient();
  const { token, tokenType } = useAuth();

  const { setLoading } = useProgressStore();
  return useMutation(
    async ({
      fileNameId,
      type,
    }: {
      fileNameId: number | undefined;
      type: string;
    }) => {
      if (fileNameId) {
        setLoading('isDeleting', true);
        const result = await deleteFile({ token, tokenType, fileNameId, type });
        if (result?.status === 200) {
          queryClient?.invalidateQueries('get_tutorials');
          return result;
        } else {
          const errorMsg = await result.text();
          throw { message: errorMsg };
        }
      }
    },
    {
      onSettled: () => {
        setLoading('isDeleting', false);
      },
    },
  );
}
export { useDeleteTutorialFile };
