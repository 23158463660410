import React, {
  ReactElement,
  useRef,
  DragEvent,
  Fragment,
  useState,
  useMemo,
} from 'react';
import { useQueryClient } from 'react-query';
import {
  ArrowDownTrayIcon as ArrowDownTrayIconSolid,
  TrashIcon,
  ChevronDownIcon,
  PencilIcon,
  ArrowTopRightOnSquareIcon,
  EllipsisHorizontalIcon,
  XMarkIcon,
} from '@heroicons/react/20/solid';
import { PencilIcon as PencilIconMicro } from '@heroicons/react/16/solid';
import {
  FileIcon,
  Cross2Icon,
  Link2Icon,
  TrashIcon as TrashIconRadix,
  DownloadIcon as DownloadIconRadix,
  OpenInNewWindowIcon as OpenInNewWindowIconRadix,
} from '@radix-ui/react-icons';
import { Transition, Dialog, Disclosure } from '@headlessui/react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import * as PopoverRadix from '@radix-ui/react-popover';

import { Button, TextField } from 'src/ui/components';
import { Tooltip } from 'src/ui/components/Tooltip';

import { useDeleteTutorialFile, useUploadFile, usePostSaveOrder } from '../api';

import { classNames } from 'src/utils/className';
import { showErrorToast, showSuccessToast } from 'src/utils/ToastNotification';
import {
  tutorialItemDeleteMsg,
  sectionDeleteMsg,
  editUrlSuccessMsg,
  isValidURL,
} from '../utils/constants';

import {
  TutorialElementType,
  TutorialType,
  UploadType,
  GetTutorials,
} from 'src/types/tutorialsType';
import { useAuth, useGetStoredPermissions } from 'src/hooks';

type SectionType = {
  section: TutorialType;
  index: number;
  handleSectionDragStart: (e: DragEvent<HTMLDivElement>, id: any) => void;
  handleSectionDrop: (e: DragEvent<HTMLDivElement>, id: any) => void;
  handleSubDragStart: (
    e: DragEvent<HTMLDivElement>,
    sectionId: any,
    subSectionId: any,
  ) => void;
  handleSubDrop: (
    e: DragEvent<HTMLDivElement>,
    sectionId: any,
    index: number,
  ) => void;
  handleTouchStart: any;
  handleTouchMove: any;
  handleTouchEnd: any;
  handleDownloadDoc: (documentName: string) => void;
  data: GetTutorials;
  sectionRefs: any;
  setData: any;
};

function TutorialsSection({
  section,
  index,
  handleSectionDragStart,
  handleSectionDrop,
  handleSubDragStart,
  handleSubDrop,
  handleTouchStart,
  handleTouchMove,
  handleTouchEnd,
  handleDownloadDoc,
  data,
  sectionRefs,
  setData,
}: SectionType): ReactElement {
  const queryClient = useQueryClient();

  const tutorialsListWrapperRef = useRef<HTMLDivElement>(null);

  const { isExternalUser } = useAuth();

  const [showDelElementModal, setShowDelElementModal] =
    useState<boolean>(false);
  const [showDelSectionModal, setShowDelSectionModal] =
    useState<boolean>(false);
  const [popoverOpen, setPopoverOpen] = useState<any>({}); // Control popover state
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [sectionName, setSectionName] = useState<string>(section?.Name);
  const [renamePopoverOpen, setRenamePopoverOpen] = useState<any>({});
  /*set the file name - url, pdf in state for delete */
  const [fileName, setFileName] = useState<string | undefined>(undefined);
  const [urlInfo, setUrlInfo] = useState<{
    url: string;
    textDisplay: string;
  }>();

  //mobile section drag and drop
  const [draggingIndex, setDraggingIndex] = useState<any>(null);
  const [hoverIndex, setHoverIndex] = useState(null);
  const [draggingSectionId, setDraggingSectionId] = useState<number>();

  const tutorialRefs: any = useRef([]);

  const initialSectionValueRef = useRef<string>(section?.Name);
  const initialUrlValueRef = useRef<
    | {
        url: string;
        textDisplay: string;
      }
    | undefined
  >(urlInfo);

  const { mutate: deleteFile } = useDeleteTutorialFile();
  const { mutate: renameSectionName } = usePostSaveOrder();
  const { mutate: uploadFile } = useUploadFile();
  const permission = useGetStoredPermissions();

  const isSectionEdited = useMemo(() => {
    if (sectionName !== initialSectionValueRef?.current) return true;
    else return false;
  }, [sectionName]);

  const isUrlEdited = useMemo(() => {
    if (JSON.stringify(urlInfo) !== JSON.stringify(initialUrlValueRef.current))
      return true;
    else return false;
  }, [urlInfo]);

  function disableUrlEdit() {
    if (!isUrlEdited) return true;
    else if (urlInfo?.url === '' || urlInfo?.textDisplay === '') return true;
    else if (errorMessage !== '') return true;
    else return false;
  }

  function handleEditFileName(
    sectionName: string,
    tutorial: TutorialElementType,
  ) {
    let docParam: UploadType;
    docParam = {
      Title: urlInfo?.textDisplay || '',
      Content: urlInfo?.url || '',
      Container: '',
      StreamContent: '',
      SectionName: sectionName,
      SectionOrder: section.Order,
      ElementOrder: tutorial.Order,
      ElementId: tutorial?.Id,
      IsEdit: true,
    };
    uploadFile(docParam, {
      onSuccess: () => {
        showSuccessToast({ message: editUrlSuccessMsg });
        setPopoverOpen({ [tutorial.Id]: false });
      },
      onError: (e: any) => {
        showErrorToast({ message: e });
        setPopoverOpen({ [tutorial.Id]: false });
      },
    });
  }

  function handleDragOver(e: any) {
    if (isExternalUser) return;
    e.preventDefault();
  }

  const handleTutorialTouchStart = (
    e: any,
    index: number,
    section: TutorialType,
  ) => {
    if (isExternalUser) return;
    setDraggingIndex(index);
    setDraggingSectionId(section?.Id);
  };

  const handleTutorialTouchMove = (e: any) => {
    if (isExternalUser) return;
    if (draggingIndex === null) return;
    const touch = e.targetTouches[0];
    // Calculate hover index based on touch Y position
    let newHoverIndex = null;
    tutorialRefs.current.forEach((ref: any, idx: any) => {
      const rect = ref?.getBoundingClientRect();
      if (touch.clientY > rect.top && touch.clientY < rect.bottom) {
        newHoverIndex = idx;
      }
    });
    setHoverIndex(newHoverIndex);
    e.preventDefault();
  };

  const handleTutorialTouchEnd = (sectionId: number) => {
    if (isExternalUser) return;
    if (
      draggingIndex !== null &&
      hoverIndex !== null &&
      hoverIndex !== draggingIndex
    ) {
      if (draggingSectionId !== section?.Id) return;
      const newSections = [...section?.TutorialElements];
      const draggedItem = newSections.splice(draggingIndex, 1)[0];
      newSections.splice(hoverIndex, 0, draggedItem);
      let tempData = [...data];
      // Find index of the object with the given id
      const index = tempData.findIndex(item => item.Id === section?.Id);
      if (index !== -1) {
        if (section?.Id === sectionId) {
          tempData[index] = { ...section, TutorialElements: newSections };
        }
      }
      if (tempData) setData(tempData);
      setDraggingIndex(null);
      setHoverIndex(null);
    }
  };

  function onDelete(fileName: string) {
    let type = '';
    if (showDelSectionModal === true) type = 'section';
    let fileNameId: number | undefined = undefined;
    if (type === 'section') {
      fileNameId = section?.Id;
    } else {
      fileNameId = section?.TutorialElements?.find(
        item => item.Name === fileName,
      )?.Id;
    }
    deleteFile(
      { fileNameId, type },
      {
        onSuccess: () => {
          let deleteMsg =
            showDelSectionModal === true
              ? sectionDeleteMsg
              : tutorialItemDeleteMsg;
          showSuccessToast({ message: deleteMsg });
          setFileName(undefined);
          setShowDelSectionModal(false);
          setShowDelElementModal(false);
        },
        onError: (errorMsg: any) => {
          showErrorToast({ message: errorMsg.message });
        },
      },
    );
  }

  //function for rename section
  function renameSection(section: any) {
    const params = data?.map((item, index) => ({
      sectionName: item?.Name,
      sectionOrder: index,
      NewSectionName: item?.Id === section?.Id ? sectionName : '',
      elementOrders: item?.TutorialElements?.map((tItem, tIndex) => ({
        elementName: tItem?.Name,
        elementOrder: tIndex,
        NewElementName: '',
      })),
    }));

    renameSectionName(params, {
      onSuccess: () => {
        setRenamePopoverOpen({ [section.Id]: false });
        showSuccessToast({ message: 'Section renamed successfully' });
        queryClient.invalidateQueries('get_tutorials');
      },
    });
  }

  function renderConfirmAlert(): ReactElement {
    return (
      <Transition
        appear
        show={showDelSectionModal || showDelElementModal}
        as={Fragment}
      >
        <Dialog as="div" className="relative z-10" onClose={() => {}}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="pr-dialog__backdrop" />
          </Transition.Child>

          <div className="pr-dialog__wrapper">
            <div className="pr-dialog__panel__container">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="pr-dialog__panel">
                  <Dialog.Title
                    as="h3"
                    className="pl-4 pr-16 pt-4 font-medium leading-6 text-gray-900 dark:text-white"
                  >
                    Are you sure you want to delete <br />{' '}
                    <strong className="font-bold">{fileName}</strong>?
                  </Dialog.Title>
                  <button
                    type="button"
                    className="absolute right-4 top-4 text-gray-400 hover:text-gray-500 sm:right-4 sm:top-4"
                    onClick={() => {
                      setShowDelSectionModal(false);
                      setShowDelElementModal(false);
                    }}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>

                  <div className="mt-4 flex gap-[1ch] bg-gray-50 p-2 px-2 py-4 dark:bg-neutral-700/50 sm:justify-end sm:p-3">
                    <Button
                      variant="secondary"
                      onClick={() => {
                        setShowDelSectionModal(false);
                        setShowDelElementModal(false);
                        setFileName(undefined);
                      }}
                      className="grow sm:grow-0"
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="danger"
                      onClick={() => {
                        if (fileName) onDelete(fileName);
                      }}
                      className="grow sm:grow-0"
                    >
                      Delete
                    </Button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    );
  }

  function renderElementAction(tutorial: TutorialElementType) {
    return (
      <div className="hidden items-center gap-4 md:flex">
        {tutorial.URL === '' ? (
          <Tooltip
            delay={500}
            trigger={
              <Button
                variant="subtle"
                size="small"
                onClick={() => {
                  handleDownloadDoc(tutorial?.Name);
                }}
              >
                <ArrowDownTrayIconSolid className="size-5" />
              </Button>
            }
            content="Download document"
          />
        ) : (
          <Tooltip
            delay={500}
            trigger={
              <Button
                variant="subtle"
                size="small"
                onClick={() => {
                  const url = tutorial?.URL;
                  if (
                    url.indexOf('http://') === 0 ||
                    url.indexOf('https://') === 0
                  ) {
                    window.open(tutorial?.URL, '_blank');
                  } else {
                    window.open(`https://${tutorial?.URL}`, '_blank');
                  }
                }}
              >
                <ArrowTopRightOnSquareIcon className="size-5" />
              </Button>
            }
            content="Go to the page"
          />
        )}

        {permission?.AdministratorUsers && (
          <Tooltip
            delay={500}
            trigger={
              <Button
                variant="subtle-danger"
                size="small"
                onClick={() => {
                  setFileName(tutorial?.Name);
                  setShowDelElementModal(true);
                }}
              >
                <TrashIcon className="size-5" />
              </Button>
            }
            content={tutorial.URL === '' ? 'Remove document' : 'Remove url'}
          />
        )}
      </div>
    );
  }

  function renderResponsiveTutorialMenu(tutorial: TutorialElementType) {
    return (
      <div className="md:hidden">
        <DropdownMenu.Root>
          <DropdownMenu.Trigger asChild>
            <Button variant="subtle" size="small">
              <EllipsisHorizontalIcon className="h-5 w-5" aria-hidden="true" />
            </Button>
          </DropdownMenu.Trigger>

          <DropdownMenu.Portal container={tutorialsListWrapperRef.current}>
            <DropdownMenu.Content
              className={classNames(
                'w-44 rounded-md bg-white p-1 shadow-2xl ring-1 ring-black/10 will-change-[opacity,transform] focus:outline-none dark:bg-neutral-700',
                'data-[side=bottom]:animate-slideUpAndFade data-[side=left]:animate-slideRightAndFade data-[side=right]:animate-slideLeftAndFade data-[side=top]:animate-slideDownAndFade',
              )}
            >
              {tutorial.URL !== '' ? (
                <DropdownMenu.Item asChild>
                  <button
                    className={classNames(
                      'group flex w-full items-center rounded-md px-2 py-2 text-sm font-semibold',
                      'data-[highlighted]:bg-gray-200 data-[highlighted]:outline-none dark:data-[highlighted]:bg-neutral-600 dark:data-[highlighted]:text-white',
                    )}
                    onClick={() => {
                      const url = tutorial?.URL;
                      if (
                        url.indexOf('http://') === 0 ||
                        url.indexOf('https://') === 0
                      ) {
                        window.open(tutorial?.URL, '_blank');
                      } else {
                        window.open(`https://${tutorial?.URL}`, '_blank');
                      }
                    }}
                  >
                    <OpenInNewWindowIconRadix
                      className="mr-2 size-[15px]"
                      aria-hidden="true"
                    />
                    Open link
                  </button>
                </DropdownMenu.Item>
              ) : (
                <DropdownMenu.Item asChild>
                  <button
                    className={classNames(
                      'group flex w-full items-center rounded-md px-2 py-2 text-sm font-semibold',
                      'data-[highlighted]:bg-gray-200 data-[highlighted]:outline-none dark:data-[highlighted]:bg-neutral-600 dark:data-[highlighted]:text-white',
                    )}
                    onClick={() => handleDownloadDoc(tutorial?.Name)}
                  >
                    <DownloadIconRadix
                      className="mr-2 size-[15px]"
                      aria-hidden="true"
                    />
                    Download
                  </button>
                </DropdownMenu.Item>
              )}

              {permission?.AdministratorUsers && (
                <DropdownMenu.Item asChild>
                  <button
                    className={classNames(
                      'group flex w-full items-center rounded-md px-2 py-2 text-sm font-semibold',
                      'data-[highlighted]:bg-gray-200 data-[highlighted]:outline-none dark:data-[highlighted]:bg-neutral-600 dark:data-[highlighted]:text-white',
                    )}
                    onClick={() => {
                      setFileName(tutorial?.Name);
                      setShowDelElementModal(true);
                    }}
                  >
                    <TrashIconRadix
                      className="mr-2 size-[15px]"
                      aria-hidden="true"
                    />
                    Delete
                  </button>
                </DropdownMenu.Item>
              )}

              <DropdownMenu.Arrow className="fill-white dark:fill-neutral-700" />
            </DropdownMenu.Content>
          </DropdownMenu.Portal>
        </DropdownMenu.Root>
      </div>
    );
  }

  function renderTutorialItem(
    tutorial: TutorialElementType,
    index: number,
    sectionName: string,
  ) {
    return (
      <div
        key={tutorial.Id}
        ref={el => (tutorialRefs.current[index] = el)}
        className="flex rounded-md border bg-white dark:border-dark-medium dark:bg-neutral-800"
        draggable={isExternalUser ? false : true}
        onDragStart={e => handleSubDragStart(e, section.Id, tutorial.Id)}
        onDragOver={handleDragOver}
        onDrop={e => handleSubDrop(e, section.Id, index)}
        onTouchStart={e => handleTutorialTouchStart(e, index, section)} // For mobile drag
        onTouchMove={handleTutorialTouchMove}
        onTouchEnd={e => handleTutorialTouchEnd(section.Id)} // For mobile drop
      >
        {permission?.AdministratorUsers && (
          <Button
            variant="subtle"
            size="small"
            className="!cursor-grab !rounded-e-none border-r active:!cursor-grabbing dark:border-dark-medium"
          >
            <svg viewBox="0 0 24 24" role="presentation" className="size-6">
              <g fill="currentColor" fillRule="evenodd">
                <circle cx="10" cy="8" r="1"></circle>
                <circle cx="14" cy="8" r="1"></circle>
                <circle cx="10" cy="16" r="1"></circle>
                <circle cx="14" cy="16" r="1"></circle>
                <circle cx="10" cy="12" r="1"></circle>
                <circle cx="14" cy="12" r="1"></circle>
              </g>
            </svg>
          </Button>
        )}

        <div className="flex grow items-center justify-between px-2 py-1">
          <div className="flex items-center gap-2 self-center">
            {tutorial.URL !== '' ? (
              <Link2Icon className="hidden size-[15px] shrink-0 text-gray-400 sm:inline-block" />
            ) : (
              <FileIcon className="hidden size-[15px] shrink-0 text-gray-400 sm:inline-block" />
            )}
            <span className="text-sm font-semibold">{tutorial.Name}</span>
            {tutorial.URL !== '' && permission?.AdministratorUsers ? (
              <>
                <PopoverRadix.Root open={popoverOpen[tutorial?.Id]}>
                  <Tooltip
                    content="Edit URL / Link text"
                    trigger={
                      <PopoverRadix.Trigger asChild>
                        <Button
                          variant="subtle"
                          size="small"
                          onClick={() => {
                            setUrlInfo((prevData: any) => {
                              const updatedData = {
                                ...prevData,
                                url: tutorial?.URL,
                                textDisplay: tutorial?.Name,
                              };
                              initialUrlValueRef.current = updatedData;
                              return updatedData;
                            });
                            setPopoverOpen({ [tutorial?.Id]: true });
                          }}
                        >
                          <PencilIconMicro className="size-4" />
                        </Button>
                      </PopoverRadix.Trigger>
                    }
                  />
                  <PopoverRadix.Portal>
                    <PopoverRadix.Content
                      side="top"
                      className={classNames(
                        'w-72 rounded bg-white shadow-2xl will-change-[transform,opacity] dark:bg-neutral-700',
                        'data-[state=open]:data-[side=bottom]:animate-slideUpAndFade data-[state=open]:data-[side=left]:animate-slideRightAndFade data-[state=open]:data-[side=right]:animate-slideLeftAndFade data-[state=open]:data-[side=top]:animate-slideDownAndFade',
                      )}
                      sideOffset={5}
                    >
                      <div className="space-y-4 p-4">
                        <h4 className="text-lg font-semibold leading-none">
                          Update URL
                        </h4>
                        <div className="space-y-2">
                          <TextField
                            label="URL"
                            id="tutURL-id"
                            value={urlInfo?.url}
                            onChange={e => {
                              setUrlInfo({
                                url: e.target.value,
                                textDisplay: urlInfo?.textDisplay || '',
                              });
                            }}
                            onBlur={e => {
                              if (urlInfo?.textDisplay === '')
                                setUrlInfo({
                                  url: e.target.value,
                                  textDisplay: urlInfo?.textDisplay,
                                });
                              if (
                                e.target.value.trim() !== '' &&
                                !isValidURL(e.target.value)
                              ) {
                                setErrorMessage('Invalid URL');
                              } else {
                                setErrorMessage('');
                              }
                            }}
                            validationError={errorMessage}
                          />

                          <TextField
                            label="Text to display"
                            id="tutURLText-id"
                            value={urlInfo?.textDisplay}
                            onChange={e => {
                              setUrlInfo({
                                url: urlInfo?.url || '',
                                textDisplay: e.target.value,
                              });
                            }}
                            onBlur={e => {
                              if (urlInfo?.textDisplay === '')
                                setUrlInfo({
                                  url: urlInfo?.url || '',
                                  textDisplay: urlInfo?.textDisplay,
                                });
                            }}
                          />
                        </div>
                        <div className="flex justify-end gap-2">
                          <PopoverRadix.Close aria-label="Close" asChild>
                            <Button
                              variant="secondary"
                              onClick={() => {
                                setPopoverOpen({ [tutorial?.Id]: false });
                                setErrorMessage('');
                              }}
                            >
                              Cancel
                            </Button>
                          </PopoverRadix.Close>

                          <Button
                            variant="primary"
                            disabled={disableUrlEdit()}
                            onClick={() =>
                              handleEditFileName(sectionName, tutorial)
                            }
                          >
                            Update
                          </Button>
                        </div>
                      </div>
                      <PopoverRadix.Close
                        className="absolute right-1 top-1"
                        aria-label="Close"
                        asChild
                      >
                        <Button
                          variant="subtle"
                          size="small"
                          onClick={() => {
                            setPopoverOpen({ [tutorial?.Id]: false });
                            setErrorMessage('');
                          }}
                        >
                          <Cross2Icon className="size-4" />
                        </Button>
                      </PopoverRadix.Close>
                      <PopoverRadix.Arrow className="fill-white dark:fill-neutral-700" />
                    </PopoverRadix.Content>
                  </PopoverRadix.Portal>
                </PopoverRadix.Root>
              </>
            ) : (
              ''
            )}
          </div>

          {/* Menu for responsive */}
          {renderResponsiveTutorialMenu(tutorial)}

          {renderElementAction(tutorial)}
        </div>
      </div>
    );
  }

  return (
    <div ref={el => (sectionRefs.current[index] = el)}>
      <Disclosure
        as="div"
        className="rounded-md border ui-not-open:shadow-sm dark:border-dark-medium"
        key={section.Id}
        draggable={isExternalUser ? false : true}
        onDragStart={e => handleSectionDragStart(e, section.Id)}
        onDragOver={handleDragOver}
        onDrop={e => handleSectionDrop(e, section.Id)}
        onTouchStart={e => handleTouchStart(e, index)} // For mobile drag
        onTouchMove={handleTouchMove}
        onTouchEnd={e => handleTouchEnd()} // For mobile drop
      >
        {({ open }) => (
          <>
            <div
              className={classNames(
                'flex',
                open ? 'border-b dark:border-dark-medium' : '',
              )}
            >
              {permission?.AdministratorUsers && (
                <Button
                  variant="subtle"
                  className="!cursor-grab rounded-e-none border-r !px-2 active:!cursor-grabbing ui-open:rounded-bl-none dark:border-dark-medium md:!px-3"
                >
                  <svg
                    viewBox="0 0 24 24"
                    role="presentation"
                    className="size-6"
                  >
                    <g fill="currentColor" fillRule="evenodd">
                      <circle cx="10" cy="8" r="1"></circle>
                      <circle cx="14" cy="8" r="1"></circle>
                      <circle cx="10" cy="16" r="1"></circle>
                      <circle cx="14" cy="16" r="1"></circle>
                      <circle cx="10" cy="12" r="1"></circle>
                      <circle cx="14" cy="12" r="1"></circle>
                    </g>
                  </svg>
                </Button>
              )}

              <div className="flex min-w-0 grow items-center justify-between px-1 py-1.5 md:px-3">
                <div className="flex min-w-0 items-center gap-1">
                  <Disclosure.Button
                    as={Button}
                    variant="link"
                    size="small"
                    className="!block truncate !text-base"
                  >
                    {section.Name}
                  </Disclosure.Button>
                  <span className="rounded-3xl bg-gray-200 px-2 py-0.5 text-center text-xs font-bold leading-none dark:bg-neutral-700">
                    {section.TutorialElements.length}
                  </span>
                </div>

                {permission?.AdministratorUsers && (
                  <div className="flex items-center gap-4">
                    <PopoverRadix.Root open={renamePopoverOpen[section?.Id]}>
                      <Tooltip
                        delay={500}
                        trigger={
                          <PopoverRadix.Trigger asChild>
                            <Button
                              variant="subtle"
                              size="small"
                              className=""
                              onClick={() => {
                                setRenamePopoverOpen({ [section?.Id]: true });
                                setSectionName(section?.Name);
                              }}
                            >
                              <PencilIcon className="size-4 md:size-5" />
                            </Button>
                          </PopoverRadix.Trigger>
                        }
                        content="Rename section"
                      />
                      <PopoverRadix.Portal>
                        <PopoverRadix.Content
                          side="top"
                          className={classNames(
                            'w-72 rounded bg-white shadow-2xl will-change-[transform,opacity] dark:bg-neutral-700',
                            'data-[state=open]:data-[side=bottom]:animate-slideUpAndFade data-[state=open]:data-[side=left]:animate-slideRightAndFade data-[state=open]:data-[side=right]:animate-slideLeftAndFade data-[state=open]:data-[side=top]:animate-slideDownAndFade',
                          )}
                          sideOffset={5}
                        >
                          <div className="space-y-4 p-4">
                            <h4 className="text-lg font-semibold leading-none">
                              Rename section
                            </h4>
                            <div className="space-y-2">
                              <TextField
                                id="tutURL-id"
                                value={sectionName}
                                onChange={e => {
                                  setSectionName(e.target.value);
                                }}
                              />
                            </div>
                            <div className="flex justify-end gap-2">
                              <PopoverRadix.Close aria-label="Close" asChild>
                                <Button
                                  variant="secondary"
                                  onClick={() =>
                                    setRenamePopoverOpen({
                                      [section?.Id]: false,
                                    })
                                  }
                                >
                                  Cancel
                                </Button>
                              </PopoverRadix.Close>

                              <Button
                                variant="primary"
                                disabled={
                                  sectionName === '' || !isSectionEdited
                                }
                                onClick={() => renameSection(section)}
                              >
                                Update
                              </Button>
                            </div>
                          </div>
                          <PopoverRadix.Close
                            className="absolute right-1 top-1"
                            aria-label="Close"
                            asChild
                          >
                            <Button
                              variant="subtle"
                              size="small"
                              onClick={() =>
                                setRenamePopoverOpen({ [section?.Id]: false })
                              }
                            >
                              <Cross2Icon className="size-4" />
                            </Button>
                          </PopoverRadix.Close>
                          <PopoverRadix.Arrow className="fill-white dark:fill-neutral-700" />
                        </PopoverRadix.Content>
                      </PopoverRadix.Portal>
                    </PopoverRadix.Root>

                    <Tooltip
                      delay={500}
                      trigger={
                        <Button
                          variant="subtle-danger"
                          size="small"
                          onClick={() => {
                            setShowDelSectionModal(true);
                            setFileName(section?.Name);
                          }}
                        >
                          <TrashIcon className="size-4 md:size-5" />
                        </Button>
                      }
                      content="Remove section"
                    />
                    {renderConfirmAlert()}
                  </div>
                )}
              </div>
              <Disclosure.Button
                as={Button}
                variant="subtle"
                aria-hidden={section.TutorialElements.length === 0}
                className={classNames(
                  'rounded-s-none border-l !px-2 ui-open:rounded-br-none dark:border-dark-medium md:!px-3',
                  section.TutorialElements.length === 0
                    ? 'pointer-events-none invisible'
                    : '',
                )}
              >
                <ChevronDownIcon className="size-5 ui-open:rotate-180 ui-open:transform" />
              </Disclosure.Button>
            </div>
            {section?.TutorialElements?.length > 0 ? (
              <Transition
                enter="transition duration-100 ease-out"
                enterFrom="transform scaleY-95 opacity-0"
                enterTo="transform scaleY-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scaleY-100 opacity-100"
                leaveTo="transform scaleY-95 opacity-0"
              >
                <Disclosure.Panel
                  ref={tutorialsListWrapperRef}
                  className="max-h-72 space-y-3 overflow-auto rounded-b-md bg-gray-200/50 p-3 shadow-inner dark:bg-neutral-900"
                >
                  {section?.TutorialElements?.map(
                    (tutorial: TutorialElementType, index: number) =>
                      renderTutorialItem(tutorial, index, section.Name),
                  )}
                  {renderConfirmAlert()}
                </Disclosure.Panel>
              </Transition>
            ) : null}
          </>
        )}
      </Disclosure>
    </div>
  );
}

export { TutorialsSection };
