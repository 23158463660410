import React, { useState } from 'react';
import { useQueryClient } from 'react-query';
import { Tab } from '@headlessui/react';
import Select from 'react-select';

import { Button, TextField } from 'src/ui/components';
import { CreatableReactSelect } from 'src/ui/components';

import { useUploadFile } from '../api';

import {
  sectionLimitWarningMsg,
  sectionLimitInfoMsg,
  successMsg,
  MAX_SECTIONS,
  isValidURL,
} from '../utils/constants';
import { showSuccessToast, showErrorToast } from 'src/utils/ToastNotification';

import {
  SelectedOptionType,
  TutorialType,
  UploadType,
} from 'src/types/tutorialsType';

type AddUrlTabType = {
  setShowUploadModal: (val: boolean) => void;
  tutorialFiles: Array<TutorialType>;
  sections: Array<TutorialType>;
  setSections: (
    value:
      | Array<TutorialType>
      | ((prevState: Array<TutorialType>) => Array<TutorialType>),
  ) => void;
  url: string;
  setUrl: (value: string | ((prevState: string) => string)) => void;
  textDisplay: string;
  setTextDisplay: (value: string | ((prevState: string) => string)) => void;
  selectedOptions: SelectedOptionType | undefined;
  setSelectedOptions: (
    value:
      | SelectedOptionType
      | undefined
      | ((
          prevState: SelectedOptionType | undefined,
        ) => SelectedOptionType | undefined),
  ) => void;
  handleCreateOption: (inputValue: string) => TutorialType | undefined;
};

export function AddUrlTab({
  setShowUploadModal,
  tutorialFiles,
  sections,
  setSections,
  url,
  setUrl,
  textDisplay,
  setTextDisplay,
  selectedOptions,
  setSelectedOptions,
  handleCreateOption,
}: AddUrlTabType) {
  const queryClient = useQueryClient();

  const [errorMessage, setErrorMessage] = useState<string>('');

  const { mutate: uploadFile } = useUploadFile();

  function handleUpload() {
    let docParam: UploadType;
    const tutorialContainedSection = tutorialFiles?.find(
      item => item?.Name === selectedOptions?.label,
    )?.TutorialElements;

    docParam = {
      Title: textDisplay,
      Content: url,
      Container: '',
      StreamContent: '',
      SectionName: selectedOptions && selectedOptions?.label,
      SectionOrder: tutorialFiles?.length ? tutorialFiles?.length : 0,
      ElementOrder: tutorialContainedSection?.length
        ? tutorialContainedSection?.length
        : 0,
      ElementId: null,
      IsEdit: false,
    };
    uploadFile(docParam, {
      onSuccess: () => {
        setShowUploadModal(false);
        showSuccessToast({ message: successMsg });
        setUrl('');
        setTextDisplay('');
      },
      onError: (e: any) => {
        if (e?.includes('Message')) {
          showErrorToast({ message: 'An error has occured' });
        } else showErrorToast({ message: e });
        setShowUploadModal(false);
        setUrl('');
        setTextDisplay('');
      },
    });
    queryClient.invalidateQueries('get_tutorials', {
      refetchActive: false,
    });
    setSections([]);
  }

  function renderFooter() {
    return (
      <div className="flex gap-[1ch] bg-gray-100 p-2 px-2 py-4 dark:bg-neutral-700/50 sm:justify-end sm:p-3">
        <Button
          variant="secondary"
          onClick={() => {
            setShowUploadModal(false);
            setUrl('');
            setTextDisplay('');
          }}
          className="grow sm:grow-0"
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          className="grow sm:grow-0"
          disabled={selectedOptions?.value === -1 || url === ''}
          onClick={() => {
            setSections((prevOptions: any) => [
              ...prevOptions,
              selectedOptions,
            ]);
            handleUpload();
          }}
        >
          Upload
        </Button>
      </div>
    );
  }

  return (
    <Tab.Panel className="space-y-6">
      <div className="space-y-4 px-4">
        <TextField
          id="id-tutorial-url"
          label="URL"
          value={url}
          onChange={e => setUrl(e.currentTarget.value)}
          onBlur={e => {
            if (textDisplay === '') setTextDisplay(url);

            if (e.target.value.trim() !== '' && !isValidURL(e.target.value)) {
              setErrorMessage('Invalid URL');
            } else {
              setErrorMessage('');
            }
          }}
          validationError={errorMessage}
        />
        <TextField
          id="id-tutorial-url-text"
          label="Text to display"
          value={textDisplay}
          onChange={e => setTextDisplay(e.currentTarget.value)}
          onBlur={e => {
            if (textDisplay === '') setTextDisplay(url);
          }}
        />

        <div className="space-y-1">
          <label>Add to</label>
          {tutorialFiles?.length < MAX_SECTIONS ? (
            <CreatableReactSelect
              value={selectedOptions}
              options={sections?.map((x: TutorialType) => ({
                label: x.Name,
                value: x.Id,
              }))}
              setSelectedOptions={(selectedOption: any) => {
                setSelectedOptions(selectedOption);
              }}
              onCreateOption={handleCreateOption}
            />
          ) : (
            <Select
              classNamePrefix="multiselect-dropdown"
              menuPlacement="top"
              options={sections?.map((x: TutorialType) => ({
                label: x.Name,
                value: x.Id,
              }))}
              closeMenuOnSelect={true}
              isSearchable={true}
              onChange={(e: any) => {
                setSelectedOptions(e);
              }}
            />
          )}
          <span className="inline-block text-xs text-gray-600 dark:text-neutral-400">
            {sections?.length < MAX_SECTIONS
              ? `${sectionLimitInfoMsg}`
              : `${sectionLimitWarningMsg}`}
          </span>
        </div>
      </div>
      {renderFooter()}
    </Tab.Panel>
  );
}
