import React, { ReactElement, SyntheticEvent } from 'react';
import {
  ArrowSmallDownIcon,
  ArrowSmallUpIcon,
  XMarkIcon,
} from '@heroicons/react/20/solid';
import { Disclosure } from '@headlessui/react';

import { Button, Select, TextField } from 'src/ui/components';

import { sortOptions } from '../utils/personRepoConstants';

import { FilterOptionsType } from '../types/personRepoListTypes';

type PersonRepoFilterType = {
  setValues: (type: keyof FilterOptionsType, value: string) => void;
  filterOptions: FilterOptionsType;
  resetFilters: () => void;
};

function PersonRepoFilter({
  setValues,
  filterOptions,
  resetFilters,
}: PersonRepoFilterType): ReactElement {
  function renderSort() {
    return (
      <div className="flex items-end gap-2 ">
        <Select
          inputSize="small"
          label="Sort by"
          id="filter-status"
          className="w-full sm:w-auto"
          options={sortOptions}
          value={filterOptions?.sortType}
          onChange={(e: SyntheticEvent<HTMLSelectElement>) =>
            setValues('sortType', e.currentTarget.value)
          }
        />
        <Button
          variant={filterOptions.sortOrder === 'asc' ? 'primary' : 'secondary'}
          size="small"
          onClick={() => setValues('sortOrder', 'asc')}
        >
          <ArrowSmallUpIcon className="h-5 w-5" aria-hidden="true" />
          <span className="sr-only">Sort in ascending order</span>
        </Button>
        <Button
          variant={filterOptions.sortOrder === 'desc' ? 'primary' : 'secondary'}
          size="small"
          onClick={() => setValues('sortOrder', 'desc')}
        >
          <ArrowSmallDownIcon className="h-5 w-5" aria-hidden="true" />
          <span className="sr-only">Sort in descending order</span>
        </Button>
      </div>
    );
  }

  function renderTextField({
    id,
    placeholder,
    value,
    key,
  }: {
    id: string;
    placeholder: string;
    value: string | number;
    key: keyof FilterOptionsType;
  }) {
    return (
      <TextField
        inputSize="small"
        type="search"
        className="w-full sm:w-auto"
        id={id}
        placeholder={placeholder}
        value={value}
        onChange={(e: SyntheticEvent<HTMLInputElement>) =>
          setValues(key, e.currentTarget.value)
        }
      />
    );
  }

  return (
    <>
      <Disclosure.Panel
        as="div"
        className="fixed bottom-0 left-0 top-0 z-10 flex w-full flex-col bg-white shadow dark:bg-neutral-800 sm:static sm:bottom-auto sm:left-auto sm:top-auto sm:z-auto sm:block sm:w-auto"
      >
        {({ close }) => (
          <>
            <div className="flex items-center border-b border-b-light-light py-1 pe-1 ps-2 dark:border-b-dark-medium sm:hidden">
              <h3 className="grow text-lg font-medium">Filter</h3>
              <Button
                variant="subtle"
                onClick={() => {
                  close();
                }}
              >
                <XMarkIcon className="h-5 w-5" />
              </Button>
            </div>
            <div className="min-h-0 grow overflow-y-auto p-2 pb-3 sm:overflow-y-visible sm:px-3 lg:px-4">
              {renderSort()}
              <div
                className="my-4 border-b border-gray-200 dark:border-neutral-700/50"
                aria-hidden="true"
              />
              <div className="flex grow flex-wrap items-end gap-3">
                <div className="flex w-full flex-col gap-1 sm:w-auto">
                  <label htmlFor="" className="text-xs font-medium">
                    ID
                  </label>
                  {renderTextField({
                    id: 'id',
                    placeholder: 'ID',
                    value: filterOptions.id,
                    key: 'id',
                  })}
                </div>
                <div className="flex w-full flex-col gap-1 sm:w-auto">
                  <label htmlFor="" className="text-xs font-medium">
                    Name
                  </label>
                  {renderTextField({
                    id: 'first-name',
                    placeholder: 'First name',
                    value: filterOptions.firstName,
                    key: 'firstName',
                  })}
                  {renderTextField({
                    id: 'last-name',
                    placeholder: 'Last name',
                    value: filterOptions.lastName,
                    key: 'lastName',
                  })}
                  {renderTextField({
                    id: 'Alternate-name',
                    placeholder: 'Preferred name',
                    value: filterOptions.alternateName,
                    key: 'alternateName',
                  })}
                </div>
                <div className="flex w-full flex-col gap-1 sm:w-auto">
                  <label htmlFor="" className="text-xs font-medium">
                    CID - SID - PID
                  </label>
                  {renderTextField({
                    id: 'cid',
                    placeholder: 'CID',
                    value: filterOptions.cid,
                    key: 'cid',
                  })}
                  {renderTextField({
                    id: 'sid',
                    placeholder: 'SID',
                    value: filterOptions.sid,
                    key: 'sid',
                  })}
                  {renderTextField({
                    id: 'pid',
                    placeholder: 'PID',
                    value: filterOptions.pid,
                    key: 'pid',
                  })}
                </div>
                <div className="flex w-full flex-col gap-1 sm:w-auto">
                  <label htmlFor="" className="text-xs font-medium">
                    Primary phone number
                  </label>
                  {renderTextField({
                    id: 'phone',
                    placeholder: 'Primary phone',
                    value: filterOptions.primaryPhone,
                    key: 'primaryPhone',
                  })}
                </div>
                <div className="flex w-full flex-col gap-1 sm:w-auto">
                  <label htmlFor="" className="text-xs font-medium">
                    City
                  </label>
                  {renderTextField({
                    id: 'city',
                    placeholder: 'City',
                    value: filterOptions.city,
                    key: 'city',
                  })}
                </div>
                <div className="flex w-full flex-col gap-1 sm:w-auto">
                  <label htmlFor="" className="text-xs font-medium">
                    State
                  </label>
                  {renderTextField({
                    id: 'state',
                    placeholder: 'State',
                    value: filterOptions.state,
                    key: 'state',
                  })}
                </div>
              </div>
            </div>
            <div className="flex flex-col  gap-1 border-t border-t-light-light p-2 dark:border-b-dark-medium sm:hidden">
              <Button
                className="grow"
                variant="link"
                onClick={() => {
                  resetFilters();
                }}
              >
                Reset
              </Button>
              <div className="flex gap-1">
                <Button
                  className="grow"
                  onClick={() => {
                    close();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  className="grow"
                  variant="primary"
                  onClick={() => {
                    close();
                  }}
                >
                  Apply
                </Button>
              </div>
            </div>
          </>
        )}
      </Disclosure.Panel>
    </>
  );
}

export { PersonRepoFilter };
