import { useMutation, UseMutationResult, useQueryClient } from 'react-query';

import { useAuth, useProgressStore } from 'src/hooks';

import { uploadTutorial } from 'src/utils/urls';

import { UploadFileType } from '../types/TutorialsType';

async function uploadFile({
  token,
  tokenType,
  params,
}: TArguments): Promise<TResult> {
  const response = await fetch(uploadTutorial(params.SectionName || ''), {
    method: 'POST',
    headers: {
      Authorization: `${tokenType} ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params),
  });

  if (response.status === 200) return 'Success';
  else return response.text();
}

type TResult = any;

export type TError = { Message: string };

export type TVariables = {
  params: UploadFileType;
};

export type TArguments = {
  token: string;
  tokenType: string;
  params: UploadFileType;
};

function useUploadFile(): UseMutationResult<
  void,
  TError,
  UploadFileType,
  unknown
> {
  const queryClient = useQueryClient();
  const { token, tokenType } = useAuth();

  const { setLoading } = useProgressStore();

  return useMutation(
    async (params: UploadFileType) => {
      setLoading('isUploading', true);
      const result = await uploadFile({
        token,
        tokenType,
        params: params,
      });
      queryClient?.invalidateQueries('get_tutorials');
      if (result === 'Success') return result;
      else throw result;
    },
    {
      onSettled: () => {
        setLoading('isUploading', false);
      },
    },
  );
}
export { useUploadFile };
