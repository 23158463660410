import { useQuery, UseQueryResult } from 'react-query';

import { useAuth } from 'src/hooks';

import { getCallHistoryDocumentPreview } from 'src/utils/urls';

import { usePersonRepoStore } from '../store/personRepoStore';

import { CallDocumentsPreviewType } from 'src/features/CallDocuments/types/callDocumentPreview';

async function fetchCallDocumentsPreview({
  token,
  tokenType,
  callDocumentId,
}: TVariables): Promise<any> {
  const response = await fetch(getCallHistoryDocumentPreview(callDocumentId), {
    method: 'GET',
    headers: {
      Authorization: `${tokenType} ${token}`,
    },
  });
  if (response?.status === 200) return response.json();
  else return response;
}

export type TResult = CallDocumentsPreviewType;

export type TVariables = {
  token: string;
  tokenType: string;
  callDocumentId: number;
};

export type TError = { message: string };

function useGetCallDocumentPreview(
  callDocumentId: number | undefined,
): UseQueryResult<TResult, TError> {
  const { token, tokenType } = useAuth();

  const updateCallInfo = usePersonRepoStore.getState().updateCallInfo;
  return useQuery(
    ['get_call_documents_preview', callDocumentId],
    async () => {
      if (callDocumentId) {
        const result = await fetchCallDocumentsPreview({
          token,
          tokenType,
          callDocumentId,
        });

        updateCallInfo(result);
        return result;
      }
    },
    {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      // enabled: false,
    },
  );
}

export { useGetCallDocumentPreview };
