import { otherPhoneNumberLength, usPhoneNumberLength } from './appConstants';
import { minDobDate } from './datepickerHelper';
import {
  otherPhnValidationMsg,
  usPhnValidationMsg,
  usPhoneNumberFormat,
  allPhnNumFormat,
} from './phoneNumberConstants';

/*to capitalise the first letter of each word */
function toCapitalise(uMode: string) {
  const wordArr = uMode?.split(' ');
  const newWord = wordArr
    ?.map(word => {
      return word[0].toUpperCase() + word.substring(1);
    })
    .join(' ');
  return newWord;
}

//for trimming the values for filter and search fields
function trimValues(param: string) {
  return param?.trim();
}

function calculateAgeAsString(dateOfBirth: string | undefined) {
  if (dateOfBirth) {
    const today = new Date();
    const birthDate = new Date(dateOfBirth);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    return age?.toString();
  } else return '0';
}

// to set inner html
function createMarkup(strVal: any) {
  return { __html: strVal || '' };
}

const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const complexEmailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

const formatSelectOption = (
  options: any,
  key1: string,
  key2: string,
  fileName?: string,
) => {
  if (options) {
    const resultArray = options?.map((item: any) => {
      return {
        value: item?.[key1],
        label: item?.[key2],
      };
    });
    if (fileName && fileName === 'clinician') {
      resultArray?.unshift({ value: -1, label: 'Unassigned' });
    } else resultArray?.unshift({ value: -1, label: 'Select one' });
    return resultArray;
  } else return [];
};

function formatSelectOptions(inputArray: any, isKeyDiff: boolean = false) {
  if (inputArray) {
    const options = inputArray?.map((item: any) => {
      if (isKeyDiff) {
        return {
          value: item?.Id,
          label: item?.Abbreviation,
        };
      }
      return {
        value: item?.Id,
        label: item?.Name,
      };
    });
    options.unshift({ value: -1, label: 'Select one' });
    return options;
  } else return [];
}

const formatSelectOptionWithoutSelect = (
  options: any[] | undefined,
  key1: string,
  key2: string,
  isSelect: boolean = false,
  gender: boolean = false,
) => {
  if (!options) {
    return [];
  }

  const resultArray = options.map((item: any) => ({
    value: item?.[key1],
    label: item?.[key2],
  }));

  if (isSelect && gender === true) {
    resultArray.unshift({ value: -1, label: 'Select one' });
  } else if (isSelect) {
    resultArray.unshift({ value: -1, label: 'Select' });
  }

  return resultArray;
};

function addressInfo(item: any) {
  return {
    City: item?.PlaceName,
    StateProvinceId: item?.StateProvinceId,
    StateProvince: item?.StateProvince,
    ZipPostalCode: item?.Code,
    CountryId: item?.CountryId,
    Country: item?.Country,
    County: item?.County,
    Id: item?.Id,
  };
}

//validate password for set password and confirm password screen.
function validatePassword(type: string, password: string): boolean {
  switch (type) {
    case 'number':
      return /[0-9]/g.test(password);
    case 'lowercase':
      return /[a-z]/g.test(password);
    case 'uppercase':
      return /[A-Z]/g.test(password);
    case 'specialChar':
      return /[^a-zA-Z0-9]/g.test(password);
    case 'reqLength':
      return password.length >= 8;
    default:
      return true;
  }
}

//To check whether the password is  valid or not.
function isValidPassword(password: string): boolean {
  return (
    /[0-9]/g.test(password) &&
    /[a-z]/g.test(password) &&
    /[A-Z]/g.test(password) &&
    /[^a-zA-Z0-9]/g.test(password) &&
    password.length >= 8
  );
}

const specialCharArray = '(~@!#$%^&*_-+={}[];,<,>,.?/|\\",`)';

function shouldDisableDate(date: any) {
  const currentDate = new Date();
  return (
    date.getTime() < minDobDate.getTime() ||
    date.getTime() > currentDate.getTime()
  );
}

function countryOptions(country: any) {
  return country?.map((item: any) => ({
    value: item?.Id,
    label: item?.ISDCode,
    Abbreviation: item?.Abbreviation,
    Name: item?.Name,
  }));
}

function isUsPhoneNumber(country: any, id: any) {
  return country?.find((x: any) => x.Id == id)?.ISDCode === '+1';
}

function patternForMask(countryCode: any, countryId: any) {
  const isDefaultCountryCode =
    countryCode?.find((country: any) => country.value == countryId)?.label ===
    '+1';
  if (isDefaultCountryCode) return '999-999-9999';
  else return '999999999999999';
}

function formattedPhoneNumber(phoneNumber: any, countryId: any) {
  if (phoneNumber) {
    let formattedPhone = phoneNumber.replace(/-/g, '');
    if (countryId === 1)
      formattedPhone = formattedPhone.replace(usPhoneNumberFormat, '$1-$2-$3');
    return formattedPhone;
  }
  return '';
}

function isPhnIsInvalidOrNot(
  phnNumber: any,
  countryId: any,
  countryCodes?: any,
) {
  if (phnNumber === '' || phnNumber === undefined || phnNumber === null)
    return false;

  const isUsPhoneNumber = hasUsFormat(countryCodes, countryId);

  const phoneNumberLength = phnNumber.match(/\d/g)?.length;

  const isInvalidPhoneNumber =
    phoneNumberLength > 0 &&
    ((isUsPhoneNumber && phoneNumberLength < 10) ||
      (!isUsPhoneNumber && phoneNumberLength < 8));

  return isInvalidPhoneNumber;
}

function isNullOrUndefined(value: any) {
  return value === undefined || value === null || value === '';
}

function hasUsFormat(country: any[], id: number): boolean {
  const countryId = typeof id !== 'number' ? parseInt(id) : id;
  const foundCountry = country.find(elem => elem.value === countryId);
  return foundCountry ? foundCountry.label === '+1' : false;
}

function isCountryCodeInUSFormat(
  prevCountryId: any,
  countryId: number,
  countryCode?: any,
): boolean {
  const isCurrentUSPhoneNumber = hasUsFormat(countryCode, countryId);
  const isPreviousUSPhoneNumber = hasUsFormat(countryCode, prevCountryId);

  return isCurrentUSPhoneNumber && !isPreviousUSPhoneNumber;
}

function appendToComments(comment: any, phone: any) {
  if (!comment && !phone) return '';

  return `${comment || ''}${comment && phone ? ', ' : ''}${phone || ''}`;
}

function validationErrorMsgForPhone(country: any, countryId: number): string {
  if (hasUsFormat(country, countryId)) return usPhnValidationMsg;
  return otherPhnValidationMsg;
}

function phnNumberToUSFormat(phone: any, countryId: any, country: Array<any>) {
  const isUsCountryCode = hasUsFormat(country, countryId);
  let formattedPhone = phone.replace(/-/g, '');
  if (isUsCountryCode)
    return formattedPhone.replace(usPhoneNumberFormat, '$1-$2-$3');
  return formattedPhone;
}

function isTinymceEditorContentEmpty(content: string): boolean {
  return content?.replace(/&nbsp;|<[^>]+>/g, '')?.trim() == '';
}

function formatAllPhnToUsFormat(num: any) {
  const formattedNumber = num.replace(allPhnNumFormat, '$1-$2-$3');
  return formattedNumber;
}

function getComments(phoneObj: any, countryId: any, countryOptions: any) {
  if (phoneObj) {
    if (
      phoneObj?.Number &&
      (phoneObj?.CountryId === null ||
        (isUsPhoneNumber(countryOptions, phoneObj?.CountryId) &&
          formatAllPhnToUsFormat(phoneObj?.Number)?.length >
            usPhoneNumberLength) ||
        (!isUsPhoneNumber(countryOptions, phoneObj?.CountryId) &&
          phoneObj?.Number?.length > otherPhoneNumberLength))
    ) {
      if (phoneObj?.Comments)
        return phoneObj?.Comments + ', ' + phoneObj?.Number;
      return phoneObj?.Number;
    }
    return phoneObj?.Comments;
  }
  return phoneObj?.Comments;
}

function formatPhoneNumber(phone: string, hasUsFormat: boolean): string {
  const usFormatPhoneLength = 10;
  const otherPhoneLength = 15;
  const digitsOnly = phone.replace(/\D/g, '');
  if (hasUsFormat) {
    if (digitsOnly.length < usFormatPhoneLength) {
      return digitsOnly;
    }
    return digitsOnly
      .substring(0, usFormatPhoneLength)
      .replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
  }

  return digitsOnly.substring(0, otherPhoneLength);
}

export {
  toCapitalise,
  trimValues,
  createMarkup,
  calculateAgeAsString,
  emailRegex,
  formatSelectOption,
  addressInfo,
  formatSelectOptionWithoutSelect,
  formatSelectOptions,
  validatePassword,
  specialCharArray,
  isValidPassword,
  shouldDisableDate,
  complexEmailRegex,
  countryOptions,
  patternForMask,
  formattedPhoneNumber,
  isPhnIsInvalidOrNot,
  isNullOrUndefined,
  isCountryCodeInUSFormat,
  appendToComments,
  validationErrorMsgForPhone,
  hasUsFormat,
  phnNumberToUSFormat,
  isTinymceEditorContentEmpty,
  isUsPhoneNumber,
  getComments,
  formatPhoneNumber,
};
