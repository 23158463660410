import { useMutation, UseMutationResult, useQueryClient } from 'react-query';

import { useAuth, useProgressStore } from 'src/hooks';

import { saveOrder } from 'src/utils/urls';

import { SectionOrderType } from '../types/TutorialsType';

export async function savePostOrder({
  requestData,
  token,
  tokenType,
}: {
  requestData: any;
  token: string;
  tokenType: string;
}): Promise<TResult> {
  const response = await fetch(saveOrder, {
    method: 'PUT',
    headers: {
      Authorization: `${tokenType} ${token}`,
      'content-type': 'application/json',
    },
    body: JSON.stringify(requestData),
  });
  if (response.status === 200) return 'Success';
  else return response.json();
}

export type ValidationError = { Message?: string };
export type TResult = string | ValidationError;
export type TVariables = any;
export type TError = {
  message: string;
};
export type TSnapshot = unknown;

function usePostSaveOrder(): UseMutationResult<
  TResult,
  TError,
  TVariables,
  TSnapshot
> {
  const { token, tokenType } = useAuth();
  const queryClient = useQueryClient();
  const { setLoading } = useProgressStore();

  return useMutation(
    async (params: TVariables) => {
      setLoading('isPostOrderSaving', true);
      const result = await savePostOrder({
        requestData: params,
        token: token,
        tokenType: tokenType,
      });
      queryClient?.invalidateQueries('get_tutorials');
      if (result === 'Success') return result;
      else throw result;
    },
    {
      onSettled: () => {
        setLoading('isPostOrderSaving', false);
      },
    },
  );
}

export { usePostSaveOrder };
