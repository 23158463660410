import React, {
  Fragment,
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { Disclosure } from '@headlessui/react';
import {
  ArrowPathIcon,
  FunnelIcon,
  InformationCircleIcon,
  PencilIcon,
  XMarkIcon,
} from '@heroicons/react/20/solid';

import { Button, EmptyState, Skeleton, Spinner } from 'src/ui/components';
import {
  ListItem,
  PortalAlertFilter,
  PortalAlertSort,
  UserActions,
} from './components';

import { useTitle } from 'src/hooks';
import { useGetPortalAlerts, useDownloadCsv } from './api/index';

import { downloadCsvMsg, localTzMsg, pageLength } from 'src/utils/appConstants';
import { usePortalAlertFilter } from './utils/useFilterReducer';
import { usePortalAlertStore } from '../AddEditPortalAlerts/store/portalAlertStore';
import { showSuccessToast } from 'src/utils/ToastNotification';

import { PortalAlertListType, PortalAlerts } from './types/portalAlerts';

export default function PortalAlert(): ReactElement {
  const navigate = useNavigate();
  useTitle('View Portal Alerts');

  const tbodyRef = useRef() as React.MutableRefObject<HTMLTableSectionElement>;

  const [selectedAlert, setSelectedAlert] = useState<
    PortalAlerts | undefined
  >();

  const { filterCount, filterOptions, setValues, resetFilters } =
    usePortalAlertFilter();

  const resetStore = usePortalAlertStore(
    useCallback(state => state?.resetStore, []),
  );

  const {
    data,
    isLoading: isListLoading,
    isFetching,
    hasNextPage,
    isRefetching,
    isFetchingNextPage,
    fetchNextPage,
  } = useGetPortalAlerts(filterOptions);

  const { mutate: downloadAsCsv, isLoading: isDownloading } = useDownloadCsv();

  const handleScroll = useCallback(
    (e: any) => {
      if (tbodyRef.current === e.target) {
        const bottom =
          e.target.clientHeight - 10 <
            e.target.scrollHeight - e.target.scrollTop &&
          e.target.scrollHeight - e.target.scrollTop <
            e.target.clientHeight + 10;
        if (bottom) {
          hasNextPage && fetchNextPage();
        }
      }
    },
    [fetchNextPage, hasNextPage],
  );

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, true);
    return () => {
      window.removeEventListener('scroll', handleScroll, true);
    };
  }, [handleScroll]);

  function handleRowClick(item: PortalAlerts) {
    if (item.Id !== selectedAlert?.Id) {
      setSelectedAlert(item);
    } else {
      setSelectedAlert(undefined);
    }
  }

  function onDownload() {
    const params = {
      filterOptions: filterOptions,
      pageParam: pageLength,
    };
    downloadAsCsv(params, {
      onSuccess: () => {
        showSuccessToast({
          message: downloadCsvMsg,
        });
      },
    });
  }

  function renderCount(): ReactElement {
    return (
      <>
        {data?.pages &&
          data.pages.length > 0 &&
          data?.pages[0]?.data?.length > 0 &&
          data.pages[data.pages.length - 1]?.data.length && (
            <p className="text-xs text-gray-600 dark:text-neutral-400">
              <span>
                Showing 1 to{' '}
                <strong>
                  {' '}
                  {data?.pages[data?.pages?.length - 1]?.data?.length ===
                  pageLength
                    ? data?.pages?.length * pageLength
                    : (data?.pages?.length - 1) * pageLength +
                      data?.pages[data?.pages?.length - 1]?.data.length}{' '}
                </strong>{' '}
                of{' '}
                <strong>
                  {' '}
                  {data?.pages[data?.pages?.length - 1]?.recordsFiltered}
                </strong>{' '}
                entries (filtered from{' '}
                <strong> {data?.pages[0]?.recordsTotal} </strong> total entries)
                {isFetching ? ' | Processing' : ''}
              </span>
            </p>
          )}
      </>
    );
  }

  function disclosureContent(): ReactElement {
    return (
      <Disclosure>
        {({ open }) => (
          <>
            <div className="flex items-center justify-between gap-4 border-t border-gray-200 bg-white px-2 py-1 shadow dark:border-neutral-700/50 dark:bg-neutral-800 sm:px-3 lg:px-4">
              <div className="flex min-w-0 flex-1 items-end gap-4 sm:gap-6">
                <h2 className="truncate text-lg font-semibold text-gray-950 dark:text-white sm:text-xl sm:tracking-tight">
                  Portal alerts
                </h2>
                <div className="mt-1 flex items-center gap-2 sm:mt-0">
                  <div className="relative inline-flex">
                    <Disclosure.Button
                      as={Button}
                      size="small"
                      variant={open ? 'primary' : 'subtle'}
                    >
                      <FunnelIcon
                        className="-ml-0.5 h-4 w-4"
                        aria-hidden="true"
                      />
                      <span className="hidden sm:inline-block">Filter</span>
                    </Disclosure.Button>

                    <span className="absolute right-0 top-0 -mr-1.5 -mt-1.5 flex h-5 w-5">
                      {filterCount > 0 ? (
                        <span
                          className={`relative inline-flex h-5 w-5 items-center justify-center rounded-full bg-red-600 text-xs font-bold leading-none text-white`}
                        >
                          {filterCount}
                        </span>
                      ) : null}
                    </span>
                  </div>

                  <Button
                    variant="subtle"
                    size="small"
                    onClick={() => {
                      setSelectedAlert(undefined);
                      resetFilters();
                    }}
                  >
                    <ArrowPathIcon
                      className="-ml-0.5 h-5 w-5"
                      aria-hidden="true"
                    />
                    <span className="hidden sm:block">Reset</span>
                  </Button>
                </div>
              </div>

              <div className="relative hidden h-7 md:block">
                <div className="flex gap-x-4 gap-y-1 text-center"></div>
              </div>

              <UserActions
                contentLength={data ? data?.pages.length : undefined}
                alertId={selectedAlert?.Id}
                downloadAsCsv={onDownload}
              />
            </div>

            {/* Filter */}
            <Disclosure.Panel
              as="div"
              className="fixed bottom-0 left-0 top-0 z-10 flex w-full flex-col bg-white shadow dark:bg-neutral-800 sm:static sm:bottom-auto sm:left-auto sm:top-auto sm:block sm:w-auto"
            >
              {({ close }) => (
                <>
                  <div className="flex items-center border-b border-b-light-light py-1 pe-1 ps-2 dark:border-b-dark-medium sm:hidden">
                    <h3 className="grow text-lg font-medium">Filter</h3>
                    <Button
                      variant="subtle"
                      onClick={() => {
                        close();
                      }}
                    >
                      <XMarkIcon className="h-5 w-5" />
                    </Button>
                  </div>

                  <div className="min-h-0 grow overflow-y-auto p-2 pb-3 sm:overflow-y-visible sm:px-3 lg:px-4">
                    <PortalAlertSort
                      filterOptions={filterOptions}
                      setValues={setValues}
                    />
                    <div
                      className="my-4 border-b border-gray-200 dark:border-neutral-700/50"
                      aria-hidden="true"
                    ></div>

                    <PortalAlertFilter
                      filterOptions={filterOptions}
                      setValues={setValues}
                    />
                  </div>
                  <div className="flex flex-col gap-1 border-t border-t-light-light p-2 dark:border-b-dark-medium sm:hidden">
                    <Button
                      className="grow"
                      variant="link"
                      onClick={() => {
                        resetFilters();
                      }}
                    >
                      Reset
                    </Button>
                    <div className="flex gap-1">
                      <Button
                        className="grow"
                        onClick={() => {
                          close();
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        className="grow"
                        variant="primary"
                        onClick={() => {
                          close();
                        }}
                      >
                        Apply
                      </Button>
                    </div>
                  </div>
                </>
              )}
            </Disclosure.Panel>
            <p className="flex min-w-0 items-center justify-end gap-1 border-t bg-blue-50 p-1 font-medium  text-sky-700 shadow dark:border-neutral-700 dark:bg-neutral-800 dark:text-sky-300">
              <InformationCircleIcon
                className="-ml-0.5 hidden h-4 w-4 shrink-0 md:inline-block"
                aria-hidden="true"
              />
              <span className="text-xs">{localTzMsg}</span>
            </p>
          </>
        )}
      </Disclosure>
    );
  }

  return (
    <>
      <main className="flex flex-1 flex-col overflow-y-auto overscroll-contain">
        {disclosureContent()}

        <div className="flex min-h-[40px] items-center justify-between gap-x-4 gap-y-2 px-2 py-1 sm:px-3 lg:px-4">
          {renderCount()}
          {selectedAlert && data && data?.pages?.length > 0 && (
            <div className="relative h-7">
              <Button
                variant="subtle"
                size="small"
                className="hidden whitespace-nowrap md:flex"
                onClick={() => {
                  resetStore();
                  navigate(`/PortalAlert/Edit/${selectedAlert?.Id}`);
                }}
              >
                <PencilIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                Edit alert
              </Button>
              <Button
                variant="secondary"
                size="small"
                className="flex whitespace-nowrap md:hidden"
                onClick={() => {
                  resetStore();
                  navigate(`/PortalAlert/Edit/${selectedAlert?.Id}`);
                }}
              >
                <PencilIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                Edit alert
              </Button>
            </div>
          )}
        </div>

        <div
          className="relative flex-1 space-y-2 overflow-y-scroll px-2 pb-2 sm:px-3 sm:pb-3 lg:px-4 lg:pb-4"
          ref={tbodyRef}
        >
          {!isListLoading && data?.pages ? (
            <>
              {data?.pages.map((page: PortalAlertListType, index: number) => (
                <Fragment key={`${page} - ${index + 1}`}>
                  {page?.data && page?.data?.length ? (
                    page?.data.map((alertInfo: PortalAlerts, indx: number) => (
                      <ListItem
                        key={`${alertInfo.Id}-${indx + 1}`}
                        alertInfo={alertInfo}
                        selectedAlert={selectedAlert}
                        handleRowClick={handleRowClick}
                      />
                    ))
                  ) : (
                    <div className="absolute inset-0 grid place-content-center">
                      <EmptyState message="No information available" />
                    </div>
                  )}
                </Fragment>
              ))}
            </>
          ) : (
            (isListLoading || isFetching) && <Skeleton count={8} />
          )}
          {(isRefetching || isFetchingNextPage || isDownloading) && (
            <div className="fixed inset-0 z-20 grid place-content-center">
              <Spinner size="large" />
            </div>
          )}
        </div>
      </main>
    </>
  );
}
