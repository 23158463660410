function formatStore(store: any) {
  let userPermissions = store.userSettingsPermissions?.map(
    (item: any) => item?.PermissionId,
  );

  const user = {
    firstName: store.basicUserDetails.firstName,
    lastName: store.basicUserDetails.lastName,
    email: store.basicUserDetails.email,
    accountsInfo: store.accountsInfo
      ?.map((item: any) => item)
      .sort((a: any, b: any) => a - b),
    userSettingsPermissions: userPermissions?.sort((a: any, b: any) => a - b),
    customEmailNotificationSettings:
      store.defaultEmailNotification?.id === 8
        ? store.customEmailNotificationSettings
            ?.map((item: any) => ({
              NotifyTime: item?.NotifyTime,
              Id: item?.Id,
            }))
            .sort((a: any, b: any) => a.NotifyTime - b?.NotifyTime)
        : [],

    includeEmptyNotification: store.includeEmptyNotification,
    selectedOptions: store.selectedOptions,
    accountQuestionSettings: store.accountQuestionSettings
      ?.map((item: any) => ({
        AccessTypeId: item?.AccessTypeId,
        HasLimitedAccessLookups: item?.HasLimitedAccessLookups,
        AccountId: item?.AccountId,
      }))
      .sort((a: any, b: any) => a.AccountId - b.AccountId),
    defaultEmailNotification: store.defaultEmailNotification,
  };
  return user;
}

//checking the data equal
function isUserDataEqual(initialData: any, currentData: any) {
  let tempInitialData = {
    ...initialData,
    customEmailNotificationSettings:
      initialData?.customEmailNotificationSettings?.map(
        (item: any) => item?.NotifyTime,
      ),
  };
  let tempCurrentData = {
    ...currentData,
    customEmailNotificationSettings:
      currentData?.customEmailNotificationSettings?.map(
        (item: any) => item?.NotifyTime,
      ),
  };

  if (JSON.stringify(tempInitialData) === JSON.stringify(tempCurrentData)) {
    return true;
  } else return false;
}
export { formatStore, isUserDataEqual };
