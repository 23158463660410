import dayjs from 'dayjs';

function equatingNullValues(value: any): any {
  if (
    value === null ||
    value === undefined ||
    value === '' ||
    value === '-1' ||
    value == -1
  ) {
    return null;
  }
  if (Array.isArray(value)) {
    return value.map(equatingNullValues);
  }
  if (typeof value === 'object' && value !== null) {
    const normalizedState: any = {};
    for (const key in value) {
      if (value.hasOwnProperty(key)) {
        normalizedState[key] = equatingNullValues(value[key]);
      }
    }
    return normalizedState;
  }
  return value;
}

function hasValidvalue(obj: any) {
  return Object.values(obj).some(
    (value: any) =>
      value !== null &&
      value !== '' &&
      value !== undefined &&
      value?.length !== 0 &&
      Object?.values(value)?.length > 0,
  );
}

//function to compare birth date
function birthDateComparison(alertDetails: any, initialAlert: any) {
  const initialBirthDate = dayjs(
    initialAlert?.Person?.PersonAlertDetails?.Birthdate,
  ).format('MM/DD/YYYY');
  const updatedBirthDate = dayjs(
    alertDetails?.Person?.PersonAlertDetails?.Birthdate,
  ).format('MM/DD/YYYY');
  if (initialBirthDate === updatedBirthDate) return true;
  else return false;
}

function formatInitialAlerts(alertDetails: any, accountsInfo: any) {
  const modifiedAlert = {
    ...alertDetails,
    alertDate: alertDetails?.alertDate.replace(/\s+/g, ''),
    Person: {
      ...alertDetails?.Person,
      PersonCallLimit:
        alertDetails?.Person?.PersonCallLimit === null
          ? null
          : {
              MaxCallCount:
                alertDetails?.Person?.PersonCallLimit?.MaxCallCount !== null
                  ? parseInt(
                      alertDetails?.Person?.PersonCallLimit?.MaxCallCount,
                    )
                  : null,
              ResetInterval:
                alertDetails?.Person?.PersonCallLimit?.ResetInterval?.slice(
                  0,
                  5,
                ),
              CallsCalled:
                alertDetails?.Person?.PersonCallLimit?.CallsCalled !== null
                  ? parseInt(alertDetails?.Person?.PersonCallLimit?.CallsCalled)
                  : 0,
              TimeZoneId:
                alertDetails?.Person?.PersonCallLimit?.TimeZoneId !== null &&
                alertDetails?.Person?.PersonCallLimit?.TimeZoneId !== undefined
                  ? parseInt(alertDetails?.Person?.PersonCallLimit?.TimeZoneId)
                  : null,
            },
    },
    accountsInfo: { ...accountsInfo },
    hasCallLimit: alertDetails?.Person?.PersonCallLimit !== null ? true : false,
    newPerson: false,
  };
  return modifiedAlert;
}

function isNullCheck(val: any) {
  if (val === null || val === undefined || val === '') return '';
  else return val;
}

function convertToNumber(key: string, value: any) {
  return typeof value[`${key}`] === 'string'
    ? parseInt(value[`${key}`])
    : value[`${key}`];
}

function commonFormatterFunction(store: any) {
  const value = {
    accountsInfo: isNullCheck(store.accountsInfo?.Id),
    id: isNullCheck(store.alertDetails?.Id),
    enteredBy: isNullCheck(store.alertDetails?.EnteredBy),
    enteredDate: isNullCheck(store.alertDetails?.EnteredDate),
    alertDate: isNullCheck(store.alertDetails?.alertDate?.replaceAll(' ', '')),
    reasons: store.alertDetails?.Reasons?.map((item: any) => item?.Id).sort(
      (a: any, b: any) => a - b,
    ),
    firstName: isNullCheck(store.alertDetails?.Person?.FirstName),
    lastName: isNullCheck(store.alertDetails?.Person?.lastName),
    preferredName: isNullCheck(store.alertDetails?.Person?.PreferredName),
    birthdate: dayjs(
      store.alertDetails?.Person?.PersonAlertDetails?.Birthdate,
    ).format('MM/DD/YYYY'),

    age: isNullCheck(store.alertDetails?.Person?.PersonAlertDetails?.Age),
    street1: isNullCheck(
      store.alertDetails?.Person?.PersonAlertDetails?.PrimaryAddress?.Street1,
    ),
    street2: isNullCheck(
      store.alertDetails?.Person?.PersonAlertDetails?.PrimaryAddress?.Street2,
    ),
    street3: isNullCheck(
      store.alertDetails?.Person?.PersonAlertDetails?.PrimaryAddress?.Street3,
    ),
    city: isNullCheck(
      store.alertDetails?.Person?.PersonAlertDetails?.PrimaryAddress?.City,
    ),
    countryId:
      store.alertDetails?.Person?.PersonAlertDetails?.PrimaryAddress
        ?.CountryId === -1
        ? null
        : store.alertDetails?.Person?.PersonAlertDetails?.PrimaryAddress
            ?.CountryId,

    stateProvinceId:
      store.alertDetails?.Person?.PersonAlertDetails?.PrimaryAddress
        ?.StateProvinceId === -1
        ? null
        : store.alertDetails?.Person?.PersonAlertDetails?.PrimaryAddress
            ?.StateProvinceId,
    zipPostalCode: isNullCheck(
      store.alertDetails?.Person?.PersonAlertDetails?.PrimaryAddress
        ?.ZipPostalCode,
    ),
    county: isNullCheck(
      store.alertDetails?.Person?.PersonAlertDetails?.PrimaryAddress?.County,
    ),
    primaryPhoneTypeId: isNullCheck(
      store.alertDetails?.Person?.PersonAlertDetails?.PrimaryPhone
        ?.PhoneTypeId === null
        ? 1
        : store.alertDetails?.Person?.PersonAlertDetails?.PrimaryPhone
            ?.PhoneTypeId,
    ),
    primaryCountryId: isNullCheck(
      store.alertDetails?.Person?.PersonAlertDetails?.PrimaryPhone?.CountryId,
    ),
    primaryNumber: isNullCheck(
      store.alertDetails?.Person?.PersonAlertDetails?.PrimaryPhone?.Number,
    ),
    primaryComments: isNullCheck(
      store.alertDetails?.Person?.PersonAlertDetails?.PrimaryPhone?.Comments,
    ),
    secondaryPhoneTypeId:
      store.alertDetails?.Person?.PersonAlertDetails?.SecondaryPhone
        ?.PhoneTypeId === null
        ? 1
        : store.alertDetails?.Person?.PersonAlertDetails?.SecondaryPhone
            ?.PhoneTypeId,
    secondaryPhnCountryId: isNullCheck(
      store.alertDetails?.Person?.PersonAlertDetails?.SecondaryPhone?.CountryId,
    ),
    secondaryNumber: isNullCheck(
      store.alertDetails?.Person?.PersonAlertDetails?.SecondaryPhone?.Number,
    ),
    secondaryComments: isNullCheck(
      store.alertDetails?.Person?.PersonAlertDetails?.SecondaryPhone?.Comments,
    ),
    counselorOrCaseManager: isNullCheck(
      store.alertDetails?.CounselorOrCaseManager,
    ),
    specialProgramsParticipant: isNullCheck(
      store.alertDetails?.SpecialProgramsParticipant,
    ),
    situationDescription: isNullCheck(store.alertDetails?.SituationDescription),
    telephoneInterventionDescription: isNullCheck(
      store.alertDetails?.TelephoneInterventionDescription,
    ),
    hasCallLimit: isNullCheck(store?.hasCallLimit),
    maxCallCount: isNullCheck(
      store?.alertDetails?.Person?.PersonCallLimit?.MaxCallCount,
    ),
    resetInterval: isNullCheck(
      store?.alertDetails?.Person?.PersonCallLimit?.ResetInterval,
    )?.slice(0, 5),
    timeZoneId: isNullCheck(
      store?.alertDetails?.Person?.PersonCallLimit?.TimeZoneId,
    ),
    callsCalled: isNullCheck(
      store?.alertDetails?.Person?.PersonCallLimit?.CallsCalled,
    )
      ? isNullCheck(store?.alertDetails?.Person?.PersonCallLimit?.CallsCalled)
      : 0,
    newPerson: store?.newPerson ? store?.newPerson : false,
  };
  console.log('callscalled', value.callsCalled);
  const formattedValue = {
    ...value,
    callsCalled: convertToNumber('callsCalled', value),
    timeZoneId: convertToNumber('timeZoneId', value),
    maxCallCount: convertToNumber('maxCallCount', value),
    primaryPhoneTypeId: convertToNumber('primaryPhoneTypeId', value),
    primaryCountryId: convertToNumber('primaryCountryId', value),
    secondaryPhoneTypeId: convertToNumber('secondaryPhoneTypeId', value),
    secondaryPhnCountryId: convertToNumber('secondaryPhnCountryId', value),

    // callsCalled:
    //   typeof value.callsCalled === 'string'
    //     ? parseInt(value.callsCalled)
    //     : value.callsCalled,
    // timeZoneId:
    //   typeof value.timeZoneId === 'string'
    //     ? parseInt(value.timeZoneId)
    //     : value.timeZoneId,
    // maxCallCount:
    //   typeof value.maxCallCount === 'string'
    //     ? parseInt(value.maxCallCount)
    //     : value.maxCallCount,
    //

    //   typeof value.primaryPhoneTypeId === 'string'
    //     ? parseInt(value.primaryPhoneTypeId)
    //     : value.primaryPhoneTypeId,

    // primaryCountryId:
    //   typeof value.primaryCountryId === 'string'
    //     ? parseInt(value.primaryCountryId)
    //     : value.primaryCountryId,

    // secondaryPhoneTypeId:
    //   typeof value.secondaryPhoneTypeId === 'string'
    //     ? parseInt(value.secondaryPhoneTypeId)
    //     : value.secondaryPhoneTypeId,
    //     secondaryPhnCountryId:
  };
  return formattedValue;
}

function formatcurrentAlert(
  alertDetails: any,
  accountsInfo: any,
  hasCallLimit: boolean,
  newPerson: boolean,
) {
  let newAlertDetails = {
    ...alertDetails,
    accountsInfo: { ...accountsInfo },
    hasCallLimit: hasCallLimit,
    newPerson: newPerson,
    alertDate: alertDetails?.alertDate.replace(/\s+/g, ''),
    Person: {
      ...alertDetails?.Person,
      PersonCallLimit:
        hasCallLimit === false
          ? null
          : {
              MaxCallCount: parseInt(
                alertDetails?.Person?.PersonCallLimit?.MaxCallCount,
              ),
              ResetInterval:
                alertDetails?.Person?.PersonCallLimit?.ResetInterval?.slice(
                  0,
                  5,
                ),
              CallsCalled: alertDetails?.Person?.PersonCallLimit?.CallsCalled
                ? parseInt(alertDetails?.Person?.PersonCallLimit?.CallsCalled)
                : 0,
              TimeZoneId: parseInt(
                alertDetails?.Person?.PersonCallLimit?.TimeZoneId,
              ),
            },
    },
  };
  if (newAlertDetails?.alertDetails?.hasCallLimit === false) {
    let person = {
      ...alertDetails?.Person,
    };
    delete person['PersonCallLimit'];
    newAlertDetails = { ...newAlertDetails, Person: { ...person } };
  }
  return newAlertDetails;
}
export {
  hasValidvalue,
  formatInitialAlerts,
  equatingNullValues,
  formatcurrentAlert,
  birthDateComparison,
  commonFormatterFunction,
};
