import React, { ReactElement, useMemo } from 'react';
import {
  EyeIcon,
  CheckCircleIcon,
  ArrowDownOnSquareIcon,
} from '@heroicons/react/20/solid';
import { ArrowTopRightOnSquareIcon as ArrowTopRightOnSquareIconMicro } from '@heroicons/react/16/solid';
import dayjs from 'dayjs';

import { Skeleton } from 'src/ui/components';

import { useGetDocumentActivity } from '../api';

import { initialState } from 'src/features/ActivityLog/utils/activityFilterConstants';

import { ActivityLogListType } from 'src/features/ActivityLog/types/activityLogType';

function DocumentActivity(): ReactElement {
  const { data: documentActivity, isLoading } =
    useGetDocumentActivity(initialState);

  const filteredActivities = useMemo(() => {
    if (documentActivity && documentActivity?.length) {
      return documentActivity?.filter(item => item.HasReport);
    }
  }, [documentActivity]);

  function getActivityTime(createdOn: string) {
    let activityTime = '';
    if (
      dayjs(createdOn).format('MM/DD/YYYY') === dayjs().format('MM/DD/YYYY')
    ) {
      activityTime = dayjs(createdOn).format('h:mm a');
    } else activityTime = dayjs(createdOn).format('MMM DD');
    return activityTime;
  }

  function getActivityIcon(item: ActivityLogListType) {
    switch (item.Activity) {
      case 'View':
        return (
          <EyeIcon className="h-4 w-4 shrink-0 text-gray-400 group-active:translate-y-px dark:text-neutral-500" />
        );
      case 'Finalized':
        return (
          <CheckCircleIcon className="h-4 w-4 shrink-0 text-gray-400 group-active:translate-y-px dark:text-neutral-500" />
        );
      case 'Download as pdf':
        return (
          <ArrowDownOnSquareIcon className="h-4 w-4 shrink-0 text-gray-400 group-active:translate-y-px dark:text-neutral-500" />
        );
      case 'Download as html':
        return (
          <ArrowDownOnSquareIcon className="h-4 w-4 shrink-0 text-gray-400 group-active:translate-y-px dark:text-neutral-500" />
        );
      case 'Download as doc':
        return (
          <ArrowDownOnSquareIcon className="h-4 w-4 shrink-0 text-gray-400 group-active:translate-y-px dark:text-neutral-500" />
        );
    }
  }

  function getFileName(item: ActivityLogListType) {
    const type =
      item?.ReportFileName?.split('_')[0].length < 3
        ? item?.ReportFileName?.split('_')[0].concat('_')
        : item?.ReportFileName?.split('_')[0];
    const reqName = type.concat('_', item?.CID, '_', item?.CallId?.toString());
    return reqName;
  }

  function renderActivityItem(item: ActivityLogListType) {
    return (
      <p
        key={item.Id}
        className="group flex items-center gap-2 px-3 py-3 hover:cursor-pointer hover:bg-sky-50 active:bg-sky-100 dark:hover:bg-neutral-700/50 dark:active:bg-neutral-700/50"
        onClick={() => {
          localStorage.setItem('isFromDashboard', 'true');
          window.open(`CallDocuments/Preview/${item.CallId}`);
        }}
      >
        {getActivityIcon(item)}
        <span className=" flex grow">
          <span className="prevent-text-breakout font-medium leading-tight text-sky-700 group-hover:underline group-active:translate-y-px dark:text-sky-400">
            {getFileName(item)}
          </span>
          <ArrowTopRightOnSquareIconMicro
            title="This link will open in a new tab"
            className="ms-2 h-4 w-4 shrink-0 opacity-0 group-hover:opacity-50 [@media(hover:none)]:opacity-50"
          />
        </span>
        <span className="leading-tight text-gray-500 group-active:translate-y-px dark:text-neutral-400 ">
          {getActivityTime(item.CreatedOn)}
        </span>
      </p>
    );
  }

  return (
    <div className="relative flex min-h-[300px] flex-col rounded-lg bg-white shadow  dark:bg-neutral-800">
      <h4 className="border-b border-light-light px-2 py-2 text-lg font-semibold dark:border-dark-medium md:px-4">
        Recent document activity
      </h4>
      <div className="relative max-h-96 min-h-0 grow divide-y divide-light-light overflow-y-auto overscroll-contain text-sm dark:divide-dark-medium lg:max-h-full">
        {!isLoading ? (
          filteredActivities && filteredActivities.length ? (
            filteredActivities.map((item: ActivityLogListType) => {
              if (item?.ReportFileName !== null)
                return renderActivityItem(item);
            })
          ) : (
            <div className="absolute inset-0 flex items-center justify-center">
              <p className=" text-center text-sm text-gray-500 dark:text-neutral-300">
                No information available
              </p>
            </div>
          )
        ) : (
          isLoading && <Skeleton count={8} />
        )}
      </div>
    </div>
  );
}

export { DocumentActivity };
