import React, {
  Fragment,
  ReactElement,
  useCallback,
  useState,
  useRef,
  useEffect,
} from 'react';
import { useQueryClient } from 'react-query';
import { Dialog, Tab, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/20/solid';

import { Button, Spinner, CustomCancelModal, Alert } from 'src/ui/components';
import { PersonDetailsTab } from './components';
import { QuestionsTab } from './Questions';

import { useGetPhoneType, useGetStoredPermissions } from 'src/hooks';
import { useEditPersonDetails } from '../../api';
import { useMutableObserver } from 'src/hooks/useMutableObserver';

import { formatSelectOptionWithoutSelect } from 'src/utils/common';
import { classNames } from 'src/utils/className';
import {
  successMsg,
  warningMsg,
  cancelAlertWarning,
} from '../../utils/personRepoConstants';
import { showSuccessToast } from 'src/utils/ToastNotification';
import { usePersonRepoStore } from '../../store/personRepoStore';

type ModalType = {
  showModal: boolean;
  setShowModal: (val: boolean) => void;
};

function ViewEditModal({ showModal, setShowModal }: ModalType): ReactElement {
  const queryClient = useQueryClient();
  const warningMsgRef = useRef<HTMLDivElement | null>(null);
  const dateRangewrapper = useRef<any>();

  const permissions = useGetStoredPermissions();

  const { isMessageChanged } = useMutableObserver(warningMsgRef);

  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);

  // to store current tab
  const [currentTab, setCurrentTab] = useState<string>('personTab');

  const personDetails = usePersonRepoStore(
    useCallback(state => state.personDetails, []),
  );

  const userMode = usePersonRepoStore(useCallback(state => state.userMode, []));

  const setUserMode = usePersonRepoStore(
    useCallback(state => state.setUserMode, []),
  );

  const enableSaveBtn = usePersonRepoStore(
    useCallback(state => state.enableSaveBtn, []),
  );

  const isSaveClicked = usePersonRepoStore(
    useCallback(state => state.isSaveClicked, []),
  );

  const setIsSaveClicked = usePersonRepoStore(
    useCallback(state => state.setIsSaveClicked, []),
  );

  const isPersonInformationLoading = usePersonRepoStore(
    useCallback(state => state.isPersonInformationLoading, []),
  );

  const resetStore = usePersonRepoStore(
    useCallback(state => state.resetStore, []),
  );

  const setSelectedPerson = usePersonRepoStore(
    useCallback(state => state.setSelectedPerson, []),
  );

  const showPrimaryNumberError = usePersonRepoStore(
    useCallback(state => state.showPrimaryNumberError, []),
  );

  const showSecondaryNumberError = usePersonRepoStore(
    useCallback(state => state.showSecondaryNumberError, []),
  );

  const checkDataHasChanged = usePersonRepoStore(
    useCallback(state => state.checkDataHasChanged, []),
  );

  const { data: phoneTypes, isLoading: isPhoneTypeLoading } = useGetPhoneType();
  const { mutate: editPersonDetails, isLoading: isUpdating } =
    useEditPersonDetails();

  const isSpinnerLoading =
    isPersonInformationLoading || isPhoneTypeLoading || isUpdating;

  const phoneTypeList = formatSelectOptionWithoutSelect(
    phoneTypes,
    'Id',
    'Description',
  );

  const updatePhone = (phoneDetails: any) => ({
    ...phoneDetails,
    PhoneTypeId:
      phoneDetails.PhoneTypeId === '-1' ? null : phoneDetails.PhoneTypeId,
    PhoneType:
      phoneDetails.PhoneTypeId === '-1' ? null : phoneDetails.PhoneType,
  });

  //for save person details
  function onSavePersonDetails() {
    const firstName = personDetails?.FirstName?.trim();
    const lastName = personDetails?.LastName?.trim();
    const prefix = personDetails?.Prefix?.trim();
    const suffix = personDetails?.Suffix?.trim();
    const preferredName = personDetails?.PreferredName?.trim();
    const genderId =
      personDetails?.GenderId === '-1' || personDetails?.GenderId === -1
        ? null
        : personDetails?.GenderId;

    if (
      firstName === undefined ||
      lastName === undefined ||
      firstName === '' ||
      lastName === ''
    ) {
      return;
    }

    const updatedPersonDetails = {
      ...personDetails,
      FirstName: firstName,
      LastName: lastName,
      Prefix: prefix,
      Suffix: suffix,
      PreferredName: preferredName,
      Age: null,
      GenderId: genderId,
      PrimaryPhone: updatePhone(personDetails.PrimaryPhone),
      SecondaryPhone: updatePhone(personDetails.SecondaryPhone),
      Birthdate: personDetails.Birthdate
        ? new Date(personDetails.Birthdate).toISOString()
        : null,
    };

    editPersonDetails(updatedPersonDetails, {
      onSuccess: () => {
        showSuccessToast({ message: successMsg });
        setShowModal(false);
        setIsSaveClicked(false);
        resetStore();
        queryClient.invalidateQueries(['get_person_repo_list'], {
          refetchActive: true,
        });
        setSelectedPerson(undefined);
      },
    });
  }

  //for getting button label
  function getLabel() {
    if (userMode === 'Edit') return 'Edit';
    else return 'Update';
  }

  //for handle cancel
  function handleCancel() {
    setCurrentTab('personTab');
    setShowModal(false);
    setIsSaveClicked(false);
    resetStore();
    queryClient.invalidateQueries(['get-person-details'], {
      refetchActive: false,
    });
  }

  function renderFooterButtons() {
    return (
      <div
        ref={warningMsgRef}
        className="flex flex-col justify-end gap-[1ch] border-t bg-gray-50 p-2 dark:border-dark-medium dark:bg-neutral-700/50 sm:flex-row  sm:p-4"
      >
        {isSaveClicked && !enableSaveBtn() && currentTab === 'personTab' && (
          <div
            className={`flex items-center gap-2 ${
              isMessageChanged ? 'animate-shake' : ''
            }`}
          >
            <span className="relative flex h-3 w-3 shrink-0 items-center justify-center">
              <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-red-400 opacity-75" />
              <span className="relative inline-flex h-2 w-2 rounded-full bg-red-500" />
            </span>
            <span className="text-sm font-medium text-red-700 dark:text-red-400">
              {warningMsg}
            </span>
          </div>
        )}
        <div className="flex flex-col  justify-between gap-[1ch] md:flex-row md:items-center">
          {checkDataHasChanged() === false && currentTab === 'questionTab' && (
            <Alert variant="warning" size="small">
              {cancelAlertWarning}
            </Alert>
          )}
          <div className="flex gap-[1ch]">
            <Button
              variant="secondary"
              className="grow sm:grow-0"
              onClick={() => cancelHandler()}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              className="grow sm:grow-0"
              onClick={() => {
                setUserMode('Update');
                if (userMode === 'Update') {
                  setIsSaveClicked(true);
                  onSavePersonDetails();
                }
              }}
              disabled={
                currentTab === 'questionTab' ||
                !(
                  permissions?.CallCenterSupervisors ||
                  permissions?.RelationshipManager
                ) ||
                (userMode === 'Update' &&
                  (checkDataHasChanged() ||
                    showPrimaryNumberError ||
                    showSecondaryNumberError))
              }
            >
              {getLabel()}
            </Button>
          </div>
        </div>
      </div>
    );
  }

  function cancelHandler() {
    if (userMode === 'Update' && checkDataHasChanged() === false)
      setShowCancelModal(true);
    else handleCancel();
  }

  return (
    <>
      <Transition appear show={showModal} as={Fragment}>
        <Dialog
          as="div"
          className="relative"
          onClose={() => {
            if (userMode === 'Update' && checkDataHasChanged() === false) {
              setShowCancelModal(true);
            } else {
              setShowModal(false);
              resetStore();
            }
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="pr-dialog__backdrop" />
          </Transition.Child>

          <div className="pr-dialog__wrapper">
            <div className="pr-dialog__panel__container">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="pr-dialog__panel flex h-[calc(100svh-110px)] max-h-[calc(100svh-110px)] flex-col sm:!max-w-5xl md:h-auto">
                  {isSpinnerLoading && (
                    <div className="bg-gray-900/8  absolute inset-0 z-50 grid place-items-center backdrop-blur-sm">
                      <Spinner size="large" />
                    </div>
                  )}

                  <div className="flex items-center gap-3 border-b p-4 dark:border-dark-medium">
                    <Dialog.Title
                      as="h3"
                      className="grow truncate text-lg font-medium leading-6 text-gray-900 dark:text-white"
                    >
                      {personDetails?.FirstName} {personDetails?.LastName}
                    </Dialog.Title>

                    <button
                      type="button"
                      className=" text-gray-400 hover:text-gray-500 sm:right-4 sm:top-4"
                      onClick={() => cancelHandler()}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>

                  <Tab.Group>
                    <Tab.List className="flex justify-center gap-5 border-b border-gray-200 dark:border-neutral-700">
                      <Tab
                        as="button"
                        className={classNames(
                          'border-b-2 border-b-transparent px-2 py-2.5 font-medium leading-5',
                          ' focus-visible:outline focus-visible:outline-[3px] focus-visible:outline-offset-[3px] focus-visible:outline-light-focus active:translate-y-px active:shadow-none dark:focus-visible:outline-dark-focus',
                          'ui-selected:border-b-primary-600 ui-selected:text-primary-600 dark:ui-selected:border-b-primary-600 dark:ui-selected:text-primary-300',
                          'ui-not-selected:hover:text-gray-600 dark:ui-not-selected:hover:text-gray-400',
                        )}
                        onClick={() => {
                          setCurrentTab('personTab');
                        }}
                      >
                        <span>Personal details</span>
                      </Tab>

                      <Tab
                        as="button"
                        className={classNames(
                          'border-b-2 border-b-transparent px-2 py-2.5 font-medium leading-5',
                          ' focus-visible:outline focus-visible:outline-[3px] focus-visible:outline-offset-[3px] focus-visible:outline-light-focus active:translate-y-px active:shadow-none dark:focus-visible:outline-dark-focus',
                          'ui-selected:border-b-primary-600 ui-selected:text-primary-600 dark:ui-selected:border-b-primary-600 dark:ui-selected:text-primary-300',
                          'ui-not-selected:hover:text-gray-600 dark:ui-not-selected:hover:text-gray-400',
                        )}
                        onClick={() => {
                          setCurrentTab('questionTab');
                          // onQuestionTabClickHandler();
                        }}
                      >
                        <span>Questions</span>
                      </Tab>
                    </Tab.List>

                    <Tab.Panel
                      className="relative min-h-0 grow overflow-auto scroll-smooth px-4 pb-4"
                      ref={dateRangewrapper}
                    >
                      <PersonDetailsTab refValue={dateRangewrapper} />
                    </Tab.Panel>

                    <Tab.Panel className="relative min-h-0 grow overflow-auto scroll-smooth px-4 pb-4">
                      <QuestionsTab
                        // questionDet={questions}
                        phoneType={phoneTypeList}
                      />
                    </Tab.Panel>
                  </Tab.Group>

                  {renderFooterButtons()}
                </Dialog.Panel>
              </Transition.Child>
            </div>
            {showCancelModal === true && checkDataHasChanged() === false && (
              <CustomCancelModal
                setShowCancelModal={setShowCancelModal}
                showCancelModal={showCancelModal}
                handleCancel={handleCancel}
              />
            )}
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

export { ViewEditModal };
