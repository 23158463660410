export const apiBaseUrl = `${process.env.REACT_APP_API_END_POINT}`;
export const identityUrl = `${process.env.REACT_APP_AUTH_URL}`;
export const resourceBaseUrl = `${process.env.REACT_APP_RESOURCE_API_END_POINT}`;

//Users - get user permissions
export const getUserPermissions = `${apiBaseUrl}accounts/permissions`;
//Users - get permission group code
export const getPermissionGroupCode = (groupCodeId: string) =>
  `${apiBaseUrl}permissions?permissionGroupCode=${groupCodeId}`;
// Users - get users list
export const getUsersList = `${apiBaseUrl}users/grid`;
//Users - get user email notification settings
export const getUserEmailNotificationSettings = `${apiBaseUrl}user-email-notification-settings/getAllNotificationSettings`;
//Users - get default email notification settings
export const getDefaultEmailNotificationSettings = `${apiBaseUrl}users/default-email-notification-settings`;
//Users - to get admin settings
export const getAdminSettings = `${apiBaseUrl}admin/settings`;
//User - get account information
export const getAccountInfo = `${apiBaseUrl}accounts/info`;
//User - get account information
export const getUserAccountInfo = (id: string) =>
  `${apiBaseUrl}accounts/info/${id}`;
//User - get updated account info
export const getAccountList = `${apiBaseUrl}accounts/info-grid`;
//Users - get user details
export const getUserDetails = (id: string) => `${apiBaseUrl}users/${id}`;
//Users - get user onboarding status
export const getUserOnboardingStatus = (id: string) =>
  `${apiBaseUrl}users/isUserOnboarding/${id}`;
// Users - get limited access records
export const getLimitedAccessLookup = (accountId: number) =>
  `${apiBaseUrl}accounts/${accountId}/lookups/limited-access-records`;
//Users - change password of user
export const changeUserPassword = `${apiBaseUrl}users/change-user-password`;
//Users - change user email
export const changeUserEmail = `${apiBaseUrl}users/changeEmail`;
//Users - get data transmission access
export const getDataTransmissionAccess = (
  accountId: number,
  recordId: number,
): string =>
  `${apiBaseUrl}data-transmission/${accountId}/lookups/${recordId}/dt-access-user`;
// Users - get email notification settings
export const getUserEmailNotification = (userId: string | number) =>
  `${apiBaseUrl}users/${userId}/email-notification-settings`;
//Users - revoke user
export const revokeUser = (userId: string) => `${apiBaseUrl}users/${userId}`;
//Users - create a new user
export const getNewUser = `${apiBaseUrl}users`;
//Users - update a new user
export const updateUserDetails = (id: string) => `${apiBaseUrl}users/${id}`;
// Users - download as csv file
export const downloadAsCsv = `${apiBaseUrl}users/download-csv`;
//Users - re-invite an invitation expired user
export const getReInviteUser = (userId: string) =>
  `${apiBaseUrl}users/reinviteuser?id=${userId}`;
//Users - Sent email link
export const sentEmailLink = (email: string) =>
  `${apiBaseUrl}users/resetPassword?email=${email}`;
//Users - external user dt access permission
export const externalUserDtPermissions = (id: string | undefined) =>
  `${apiBaseUrl}accounts/info/${id}`;

// to get state province details
export const getStateProvince = `${apiBaseUrl}state-province`;
// to get country details
export const getCountry = `${apiBaseUrl}country`;
// to get poral user group code
export const getPortalUserGroupCode = `${apiBaseUrl}portalusergroupcode`;

//CallDocuments - get sticky note details
export const getStickyNote = (callId: number) =>
  `${apiBaseUrl}calls/${callId}/sticky-note`;
//CallDocuments - post sticky note data
export const postStickyNote = `${apiBaseUrl}sticky-note`;
//CallDocuments - get taglist
export const getTagList = `${apiBaseUrl}accounts/tags`;
//CallDocuments - get call type
export const getCallType = `${apiBaseUrl}call-types/report-required`;
//CallDocuments - get call document list
export const getCallDocumentList = `${apiBaseUrl}data-transmission/finalizedgrid`;
//CallDocuments - update tags in call document
export const updateTag = `${apiBaseUrl}data-transmission/update-tag`;
//CallDocuments -download calldocs full list as csv
export const downloadCallDocAsCsv = `${apiBaseUrl}data-transmission/download-csv`;
//CallDocuments - download calldocs in selected format above 15 docs
export const downloadCallDocs = `${apiBaseUrl}data-transmission/call-documents-archived-report-download`;
//CallDocuments - download calldocs in selected format less than or equal to 15 docs
export const downloadCallFileDocs = (
  id: string,
  format: string,
  includeStickyNote: boolean,
) =>
  `${apiBaseUrl}data-transmission/report-download?callId=${id}&exportFormat=${format}&includeStikyNote=${includeStickyNote}`;
//CallDocuments - get preview of call document
export const getCallDocumentPreview = (documentId: number) =>
  `${apiBaseUrl}data-transmission/preview-report/${documentId}`;

export const getCallHistoryDocumentPreview = (documentId: number) =>
  `${apiBaseUrl}data-transmission/preview-report/${documentId}?isFromDocumentView=false`;

export const showNavigationPreview = (
  documentId: number,
  navigationParam: string,
) =>
  `${apiBaseUrl}data-transmission/preview-report/email/${documentId}?navigation=${navigationParam}`;
//CallDocuments - change the read status
export const changeReadStatus = `${apiBaseUrl}data-transmission/change-read-status`;
//Call Documents - download as csv
export const downloadZip = (callDocumentId: string) =>
  `${apiBaseUrl}/reports/download?fileName=${callDocumentId}`;

// to get user details & accounts
export const getUser = `${apiBaseUrl}users/get-user`;

//Activity Log - get activity log list
export const getActivityLog = `${apiBaseUrl}activity-log/get-logs`;
//Activity Log - get activity log dowload as csv
// export const getActivityLogDownloadAsCsv = (logParams: string) =>
//   `${apiBaseUrl}activity-log/export-to-csv?logParameters=${logParams}`;
export const activityLogDownloadAsCsv = `${apiBaseUrl}activity-log/download-as-csv`;

//Person alerts - get person alerts
export const getPersonAlerts = `${apiBaseUrl}accounts/any/alerts/grid`;
//Person alerts - download person alerts
export const getPersonAlertsDownload = `${apiBaseUrl}accounts/any/alerts/download-csv`;
//Person alerts - save person alert
export const savePersonAlerts = (accountId: number) =>
  `${apiBaseUrl}accounts/${accountId}/alerts`;
//Person alerts - accounts list
export const getPersonAlertsAccountsList = `${apiBaseUrl}Accounts/grid-nopurge`;
//Person alerts - alert reason
export const getPersonAlertsReason = `${apiBaseUrl}person-alert-reasons`;
//Person alerts -  find person
export const findPersonInformation = (accountId: number) =>
  `${apiBaseUrl}accounts/${accountId}/people/find`;
//Person alerts - get person alerts details and for updating alert
export const getPersonAlertsDetails = (Id: number, AccountId: number) =>
  `${apiBaseUrl}accounts/${AccountId}/alerts/${Id}`;
//Person alerts - get preview details for person alerts
export const getPersonAlertsPreview = (accountId: number, alertId: number) =>
  `${apiBaseUrl}accounts/${accountId}/alerts/${alertId}`;

// timezones
export const getTimezone = `${apiBaseUrl}timezone`;
//get people
export const getCaller = (
  accountId: number | undefined,
  Id: number | undefined,
) => `${apiBaseUrl}accounts/${accountId}/people/${Id}`;
// person phonetype
export const getPhoneType = `${apiBaseUrl}phonetype`;
//get postal code address
export const getPostalCode = (code: string) =>
  `${apiBaseUrl}postal-codes/${code}`;

//Extracoverage request - get extra coverage requests lists
//export const getExtraCoverageList = `${apiBaseUrl}extracoverage/grid`;
export const getExtraCoverageList = `${apiBaseUrl}/extracoverage/v1/grid`;
//Extracoverage request - get extra coverage requests status list
export const getExtraCoverageStatusList = `${apiBaseUrl}extra-coverage-statuses`;
//Extracoverage request - get extracoverage request details
export const getExtraCoverageDetails = (id: number) =>
  `${apiBaseUrl}extracoverage/${id}`;
//Extracoverage request - save extra coverage details
export const saveExtracoverage = `${apiBaseUrl}extracoverage`;
//Extracoverage request - extra coverage request type
export const getRequestType = `${apiBaseUrl}extra-coverage-types`;
//Extracoverage request - get default email structure
export const getDefaultEmail = (id: number) =>
  `${apiBaseUrl}extracoverage/${id}/defaultemail`;
//Extracoverage request - to send email
export const sendEmail = (id: number | undefined) =>
  `${apiBaseUrl}extracoverage/${id}/email`;
//Extracoverage request - extra coverage post account alert
export const postAccountAlert = (id: number) =>
  `${apiBaseUrl}extracoverage/v1/${id}/account-alert`;

//Portalalerts - get portal alerts list
export const getPortalAlerts = `${apiBaseUrl}portal-alerts/grid`;
//Portal alerts - create
export const postPortalAlert = `${apiBaseUrl}portal-alerts`;
//Portal alerts - update
export const updatePortalAlerts = (id: number) =>
  `${apiBaseUrl}portal-alerts/${id}`;

//Portal alerts - Fetch Portal alert details
export const getPortalAlertInfo = (id: string | undefined) =>
  `${apiBaseUrl}portal-alerts/${id}`;
//Portal alerts - download as csv
export const alertsDownloadAsCsv = `${apiBaseUrl}portal-alerts/download-csv`;

//Activity Tracker - list
export const getTrackerList = `${apiBaseUrl}hospital-form-tracker`;
//Activity Tracker - cancel activity
export const cancelActivity = (activityId: number) =>
  `${apiBaseUrl}hospital-form-tracker/${activityId}`;
//Activity Tracker -  preview
export const getActivityTrackerPreDet = (activityId: number) =>
  `${apiBaseUrl}hospital-form-tracker/form-response/${activityId}`;
//Activity Tracker - clinician list
export const getClinicianList = `${apiBaseUrl}clinician`;
//Activity Tracker - calltaker group
export const getCallTakerGroup = `${apiBaseUrl}calltakergroup`;
//Activity Tracker -  scheduledCall details
export const getScheduledCallDetails = (submissionId: number) =>
  `${apiBaseUrl}hospital-form-tracker/${submissionId}`;
//Activity Tracker -  save scheduled call details
export const submitScheduledCall = `${apiBaseUrl}schedule`;

//Onborading - user basic info
export const getBasicUserInfo = (id: string | null) =>
  `${apiBaseUrl}users/basicInfo/${id}`;
//Onborading - reset password
export const resetPassword = (
  id: string | null,
  token: string | null,
  isNewUserOnBoarding: boolean,
) =>
  `${apiBaseUrl}users/${id}/resetPassword?token=${token}&isNewUserOnBoarding=${isNewUserOnBoarding}`;
//Onborading - verify email
export const verifyEmail = (id: string | null, code: string | null) =>
  `${apiBaseUrl}users/${id}/verifyEmail?token=${code}`;
//Onborading - verify user
export const verifyUser = (id: string | null, code: string | null) =>
  `${apiBaseUrl}/users/${id}/verifyUser?token=${code}`;
//Onboarding - get Qrcode
export const getQrCode = () => `${identityUrl}/Identity/Account/getQRCode`;
//Onboarding - verify qr code
export const verifyQrCode = () =>
  `${identityUrl}/Identity/Account/verifyGoogleAuthCode`;
//Onboarding - set email as 2 factor authentication
export const emailAsTwoFactor = () =>
  `${identityUrl}/Identity/Account/SelectEmailAsTwofactorAuth`;
//Onboarding - update onboarding status
export const onbordingStatusUpdate = (id: string | null, status: string) =>
  `${apiBaseUrl}/users/updateOnboardingStatus/${id}?status=${status}`;
//Onboarding - get tutorial files
export const getTutorials = `${apiBaseUrl}tutorials`;
//Onboarding - download tutorial files
export const downloadTutorialFiles = (documentName: string) =>
  `${apiBaseUrl}Tutorials/download/${documentName}`;
//Onboarding - set default dashboard settings
export const defaultDashboardSettings = (id: string | null) =>
  `${apiBaseUrl}users/default-settings/customer-portal?id=${id}`;

//Resource -listing
export const getResourceList = `${resourceBaseUrl}v1/providers`;
export const removeProvider = (providerId: string) =>
  `${resourceBaseUrl}v1/providers/${providerId}`;
//Resource - sync provider
export const syncProvider = (id: string) =>
  `${resourceBaseUrl}v1/providers/${id}/sync`;
//Resource - field configuartion
export const fieldConfiguration = (id: string | undefined) =>
  `${resourceBaseUrl}v1/providers/${id}/configuration`;
//Resource - resourcefields
export const getResourceFields = (id: string) =>
  `${resourceBaseUrl}v1/providers/${id}/resourcefields`;
//Resource - search groups
export const getSearchGroup = () => `${resourceBaseUrl}v1/searchgroups`;

// Settings - reset Password

//get section tutorials
export const getAllSectionTutorials = `${apiBaseUrl}tutorials`;
export const settingsResetPassword = `${apiBaseUrl}users/changePassword`;
//settings - delete file
export const deleteTutorial = (fileNameId: number) =>
  `${apiBaseUrl}tutorials/${fileNameId}`;

export const deleteSection = (sectionNameId: number) =>
  `${apiBaseUrl}tutorials/section/${sectionNameId}`;
//settings- upload file
export const uploadTutorial = (sectionName: string) =>
  `${apiBaseUrl}tutorials/section/${sectionName}`;
//to save the order
export const saveOrder = `${apiBaseUrl}tutorials`;

//

//Person Repository - list
export const getPersonRepo = `${apiBaseUrl}person/grid`;
//Person Repository - person details
export const getPersonDetails = (accountId: number, personId: number) =>
  `${apiBaseUrl}accounts/${accountId}/people/${personId}`;
//Person Repository- gender
export const getGender = `${apiBaseUrl}genders`;
//Person Repository - question
export const getQuestionsAndSection = (
  accountId: number | undefined,
  id: string | undefined,
) => `${apiBaseUrl}accounts/${accountId}/people/${id}/getpocDetails`;
//Person Repository- person alerts
export const personAlerts = (
  personId: string,
  accountId: number,
  start: number,
  take: number,
) =>
  `${apiBaseUrl}accounts/${accountId}/people/${personId}/alert/all?start=${start}&take=${take}`;

//About - release notes
export const getReleaseNotes = `${apiBaseUrl}release-note/3`;
//About - feedback
export const getFeedback = `${apiBaseUrl}users/get/feedback`;

//Home - dashBoard alert listing
export const getPortalAlertList = `${apiBaseUrl}portal-alerts/active`;
//Home - get relationship manager
export const getRelManagerInfo = `${apiBaseUrl}relationship-manager/accounts`;
//Home - get sections in dashboard
export const getHomeSections = `${apiBaseUrl}home/sections`;
//Home - get clinical call resolution chart data
export const callDataCount = `${apiBaseUrl}home/sections/calls`;
export const clinicalCallCount = `${apiBaseUrl}home/sections/clinical-outcome`;
export const accountsInfo = `${apiBaseUrl}/accounts/info`;
//Home - get colleague list
export const getColleagues = `${apiBaseUrl}users/my-colleagues`;
//Home - get widgets list
export const getWidgetList = `${apiBaseUrl}users/settings/customer-portal`;
//Feedback  - create/ get
export const createFeedbackForm = `${apiBaseUrl}users/feedback`;
// to get the current utc time
export const utcTime = `${apiBaseUrl}users/get-utc-time`;

//to download report as html
export const reportDownload = (
  callId: string,
  exportFormat: string,
  includeStikyNote: boolean,
) =>
  `${apiBaseUrl}data-transmission/report/as-html?callId=${callId}&exportFormat=${exportFormat}&includeStikyNote=${includeStikyNote}`;
// to fetch related calls
export const relatedCalls = `${apiBaseUrl}calls/relatedcallgrid`;
