import React, {
  ReactElement,
  Fragment,
  useRef,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Disclosure } from '@headlessui/react';
import { FunnelIcon, ArrowPathIcon } from '@heroicons/react/20/solid';

import { Button, EmptyState, Spinner, Skeleton } from 'src/ui/components';
import {
  PersonRepoFilter,
  PersonRepoListItem,
  UserActions,
} from './components';

import { useFetchPersonRepoList } from './api/useFetchPersonRepository';
import { useTitle } from 'src/hooks';

import { pageLength } from 'src/utils/appConstants';
import { usePersonRepositoryFilter } from './utils/usePersonRepositoryFilter';
import { usePersonRepoStore } from './store/personRepoStore';

import { PersonRepoType } from './types/personRepoListTypes';
import { useGetFetchRelatedCalls } from './api/useGetFetchRelatedCalls';

export default function PersonRepository(): ReactElement {
  useTitle('Person Repository');

  const tbodyRef = useRef() as React.MutableRefObject<HTMLTableSectionElement>;

  const [showViewEditModal, setShowViewEditModal] = useState<boolean>(false);

  const setSelectedPerson = usePersonRepoStore(
    useCallback(state => state.setSelectedPerson, []),
  );

  const selectedPerson = usePersonRepoStore(
    useCallback(state => state.selectedPerson, []),
  );

  console.log('selectedPerson', selectedPerson);

  const isUpdateButtonDisabled = usePersonRepoStore(
    useCallback(state => state.isUpdateButtonDisabled, []),
  );

  const { filterOptions, filterCount, setValues, resetFilters } =
    usePersonRepositoryFilter();

  const {
    data,
    isLoading: isListLoading,
    isFetching,
    isRefetching,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useFetchPersonRepoList(filterOptions);

  const isNonBlockingModal = isFetchingNextPage || isRefetching;

  const isSkeletonLoading = isListLoading || isFetching;

  const handleScroll = useCallback(
    (e: any) => {
      if (tbodyRef.current === e.target) {
        const bottom =
          e.target.clientHeight - 10 <
            e.target.scrollHeight - e.target.scrollTop &&
          e.target.scrollHeight - e.target.scrollTop <
            e.target.clientHeight + 10;
        if (bottom) {
          hasNextPage && fetchNextPage();
        }
      }
    },
    [fetchNextPage, hasNextPage],
  );

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, true);
    return () => {
      window.removeEventListener('scroll', handleScroll, true);
    };
  }, [handleScroll]);

  // to prevent persisting selection when filter is applied.
  useEffect(() => {
    setSelectedPerson(null);
  }, [filterOptions, setSelectedPerson]);

  function handleRowClick(item: PersonRepoType) {
    item.Id !== selectedPerson?.Id
      ? setSelectedPerson(item)
      : setSelectedPerson(undefined);
  }

  function resetFunction() {
    resetFilters();
    setSelectedPerson(undefined);
  }

  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (isUpdateButtonDisabled === false && showViewEditModal === true) {
        e.preventDefault();
        const confirmLeave = window.confirm('');
        if (confirmLeave) {
          return e;
        } else {
          e.preventDefault();
        }
      }
    };

    if (isUpdateButtonDisabled === false && showViewEditModal === true) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    }

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isUpdateButtonDisabled, showViewEditModal]);
  function renderCount(): ReactElement {
    return (
      <>
        {data?.pages &&
          data.pages.length > 0 &&
          data?.pages[0]?.data?.length > 0 &&
          data.pages[data.pages.length - 1]?.data.length && (
            <p className="text-xs text-gray-600 dark:text-neutral-400">
              <span>
                Showing 1 to{' '}
                <strong>
                  {' '}
                  {data?.pages[data?.pages?.length - 1]?.data?.length ===
                  pageLength
                    ? data?.pages?.length * pageLength
                    : (data?.pages?.length - 1) * pageLength +
                      data?.pages[data?.pages?.length - 1]?.data.length}{' '}
                </strong>{' '}
                of{' '}
                <strong>
                  {' '}
                  {data?.pages[data?.pages?.length - 1]?.recordsFiltered}
                </strong>{' '}
                entries (filtered from{' '}
                <strong> {data?.pages[0]?.recordsTotal} </strong> total entries)
                {isFetching ? ' | Processing' : ''}
              </span>
            </p>
          )}
      </>
    );
  }

  function disclosureContent(): ReactElement {
    return (
      <Disclosure>
        {({ open }) => (
          <>
            <div className="flex items-center justify-between gap-4 border-t border-gray-200 bg-white px-2 py-1 shadow dark:border-neutral-700/50 dark:bg-neutral-800 sm:px-3 lg:px-4">
              <div className="flex min-w-0 flex-1 items-end gap-4 sm:gap-6">
                <h2 className="truncate text-lg font-semibold text-gray-950 dark:text-white sm:text-xl sm:tracking-tight">
                  Person repository
                </h2>
                <div className="mt-1 flex items-center gap-2 sm:mt-0">
                  <div className="relative inline-flex">
                    <Disclosure.Button
                      as={Button}
                      size="small"
                      variant={open ? 'primary' : 'subtle'}
                    >
                      <FunnelIcon
                        className="-ml-0.5 h-4 w-4"
                        aria-hidden="true"
                      />
                      <span className="hidden sm:inline-block">Filter</span>
                    </Disclosure.Button>

                    <span className="absolute right-0 top-0 -mr-1.5 -mt-1.5 flex h-5 w-5">
                      {filterCount > 0 ? (
                        <span
                          className={`relative inline-flex h-5 w-5 items-center justify-center rounded-full bg-red-600 text-xs font-bold leading-none text-white`}
                        >
                          {filterCount}
                        </span>
                      ) : null}
                    </span>
                  </div>

                  <Button
                    variant="subtle"
                    size="small"
                    onClick={() => {
                      resetFunction();
                    }}
                  >
                    <ArrowPathIcon
                      className="-ml-0.5 h-5 w-5"
                      aria-hidden="true"
                    />
                    <span className="hidden sm:block">Reset</span>
                  </Button>
                </div>
              </div>
            </div>

            <PersonRepoFilter
              setValues={setValues}
              filterOptions={filterOptions}
              resetFilters={resetFilters}
            />
          </>
        )}
      </Disclosure>
    );
  }

  return (
    <>
      <main className="flex flex-1 flex-col overflow-y-auto overscroll-contain">
        {disclosureContent()}
        <div className="flex min-h-[40px] items-center justify-between gap-x-4 gap-y-2 px-2 py-1 sm:px-3 md:flex-wrap lg:px-4">
          <div className="shrink">{renderCount()}</div>
          {selectedPerson?.Id !== undefined &&
            selectedPerson !== undefined &&
            !isSkeletonLoading && (
              <UserActions
                showViewEditModal={showViewEditModal}
                setShowViewEditModal={setShowViewEditModal}
              />
            )}
        </div>
        <div
          className="relative flex-1 space-y-2 overflow-y-scroll px-2 pb-2 sm:px-3 sm:pb-3 lg:px-4 lg:pb-4"
          ref={tbodyRef}
        >
          {!isListLoading && data?.pages ? (
            <>
              {data?.pages.map((page: any, index: number) => (
                <Fragment key={`${page} - ${index + 1}`}>
                  {page?.data && page?.data?.length ? (
                    page?.data.map(
                      (personInfo: PersonRepoType, indx: number) => (
                        <PersonRepoListItem
                          key={`${personInfo.Id}-${indx + 1}`}
                          personInfo={personInfo}
                          handleRowClick={handleRowClick}
                          setShowViewEditModal={setShowViewEditModal}
                        />
                      ),
                    )
                  ) : (
                    <div className="absolute inset-0 grid place-content-center">
                      <EmptyState message="No information available" />
                    </div>
                  )}
                </Fragment>
              ))}
            </>
          ) : (
            isSkeletonLoading && <Skeleton count={8} />
          )}
          {isNonBlockingModal && (
            <div className="fixed inset-0 z-20 grid place-content-center">
              <Spinner size="large" />
            </div>
          )}
        </div>
      </main>
    </>
  );
}
