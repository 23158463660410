import {
  getUserDetails,
  getDefaultEmailNotificationSettings,
  getAccountInfo,
  getLimitedAccessLookup,
  getDataTransmissionAccess,
  getUserAccountInfo,
  getAdminSettings,
  externalUserDtPermissions,
} from 'src/utils/urls';

import { UsersListType } from 'src/features/Users/types/usersListType';
import { ApiParamType } from '../types/userStoreTypes';
import { LimitedAccessLookup } from '../types/limitedAccessRecords';
import { AccountInfo } from 'src/types/AccountInfo';
import { AdminSettingsType } from 'src/features/AddEditUsers/types/adminSettings';
import { useSaveChangesParamStore } from 'src/store/useSaveChangesParamStore';

// fetch user details during edit operation
export async function fetchUserDetails({
  id,
  token,
  tokenType,
}: Partial<{
  id: string;
  token: string;
  tokenType: string;
}>): Promise<UsersListType | undefined> {
  if (id) {
    const response = await fetch(getUserDetails(id), {
      method: 'GET',
      headers: {
        Authorization: `${tokenType} ${token}`,
      },
    });
    return response.json();
  }
}

// default email notification during create a user
export async function fetchDefaultEmailNotificationSettings({
  token,
  tokenType,
}: Partial<ApiParamType>): Promise<number> {
  const response = await fetch(getDefaultEmailNotificationSettings, {
    method: 'GET',
    headers: {
      Authorization: `${tokenType} ${token}`,
    },
  });
  return response.json();
}

//fetch all account details with out pagination when create
export async function fetchAccountInfo({
  token,
  tokenType,
  id,
}: Partial<{
  token: string;
  tokenType: string;
  id: string | undefined;
}>): Promise<Array<AccountInfo>> {
  const response = await fetch(id ? getUserAccountInfo(id) : getAccountInfo, {
    method: 'GET',
    headers: {
      Authorization: `${tokenType} ${token}`,
    },
  });
  return response.json();
}

//fetch all account details with out pagination when create
export async function fetchUserAccountInfo({
  token,
  tokenType,
  id,
}: Partial<{ token: string; tokenType: string; id: string }>): Promise<
  Array<AccountInfo>
> {
  const response = await fetch(getAccountInfo, {
    method: 'GET',
    headers: {
      Authorization: `${tokenType} ${token}`,
    },
  });
  return response.json();
}

//fetch all lookup for the accounts

export async function fetchLimittedAcessLookup({
  id,
  token,
  tokenType,
}: Partial<{
  id: number;
  token: string;
  tokenType: string;
}>): Promise<Array<LimitedAccessLookup> | undefined> {
  if (id) {
    const response = await fetch(getLimitedAccessLookup(id), {
      method: 'GET',
      headers: {
        Authorization: `${tokenType} ${token}`,
      },
    });
    return response.json();
  }
}

//fetch the records of account lookups
export async function fetchLimittedAccessLookupRecords({
  token,
  tokenType,
  accountId,
  recordId,
}: Partial<{
  token: string;
  tokenType: string;
  accountId: number;
  recordId: number;
}>): Promise<LimitedAccessLookup | undefined> {
  if (accountId && recordId) {
    const response = await fetch(
      getDataTransmissionAccess(accountId, recordId),
      {
        method: 'GET',
        headers: {
          Authorization: `${tokenType} ${token}`,
        },
      },
    );
    return response.json();
  }
}

export async function fetchAdminSettings({
  token,
  tokenType,
}: Partial<ApiParamType>): Promise<AdminSettingsType> {
  const response = await fetch(getAdminSettings, {
    method: 'GET',
    headers: {
      Authorization: `${tokenType} ${token}`,
    },
  });
  return response.json();
}

export async function fetchLoggedInExternalUserDtPermissions({
  id,
  token,
  tokenType,
}: Partial<{
  id: string | undefined;
  token: string;
  tokenType: string;
}>): Promise<Array<AccountInfo>> {
  const response = await fetch(externalUserDtPermissions(id), {
    method: 'GET',
    headers: {
      Authorization: `${tokenType} ${token}`,
    },
  });
  return response.json();
}
