// form related to create and edit user goes here
import React, {
  Fragment,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useQueryClient, useQuery } from 'react-query';
import { InformationCircleIcon } from '@heroicons/react/20/solid';

import {
  Button,
  RequiredWarningMessage,
  Spinner,
  CustomCancelModal,
} from 'src/ui/components';
import { AccountSelection, BasicDetails, UserSettings } from './components';

import { useAuth, useTitle } from 'src/hooks';
import { useSaveUserDetails } from './api/useCreateNewUser';
import { useUpdateUser } from './api/useUpdateNewUser';

import { useUserStore } from './store/userStore';
import { formatSaveRequestPayload } from './utils/formatSaveRequestPayload';
import { showErrorToast, showSuccessToast } from 'src/utils/ToastNotification';
import { toCapitalise } from 'src/utils/common';
import { localTzMsg, cancelWarningMsg } from 'src/utils/appConstants';
import {
  editUserSuccessMsg,
  extractMailRegex,
  userTabSections,
  userTabs,
} from './utils/userConstants';

import { useSaveChangesParamStore } from 'src/store/useSaveChangesParamStore';

export default function AddEditUser(): ReactElement {
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient: any = useQueryClient();

  /*user entity id when edit and copy */
  const { id } = useParams();

  const { token, tokenType, isExternalUser, userId } = useAuth();

  const warningMsgRef = useRef<HTMLDivElement | null>(null);

  const [isMessageChanged, setIsMessageChanged] = useState(false);
  const [isShowLoader, setIsShowLoader] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);

  const { mutate: onSaveUserDetails, isLoading: isSavingUserDetLoading } =
    useSaveUserDetails();

  const { mutate: onUpdate, isLoading: isUpdateUserLoading } = useUpdateUser();

  const updateUserMode = useUserStore(
    useCallback(state => state.updateUserMode, []),
  );

  //for setting the user mode
  useEffect(() => {
    if (location?.pathname.split('/').includes('Edit'))
      updateUserMode && updateUserMode('Edit');
    if (location?.pathname.split('/').includes('Copy'))
      updateUserMode && updateUserMode('Copy');
  }, [location?.pathname, updateUserMode]);

  const userMode = useUserStore(useCallback(state => state.userMode, []));

  const basicDetails = useUserStore(
    useCallback(state => state.basicUserDetails, []),
  );

  const accountQuestionSettings = useUserStore(
    useCallback(state => state.accountQuestionSettings, []),
  );

  const getUserDetails = useUserStore(
    React.useCallback(state => state.getUserDetails, []),
  );

  const updateAccountInfo = useUserStore(
    React.useCallback(state => state.updateAccountInfo, []),
  );

  const isResponseLoading = useUserStore(
    React.useCallback(state => state.isResponseLoading, []),
  );

  const selectedPermissions = useUserStore(
    React.useCallback(state => state.userSettingsPermissions, []),
  );

  const recordOptions = useUserStore(
    React.useCallback(state => state.selectedOptions, []),
  );

  const checkDataHasChanged = useUserStore(
    React.useCallback(state => state.checkDataHasChanged, []),
  );

  const selectedEmailFreequency = useUserStore(
    React.useCallback(state => state.defaultEmailNotification, []),
  );

  const customEmailNotificationSettings = useUserStore(
    React.useCallback(state => state.customEmailNotificationSettings, []),
  );

  const includeEmptyNotification = useUserStore(
    React.useCallback(state => state.includeEmptyNotification, []),
  );

  const defaultNotificationId = useUserStore(
    React.useCallback(state => state.defaultNotificationId, []),
  );

  const enableBtn = useUserStore(
    React.useCallback(state => state.enableSettings, []),
  );

  const setSaveClicked = useUserStore(
    React.useCallback(state => state.setSaveClicked, []),
  );

  const isSaveClicked = useUserStore(
    React.useCallback(state => state.isSaveClicked, []),
  );

  const isAllEmailNotificationLoading = useUserStore(
    React.useCallback(state => state.isAllEmailNotificationLoading, []),
  );

  const setShowValidationErr = useUserStore(
    React.useCallback(state => state.setShowValidationErr, []),
  );

  const setPrevInvalidMailId = useUserStore(
    React.useCallback(state => state.setPrevInvalidMailId, []),
  );

  const deleteUserDetails = useUserStore(
    React.useCallback(state => state.deleteUserDetails, []),
  );

  const isLoadingExternalUserPermission = useUserStore(
    React.useCallback(state => state.isLoadingExternalUserPermission, []),
  );

  const isBlockerLoading =
    isAllEmailNotificationLoading ||
    isResponseLoading ||
    isSavingUserDetLoading ||
    isShowLoader ||
    isLoadingExternalUserPermission ||
    isUpdateUserLoading;

  const getUserMode = useMemo(() => {
    if (userMode === '') return 'Add user';
    if (userMode === 'Edit') return 'Edit user';
    if (userMode === 'Copy') return 'Copy user';
    return '';
  }, [userMode]);

  useTitle(toCapitalise(getUserMode));

  function handleCancel() {
    deleteUserDetails();
    setSaveClicked(false);
    queryClient.invalidateQueries(['create-edit-user'], {
      refetchActive: false,
    });
    navigate('/User');
    updateAccountInfo([]);
  }

  function onCancel() {
    if (checkDataHasChanged() === false) {
      setShowCancelModal(true);
    } else {
      handleCancel();
    }
  }
  const isDirty = useSaveChangesParamStore(
    useCallback(state => state.isDirty, []),
  );

  //fetch api for users
  useQuery(
    ['create-edit-user', id],
    () => {
      getUserDetails({
        id,
        token,
        tokenType,
        isExternalUser,
        userId,
      });
    },
    { staleTime: Infinity },
  );

  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (isDirty) {
        e.preventDefault();
        const confirmLeave = window.confirm(cancelWarningMsg);
        if (confirmLeave) {
          return e;
        } else {
          e.preventDefault();
        }
      }
    };

    if (isDirty) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    }

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isDirty]);
  // Create a new MutationObserver instance to show animation in warning message
  useEffect(() => {
    const targetNode = warningMsgRef.current;
    if (!targetNode) return;

    const observer = new MutationObserver(() => {
      setIsMessageChanged(true);
      setTimeout(() => {
        setIsMessageChanged(false);
      }, 1000);
    });
    const config = { childList: true, subtree: true }; // Configure the MutationObserver
    observer.observe(targetNode, config); // Start observing the target node

    return () => {
      observer.disconnect();
    };
  }, []);

  function saveUserErrorHandler(ErrorMessage: any) {
    if (ErrorMessage instanceof Array) {
      const errMsg = ErrorMessage[0];
      if (errMsg.startsWith('Name') && errMsg.endsWith('is already taken.')) {
        setShowValidationErr(true);
        const elem = document.getElementById('basic-det');
        elem?.scrollIntoView();
        const email = errMsg.match(extractMailRegex)[0];
        if (email) {
          setPrevInvalidMailId(email);
        }
      } else {
        showErrorToast({
          message: ErrorMessage[0],
        });
      }
    } else {
      showErrorToast({
        message: ErrorMessage.Message,
      });
    }
  }

  function emailErrorHandler(message: string) {
    if (
      message.endsWith('UserName already exists.') ||
      (message.startsWith('Name') && message.endsWith('is already taken.'))
    ) {
      setShowValidationErr(true);
      setPrevInvalidMailId(basicDetails?.email);
      const elem = document.getElementById('basic-det');
      elem?.scrollIntoView();
    } else {
      showErrorToast({
        message: message,
      });
    }
  }

  function saveHandler() {
    setSaveClicked(true);
    if (
      !enableBtn('basic-tab') ||
      !enableBtn('permission-tab') ||
      !enableBtn('account-tab')
    ) {
      return;
    } else {
      setIsShowLoader(true);
      setSaveClicked(false);
      const userInformation: any = formatSaveRequestPayload(
        basicDetails,
        accountQuestionSettings,
        selectedPermissions,
        selectedEmailFreequency,
        includeEmptyNotification,
        customEmailNotificationSettings,
        recordOptions,
        defaultNotificationId,
      );

      if (userMode === 'Edit') {
        onUpdate(userInformation, {
          onSuccess: () => {
            if (basicDetails.status === 'Invitation sent') {
              showSuccessToast({
                message: editUserSuccessMsg,
              });
            } else {
              showSuccessToast({
                message: 'User updated successfully.',
              });
              deleteUserDetails();
            }
            setIsShowLoader(false);
            queryClient.invalidateQueries(['create-edit-user'], {
              refetchActive: false,
            });
            navigate('/User');
          },
          onError: (error: any) => {
            setIsShowLoader(false);
            if (error instanceof Array) {
              const Message = error[0];
              emailErrorHandler(Message);
            } else {
              emailErrorHandler(error.Message);
            }
          },
        });
      } else {
        onSaveUserDetails(userInformation, {
          onSuccess: () => {
            showSuccessToast({
              message: 'User created successfully.',
            });
            deleteUserDetails();
            queryClient.invalidateQueries(['create-edit-user'], {
              refetchActive: false,
            });
            setIsShowLoader(false);
            navigate('/User');
          },
          onError: ({ ErrorMessage }: { ErrorMessage: any }) => {
            setIsShowLoader(false);
            saveUserErrorHandler(ErrorMessage);
          },
        });
      }
    }
  }

  function renderPageHeader(): ReactElement {
    return (
      <div className="flex flex-wrap items-center justify-between gap-2 border-t border-gray-200 bg-white px-2 py-1 shadow dark:border-neutral-700/50 dark:bg-neutral-800 sm:gap-4 sm:px-3 lg:px-4">
        <div className="flex flex-1 shrink-0 items-end gap-4 sm:gap-8">
          <h2 className="truncate text-lg font-semibold text-gray-950 dark:text-white sm:text-xl sm:tracking-tight">
            {getUserMode === 'Add user' ? 'Create user' : getUserMode}
          </h2>
        </div>
        <p className="inline-flex items-center gap-1 text-xs text-sky-600 dark:text-sky-300">
          <InformationCircleIcon
            className="-ml-0.5 h-5 w-5 shrink-0"
            aria-hidden="true"
          />
          {localTzMsg}
        </p>
      </div>
    );
  }

  return (
    <main className="flex flex-1 flex-col">
      {renderPageHeader()}
      <div className="relative h-px grow">
        <div className="h-full max-h-full overflow-y-scroll ">
          <div className="container mx-auto flex h-full flex-col space-y-2 p-3 sm:space-y-4 lg:p-4">
            {!isBlockerLoading && (
              <Fragment>
                <BasicDetails />
                <AccountSelection />
                <UserSettings />
              </Fragment>
            )}
          </div>
        </div>
        {isBlockerLoading && (
          <div className="bg-gray-900/8 absolute inset-0 grid place-content-center backdrop-blur-sm">
            <Spinner />
          </div>
        )}
      </div>

      <div
        className={`border-t border-light-light  bg-white dark:border-dark-medium dark:bg-neutral-800 ${
          isResponseLoading || isSavingUserDetLoading || isUpdateUserLoading
            ? 'blur-sm'
            : ''
        }`}
      >
        <div
          ref={warningMsgRef}
          className="container mx-auto flex flex-col flex-wrap gap-4 p-2 sm:justify-end md:flex-row"
        >
          {isSaveClicked &&
            (!enableBtn('account-tab') ||
              !enableBtn('basic-tab') ||
              !enableBtn('permission-tab')) && (
              <div
                className={`flex items-center gap-2 ${
                  isMessageChanged ? 'animate-shake' : ''
                }`}
              >
                <span className="relative flex h-3 w-3 shrink-0 items-center justify-center">
                  <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-red-400 opacity-75" />
                  <span className="relative inline-flex h-2 w-2 rounded-full bg-red-500" />
                </span>
                <span className="text-sm font-medium text-red-700 dark:text-red-400">
                  <RequiredWarningMessage
                    tabs={userTabs}
                    tabContents={userTabSections}
                    enableTab={enableBtn}
                  />
                </span>
              </div>
            )}
          <div className="flex gap-[1ch]">
            <Button
              className="grow md:grow-0"
              onClick={() => onCancel()}
              disabled={isBlockerLoading}
            >
              Cancel
            </Button>
            <Button
              className="grow md:grow-0"
              variant="primary"
              disabled={
                isBlockerLoading || checkDataHasChanged() ? true : false
              }
              onClick={() => saveHandler()}
            >
              Save
            </Button>
            {showCancelModal === true && !checkDataHasChanged() && (
              <CustomCancelModal
                showCancelModal={showCancelModal}
                setShowCancelModal={setShowCancelModal}
                handleCancel={handleCancel}
              />
            )}
          </div>
        </div>
      </div>
    </main>
  );
}
