import React, { ReactElement, SyntheticEvent } from 'react';
import {
  ArrowSmallUpIcon,
  ArrowSmallDownIcon,
  XMarkIcon,
} from '@heroicons/react/20/solid';
import { Disclosure } from '@headlessui/react';

import {
  Button,
  Select,
  TextField,
  DateRangePicker,
  Checkbox,
  MultiSelectCheckBox,
} from 'src/ui/components';

import { useGetExtraCoverageRequestStatuses } from '../api';

import { formatStatus } from '../utils/ExtraCoverageFilterHelper';
import {
  sortOptions,
  sortOptionsForNonProtocallUser,
} from '../utils/ExtraCoverageConstants';

import { ExtraCoverageFilterType } from '../types/extraCoverageRequestFilterType';
import { MultiSelectInputType } from 'src/types/MultiSelectInputType';

type FilterInputType = {
  filterOptions?: ExtraCoverageFilterType;
  setValues: (
    type: keyof ExtraCoverageFilterType,
    value: string | boolean,
  ) => void;
  setStatus: (value: Array<MultiSelectInputType>) => void;
  permission: any;
  resetFilters: () => void;
};

function ExtraCovergeFilter({
  filterOptions,
  setValues,
  setStatus,
  permission,
  resetFilters,
}: FilterInputType): ReactElement {
  const isProtocallUser = permission?.AllProtocallUsers;
  const { data: extraCoverageStatuses } = useGetExtraCoverageRequestStatuses();

  //render text field
  function renderTextField({
    label,
    id,
    value,
    placeholder,
  }: {
    label: string;
    id: keyof ExtraCoverageFilterType;
    value: any;
    placeholder: string;
  }) {
    return (
      <div className="flex w-full flex-col gap-1 sm:w-auto">
        <TextField
          inputSize="small"
          type="search"
          className="w-full sm:w-auto"
          label={label}
          id={id}
          value={value}
          onChange={e => setValues(id, e.target.value)}
          placeholder={placeholder}
        />
      </div>
    );
  }

  //render sort  and search
  function renderSort(): ReactElement {
    return (
      <div className="flex items-end gap-2">
        <Select
          inputSize="small"
          label="Sort by"
          id="filter-status"
          className="w-full sm:w-auto"
          options={
            isProtocallUser ? sortOptions : sortOptionsForNonProtocallUser
          }
          value={filterOptions?.sortType}
          onChange={(e: SyntheticEvent<HTMLSelectElement>) =>
            setValues('sortType', e.currentTarget.value)
          }
        />
        <Button
          variant={filterOptions?.sortOrder === 'asc' ? 'primary' : 'secondary'}
          size="small"
          onClick={() => setValues('sortOrder', 'asc')}
        >
          <ArrowSmallUpIcon className="h-5 w-5" aria-hidden="true" />
          <span className="sr-only">Sort in ascending order</span>
        </Button>
        <Button
          variant={
            filterOptions?.sortOrder === 'desc' ? 'primary' : 'secondary'
          }
          size="small"
          onClick={() => setValues('sortOrder', 'desc')}
        >
          <ArrowSmallDownIcon className="h-5 w-5" aria-hidden="true" />
          <span className="sr-only">Sort in descending order</span>
        </Button>
      </div>
    );
  }

  //render date picker
  function renderDatePicker({
    id,
    key,
    value,
    placeholder,
  }: {
    id: string;
    title: string;
    key?: string;
    value?: string;
    placeholder?: string;
  }): ReactElement {
    return (
      <DateRangePicker
        rangeType="future"
        size="small"
        format="MM/DD/YYYY HH:mm:ss"
        id={id}
        value={value?.length ? value : []}
        setValues={setValues}
        keyValue={key}
        placeholder={placeholder}
      />
    );
  }

  //render checkbox
  function renderCheckBox({
    id,
    key,
    label,
    checked,
  }: {
    id: string;
    title: string;
    key: any;
    label?: string;
    checked?: boolean;
  }): ReactElement {
    return (
      <Checkbox
        id={id}
        checked={checked}
        label={label}
        onChange={e => {
          setValues(key, e.currentTarget.checked);
        }}
      />
    );
  }

  //render select field
  function renderSelectField(): ReactElement {
    return (
      <div>
        <label
          htmlFor=""
          className="block text-xs font-medium leading-6 text-gray-900 dark:text-white"
        >
          Status
        </label>
        <MultiSelectCheckBox
          size="small"
          id="status"
          label="Status"
          options={
            extraCoverageStatuses
              ? formatStatus(extraCoverageStatuses, true)
              : []
          }
          setSelectedOptions={setStatus}
          selectedOptions={filterOptions?.extraCoverageRequestStatusId}
        />
      </div>
    );
  }
  return (
    <Disclosure.Panel
      as="div"
      className="fixed bottom-0 left-0 top-0 z-10 flex w-full flex-col bg-white shadow dark:bg-neutral-800 sm:static sm:bottom-auto sm:left-auto sm:top-auto sm:block sm:w-auto"
    >
      {({ close }) => (
        <>
          <div className="flex items-center border-b border-b-light-light py-1 pe-1 ps-2 dark:border-b-dark-medium sm:hidden">
            <h3 className="grow text-lg font-medium">Filter</h3>
            <Button
              variant="subtle"
              onClick={() => {
                close();
              }}
            >
              <XMarkIcon className="h-5 w-5" />
            </Button>
          </div>
          <div className="min-h-0 grow overflow-y-auto p-2 pb-3 sm:overflow-y-visible sm:px-3 lg:px-4">
            {renderSort()}
            <div
              className="my-4 border-b border-gray-200 dark:border-neutral-700/50"
              aria-hidden="true"
            ></div>
            <div className="flex flex-wrap items-end gap-2">
              {renderTextField({
                label: 'Extra coverage ID',
                id: 'id',
                placeholder: 'Extra coverage ID',
                value: filterOptions?.id,
              })}
              {permission?.AllProtocallUsers &&
                renderTextField({
                  label: 'Approver',
                  id: 'approver',
                  placeholder: 'Approver',
                  value: filterOptions?.approver,
                })}
              {renderSelectField()}
              {renderTextField({
                label: 'Accounts',
                id: 'accounts',
                placeholder: 'Account',
                value: filterOptions?.accounts,
              })}
              <div className="flex w-full flex-col sm:w-auto">
                <label
                  htmlFor=""
                  className="block text-xs font-medium leading-6 text-gray-900 dark:text-white"
                >
                  Coverage date
                </label>
                {renderDatePicker({
                  id: 'ExtraCoverageDate',
                  title: 'Coverage Date',
                  key: 'extraCoverageDate',
                  placeholder: 'Coverage date',
                  value: filterOptions?.extraCoverageDate,
                })}
              </div>
              <div className="flex w-full flex-col sm:w-auto">
                <label
                  htmlFor=""
                  className="block text-xs font-medium leading-6 text-gray-900 dark:text-white"
                >
                  Submitted date
                </label>
                {renderDatePicker({
                  id: 'SubmittedDate',
                  title: 'Submitted Date',
                  key: 'submittedDate',
                  placeholder: 'Submitted date',
                  value: filterOptions?.submittedDate,
                })}
              </div>
              {permission?.AllProtocallUsers && (
                <>
                  <div className="flex w-full flex-col gap-1 sm:w-auto">
                    {renderCheckBox({
                      id: 'EmailSent',
                      title: 'Email sent',
                      label: 'Email sent',
                      key: 'emailSent',
                      checked: filterOptions?.emailSent,
                    })}
                    {renderCheckBox({
                      id: 'NotEmailSent',
                      title: 'Not email sent',
                      label: 'Not email sent',
                      key: 'notEmailSent',
                      checked: filterOptions?.notEmailSent,
                    })}
                  </div>
                  <div className="flex w-full flex-col gap-1 sm:w-auto">
                    {renderCheckBox({
                      id: 'Accountalertposted',
                      title: 'Account alert posted',
                      label: 'Account alert posted',
                      key: 'accountAlertPosted',
                      checked: filterOptions?.accountAlertPosted,
                    })}
                    {renderCheckBox({
                      id: 'NotAccountalertposted',
                      title: 'Not account alert posted',
                      label: 'Not account alert posted',
                      key: 'notAccountAlertPosted',
                      checked: filterOptions?.notAccountAlertPosted,
                    })}
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="flex  flex-col gap-1 border-t border-t-light-light p-2 dark:border-b-dark-medium sm:hidden">
            <Button
              className="grow"
              variant="link"
              onClick={() => {
                resetFilters();
              }}
            >
              Reset
            </Button>
            <div className="flex gap-1">
              <Button
                className="grow"
                onClick={() => {
                  close();
                }}
              >
                Cancel
              </Button>
              <Button
                className="grow"
                variant="primary"
                onClick={() => {
                  close();
                }}
              >
                Apply
              </Button>
            </div>
          </div>
        </>
      )}
    </Disclosure.Panel>
  );
}
export { ExtraCovergeFilter };
