import dayjs from 'dayjs';

function isNullCheck(val: any) {
  if (val === null || val === undefined || val === '') return '';
  else return val;
}

function updatedStoreValue(store: any) {
  const value = {
    AccountsInfo: store.accountsInfo
      ?.map((item: any) => item)
      .sort((a: any, b: any) => a - b),

    SubmittedDate: isNullCheck(store?.extraCoverageRequestDet?.submittedDate),
    ReturnEmailAddress: isNullCheck(
      store?.extraCoverageRequestDet?.returnEmailAddress,
    ),
    AdditionalReturnEmailAddress: isNullCheck(
      store?.extraCoverageRequestDet?.AdditionalReturnEmailAddress,
    ),
    TimeZoneId:
      store?.extraCoverageRequestDet?.TimeZoneId === undefined ||
      store?.extraCoverageRequestDet?.TimeZoneId === '-1'
        ? -1
        : parseInt(store?.extraCoverageRequestDet?.TimeZoneId),
    ExtraCoverageDate: dayjs(
      isNullCheck(store?.extraCoverageRequestDet?.ExtraCoverageDate),
    ).format('MM/DD/YYYY'),
    StartTime: isNullCheck(store?.extraCoverageRequestDet?.StartTime).substring(
      0,
      5,
    ),
    EndTime: isNullCheck(store?.extraCoverageRequestDet?.EndTime).substring(
      0,
      5,
    ),
    ExtraCoverageRequestStatusId: parseInt(
      isNullCheck(store?.extraCoverageRequestDet?.ExtraCoverageRequestStatusId),
    ),
    ExtraCoverageRequestTypeId:
      store?.extraCoverageRequestDet?.ExtraCoverageRequestTypeId !== null &&
      store?.extraCoverageRequestDet?.ExtraCoverageRequestTypeId !==
        undefined &&
      store?.extraCoverageRequestDet?.ExtraCoverageRequestTypeId !== '-1'
        ? parseInt(store?.extraCoverageRequestDet?.ExtraCoverageRequestTypeId)
        : '',

    ReasonForCoverage: isNullCheck(
      store?.extraCoverageRequestDet?.ReasonForCoverage,
    ),
    SpecialProcedures: isNullCheck(
      store?.extraCoverageRequestDet?.SpecialProcedures,
    ),
    SpecialProcedureMemo: isNullCheck(
      store?.extraCoverageRequestDet?.SpecialProcedureMemo,
    ),
    SendEmailOnSave: isNullCheck(store?.sendEmailOnSave),
    PostAlertOnSave: isNullCheck(store?.postAlertOnSave),
    AdditionalComments: isNullCheck(
      store?.extraCoverageRequestDet?.AdditionalComments,
    ),
  };
  return value;
}
export { updatedStoreValue };
