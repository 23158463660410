import React, { ReactElement, useCallback, useEffect } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { PlusIcon, XMarkIcon } from '@heroicons/react/20/solid';
import dayjs from 'dayjs';

import {
  Button,
  Select,
  inputBaseClasses,
  inputSizeClasses,
  Checkbox,
  Spinner,
} from 'src/ui/components';

import { useAuth, useUpdateUserEmailNotificationSettings } from 'src/hooks';

import { userOnBoardingStore } from 'src/store/userOnboardingStore';
import {
  timerPickerDefaultState,
  dailyAtSettingsId,
  noDocumentSettingsIds,
  maximumPickerLength,
  epocValueConversion,
} from 'src/utils/customEmailNotification';
import { showSuccessToast } from 'src/utils/ToastNotification';

import { CustomEmailNotificationSettingsType } from 'src/types/emailNotification';

export function Notifications(): ReactElement {
  const { userId, token, tokenType } = useAuth();

  const queryClient = useQueryClient();

  const { mutate: emailUpdate, isLoading: isEmailUpdating } =
    useUpdateUserEmailNotificationSettings();

  useQuery(
    ['settings-notification'],
    () => {
      getNotificationDetails({
        id: userId,
        token,
        tokenType,
      });
    },
    { staleTime: Infinity },
  );

  useEffect(() => {
    return () => {
      queryClient.invalidateQueries(['settings-notification'], {
        refetchActive: false,
      });
    };
  }, [queryClient]);

  const emailOptionList = userOnBoardingStore(
    useCallback(state => state.emailOptionList, []),
  );

  const customEmailNotificationSettings = userOnBoardingStore(
    useCallback(state => state.customEmailNotificationSettings, []),
  );

  const customEmailNotification = userOnBoardingStore(
    useCallback(state => state.customEmailNotification, []),
  );

  const getNotificationDetails = userOnBoardingStore(
    useCallback(state => state.getNotificationDetails, []),
  );

  const updateCustomEmailNotificationSettings = userOnBoardingStore(
    useCallback(state => state.updateCustomEmailNotificationSettings, []),
  );

  const getNotificationDetailsLoading = userOnBoardingStore(
    useCallback(state => state.getNotificationDetailsLoading, []),
  );

  const selectedFrequency = userOnBoardingStore(
    useCallback(state => state.selectedFrequency, []),
  );

  const setUpdateStore = userOnBoardingStore(
    useCallback(state => state.setUpdateStore, []),
  );

  const includeEmptyNotification = userOnBoardingStore(
    useCallback(state => state.includeEmptyNotification, []),
  );

  const isSpinnerShowing = getNotificationDetailsLoading || isEmailUpdating;

  function onRemoveTimePicker(index: number) {
    if (customEmailNotificationSettings) {
      let updatedTimeValues = customEmailNotificationSettings.filter(
        (item, i) => i !== index,
      );
      updateCustomEmailNotificationSettings([...updatedTimeValues]);
    }
  }

  function timeInputHandler(e: any, index: number | number) {
    if (
      customEmailNotificationSettings !== undefined &&
      customEmailNotificationSettings?.length !== 0
    ) {
      let existingObjects = [...customEmailNotificationSettings];
      const itemExists = existingObjects?.findIndex((item, i) => i === index);

      if (itemExists !== -1) {
        existingObjects[itemExists] = {
          NotifyTime: e.target.value,
          NotifyDateTime: dayjs(new Date(0)).utc().format(),
          EpochTimeValue: 0,
          Id: existingObjects[itemExists]?.Id,
          UserEmailSetting: null,
        };
      }

      updateCustomEmailNotificationSettings([...existingObjects]);
    }
  }

  function addTimePicker() {
    if (
      customEmailNotificationSettings &&
      customEmailNotificationSettings.length !== 4
    ) {
      updateCustomEmailNotificationSettings([
        ...customEmailNotificationSettings,
        { ...timerPickerDefaultState },
      ]);
    }
  }

  function onSaveHandler() {
    const updatedCustomSettings =
      selectedFrequency?.toString() === dailyAtSettingsId?.toString()
        ? customEmailNotificationSettings?.map(item => ({
            ...item,
            EpochTimeValue: epocValueConversion(item?.NotifyTime),
          }))
        : [];

    const params = {
      id: userId,
      payload: {
        ...customEmailNotification,
        CustomEmailNotificationSettings: updatedCustomSettings,
        EmailNotificationSettingsId: selectedFrequency,
        IncludeEmptyNotification:
          selectedFrequency !== null &&
          noDocumentSettingsIds.includes(selectedFrequency)
            ? false
            : includeEmptyNotification,
      },
    };
    emailUpdate(params, {
      onSuccess: () => {
        showSuccessToast({ message: 'User updated successfully.' });
      },
    });
  }

  function renderTimepicker(
    item: CustomEmailNotificationSettingsType,
    index: number,
  ): ReactElement {
    return (
      <div className="relative" key={`${index}${item?.NotifyDateTime}`}>
        <input
          id={`time${item?.Id}-${item?.EpochTimeValue}`}
          value={item?.NotifyTime}
          className={`${inputBaseClasses} ${inputSizeClasses.medium} mt-0.5 ${
            customEmailNotificationSettings?.length > 1 ? 'pr-10' : ''
          } outline-0`}
          type="time"
          onChange={(e: any) => timeInputHandler(e, index)}
        />

        {customEmailNotificationSettings?.length > 1 ? (
          <div className="absolute inset-y-0 bottom-[0.25rem] right-1 flex items-end">
            <Button
              size="small"
              variant="subtle"
              onClick={e => onRemoveTimePicker(index)}
            >
              <XMarkIcon className="h-5 w-5" aria-hidden="true" />
              <span className="sr-only">Clear</span>
            </Button>
          </div>
        ) : null}
      </div>
    );
  }

  function renderCheckBox(): ReactElement {
    return (
      <Checkbox
        label="Get an email when there are no new documents"
        id="email-no-documents"
        checked={includeEmptyNotification}
        onChange={e => {
          setUpdateStore('includeEmptyNotification', e.currentTarget.checked);
        }}
      />
    );
  }

  function renderEmail(): ReactElement {
    return (
      <div>
        <label
          htmlFor="filter-status"
          className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
        >
          Email frequency
        </label>
        <Select
          id="filter-status"
          options={emailOptionList}
          onChange={e => {
            setUpdateStore('selectedFrequency', e.target.value);
          }}
          value={selectedFrequency}
        />
      </div>
    );
  }

  return (
    <div className="divide-y divide-light-light rounded-lg bg-white shadow dark:divide-dark-medium dark:bg-dark-dark">
      <h3 className="px-3 py-2 text-xl font-medium md:px-4 md:py-4">
        Notifications
      </h3>

      <div className="flex flex-col gap-6 px-3 py-2 md:px-4 md:py-3">
        {renderEmail()}
        {selectedFrequency?.toString() === dailyAtSettingsId?.toString() && (
          <div className="flex flex-wrap items-end gap-2">
            {customEmailNotificationSettings?.map(
              (item: CustomEmailNotificationSettingsType, index: number) => {
                return renderTimepicker(item, index);
              },
            )}

            <Button
              disabled={
                customEmailNotificationSettings?.length === maximumPickerLength
              }
              onClick={() => addTimePicker()}
            >
              <PlusIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
              Add
            </Button>
          </div>
        )}
        {selectedFrequency !== null &&
          !noDocumentSettingsIds?.includes(selectedFrequency) &&
          renderCheckBox()}
      </div>
      {isSpinnerShowing && (
        <div className="bg-gray-900/8  absolute inset-0 z-50 grid place-items-center backdrop-blur-sm">
          <Spinner size="large" />
        </div>
      )}

      <div className="flex gap-[1ch] rounded-b-lg bg-gray-50 p-4 dark:bg-neutral-700/50 sm:justify-end sm:p-3">
        <Button
          variant="primary"
          onClick={() => onSaveHandler()}
          className="grow sm:grow-0"
        >
          Save
        </Button>
      </div>
    </div>
  );
}
