import React, { ReactElement, useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { MinusCircleIcon, CheckCircleIcon } from '@heroicons/react/20/solid';

import { TooltipItem, Badge } from 'src/ui/components';

import { useUserStore } from 'src/features/AddEditUsers/store/userStore';
import { activeStyles } from '../utils/userConstants';

import { PermissionGroupCodeType } from 'src/types/permissionGroupCode';
import { UsersListType } from '../types/usersListType';
import { useSaveChangesParamStore } from 'src/store/useSaveChangesParamStore';

export type UserListItemType = {
  userInfo: UsersListType;
  permissions: Array<PermissionGroupCodeType> | undefined;
  selectedUser: UsersListType | undefined;
  handleRowClick: (item: UsersListType) => void;
  containerRef: any;
};

function UserListItem({
  userInfo,
  permissions,
  selectedUser,
  handleRowClick,
  containerRef,
}: UserListItemType): ReactElement {
  const navigate = useNavigate();

  const queryClient: any = useQueryClient();

  const deleteUserDetails = useUserStore(
    useCallback(state => state.deleteUserDetails, []),
  );

  const setSaveClicked = useUserStore(
    React.useCallback(state => state.setSaveClicked, []),
  );

  function getUserEmailFreequency(userData: UsersListType): string {
    const dtPermissionId = permissions?.find(
      x => x?.ClaimType === 'DocumentTransmissionPermission',
    )?.Id;

    if (
      dtPermissionId &&
      userData?.PermissionIds?.includes(dtPermissionId) &&
      userData?.UserEmailNotification &&
      userData?.UserEmailNotification?.EmailNotificationSetting?.NotifiedAt
    ) {
      return userData?.UserEmailNotification?.EmailNotificationSetting
        ?.NotifiedAt;
    }
    return 'N/A';
  }

  function isPermissionEnabled(
    userInfoPermissionIds: Array<number>,
    id?: number,
  ): number | undefined {
    const permissionIds = userInfoPermissionIds?.find(x => x === id);
    return permissionIds;
  }

  function renderPermissions(
    userInfoPermissionIds: Array<number>,
  ): ReactElement {
    return (
      <>
        <div className="mt-0.5 flex flex-wrap gap-x-4 gap-y-1">
          {permissions &&
            permissions?.map(permission => (
              <span
                className=" inline-flex items-center gap-1 text-xs font-bold tracking-wide text-black dark:text-white"
                key={permission?.Id}
              >
                {!isPermissionEnabled(userInfoPermissionIds, permission?.Id) ? (
                  <MinusCircleIcon
                    className={'h-4 w-4 text-gray-400 dark:text-neutral-400'}
                    aria-hidden="true"
                  />
                ) : (
                  <CheckCircleIcon
                    className={'h-4 w-4 text-green-600 dark:text-green-400'}
                    aria-hidden="true"
                  />
                )}
                {permission?.Description}
              </span>
            ))}
        </div>
      </>
    );
  }
  return (
    <>
      <div
        tabIndex={0}
        className={`relative flex flex-wrap gap-5 rounded bg-white p-2 shadow focus-visible:outline  focus-visible:outline-[3px] focus-visible:outline-offset-[3px] focus-visible:outline-blue-600 dark:bg-neutral-800 dark:focus-visible:outline-blue-400 lg:gap-x-5 ${
          selectedUser?.Id === userInfo?.Id ? activeStyles : ''
        }`}
        onClick={() => {
          deleteUserDetails();
          handleRowClick(userInfo);
        }}
        onDoubleClick={() => {
          useSaveChangesParamStore.getState().setIsDirty(false);
          setSaveClicked(false);
          deleteUserDetails();
          queryClient.invalidateQueries(['create-edit-user'], {
            refetchActive: false,
          });
          navigate(`/User/Edit/${userInfo?.Id}`);
        }}
      >
        <div className="flex basis-[40%] flex-col gap-5 md:basis-[30%] lg:basis-1/5">
          <div>
            <label className="block whitespace-nowrap text-[10px] font-medium uppercase tracking-wide opacity-70">
              Username
            </label>
            <p
              className="prevent-text-breakout break-words text-xs font-bold tracking-wide text-black dark:text-white"
              title={userInfo.UserName}
            >
              {userInfo.UserName}
            </p>
          </div>
          <div>
            <label className="block whitespace-nowrap text-[10px] font-medium uppercase tracking-wide opacity-70">
              Status
            </label>
            <Badge
              variant={userInfo?.Status === 'Active' ? 'success' : 'danger'}
            >
              {userInfo.Status}
            </Badge>
          </div>
        </div>
        <div className="flex basis-[40%] flex-col gap-5 md:basis-[30%] lg:basis-[12%]">
          <div>
            <label className="block whitespace-nowrap text-[10px] font-medium uppercase tracking-wide opacity-70">
              First name
            </label>
            <p className="prevent-text-breakout break-words text-xs font-bold tracking-wide text-black dark:text-white">
              {userInfo.FirstName}
            </p>
          </div>
          <div>
            <label className="block whitespace-nowrap text-[10px] font-medium uppercase tracking-wide opacity-70">
              Last name
            </label>
            <p className="prevent-text-breakout break-words text-xs font-bold tracking-wide text-black dark:text-white">
              {userInfo.LastName}
            </p>
          </div>
        </div>
        <div className="flex basis-[40%] flex-col gap-5 md:basis-[30%] lg:basis-[7%]">
          <div>
            <TooltipItem
              label="CID"
              values={userInfo?.AccountCIDs?.join(', ')}
              containerRef={containerRef}
            />
          </div>
          <div>
            <TooltipItem
              label="PID"
              values={userInfo?.AccountPIDs?.join(', ')}
              containerRef={containerRef}
            />
          </div>
        </div>
        <div className=" basis-[40%] md:basis-[30%] lg:basis-[42%]">
          <label className="block whitespace-nowrap text-[10px] font-medium uppercase tracking-wide opacity-70">
            Permissions
          </label>
          {renderPermissions(userInfo.PermissionIds)}
        </div>
        <div className=" basis-[40%] md:basis-[30%] lg:basis-[10%]">
          <label className="block whitespace-nowrap text-[10px] font-medium uppercase tracking-wide opacity-70">
            Email frequency
          </label>
          <p className=" text-xs font-bold tracking-wide text-black dark:text-white">
            {getUserEmailFreequency(userInfo)}
          </p>
        </div>
      </div>
    </>
  );
}

export { UserListItem };
