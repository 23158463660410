import React, { ReactElement, Fragment, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/20/solid';
import * as Tooltip from '@radix-ui/react-tooltip';

import { Button, Textarea } from 'src/ui/components';

import { feedbackOptions } from '../utils/feedbackFormContants';
import { useCreateFeedback } from '../api/useCreateFeedback';
import { classNames } from 'src/utils/className';

export default function FeedbackForm({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
}): ReactElement {
  let cancelButtonRef = useRef(null);
  const location = useLocation();

  const [textContent, setTextContent] = useState<string>('');
  const [optionValue, setOptionValue] = useState<string>('');

  const { mutate: onPostFeedBack, isLoading } = useCreateFeedback();

  function onButtonClickHandler() {
    onPostFeedBack(
      { feedback: optionValue, comments: textContent.trim() },
      {
        onSuccess: () => {
          setIsOpen(false);
          setOptionValue('');
          setTextContent('');
          window.open(
            `${process.env.REACT_APP_OLD_CP_URL}${location.pathname}`,
            '_self',
          );
        },
        onError: () => {},
      },
    );
  }

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => setIsOpen(false)}
          initialFocus={cancelButtonRef}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="pr-dialog__backdrop" />
          </Transition.Child>

          <div className="pr-dialog__wrapper">
            <div className="pr-dialog__panel__container">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="pr-dialog__panel sm:!max-w-xl">
                  <Button
                    variant="subtle"
                    className="absolute right-2 top-2"
                    onClick={() => {
                      setIsOpen(false);
                      setOptionValue('');
                      setTextContent('');
                    }}
                    ref={cancelButtonRef}
                  >
                    <XMarkIcon className="h-5 w-5" />
                  </Button>
                  <div className="space-y-6 p-6">
                    <Dialog.Title
                      as="h3"
                      className="text-xl font-medium leading-6 text-gray-900 dark:text-white"
                    >
                      Feedback
                    </Dialog.Title>

                    <div className="space-y-2">
                      <p className="text-gray-600 dark:text-neutral-400">
                        Please note, the old version of Customer Portal will be
                        phased out before the end of the year.
                      </p>
                      <p className="text-gray-600 dark:text-neutral-400">
                        To help us assess and improve Customer Portal, please
                        select the option below that best fits why you wish to
                        use the old version. If you require additional follow up
                        please reach out to your Relationship Manager.
                      </p>
                    </div>

                    <div className="flex flex-col gap-x-5 gap-y-2 md:flex-row">
                      {feedbackOptions.map(feedback => (
                        <Tooltip.Provider key={feedback.id}>
                          <Tooltip.Root delayDuration={0}>
                            <Tooltip.Trigger asChild>
                              <Button
                                size="large"
                                variant="primary"
                                className={classNames(
                                  'grow',
                                  optionValue === feedback.name
                                    ? 'bg-primary-800 hover:bg-primary-800'
                                    : '',
                                )}
                                onClick={() => setOptionValue(feedback.name)}
                              >
                                {feedback.name}
                              </Button>
                            </Tooltip.Trigger>
                            <Tooltip.Portal>
                              <Tooltip.Content
                                className="z-20 max-w-xs select-none rounded bg-neutral-800 px-3 py-2 text-sm shadow-lg dark:bg-neutral-900"
                                sideOffset={5}
                              >
                                <p className="text-white">{feedback.desc}</p>
                                <Tooltip.Arrow className="fill-neutral-800 dark:fill-neutral-900" />
                              </Tooltip.Content>
                            </Tooltip.Portal>
                          </Tooltip.Root>
                        </Tooltip.Provider>
                      ))}
                    </div>
                  </div>

                  <div className="px-6 pb-6">
                    <Textarea
                      rows={3}
                      label="Feedback comments"
                      srOnlyLabel="Comments"
                      placeholder="Comments if any"
                      id="feedback-comments"
                      style={{ resize: 'none' }}
                      maxLength={100}
                      value={textContent}
                      onChange={e => setTextContent(e.target.value)}
                    />
                  </div>

                  <div className="flex gap-2 bg-gray-100 px-6 py-4 dark:bg-neutral-900 sm:justify-end">
                    <Button
                      variant="secondary"
                      onClick={() => {
                        setIsOpen(false);
                        setOptionValue('');
                        setTextContent('');
                      }}
                      className="grow sm:grow-0"
                    >
                      Cancel
                    </Button>

                    <Button
                      variant="primary"
                      className="grow sm:grow-0"
                      disabled={!optionValue && !textContent.trim()}
                      onClick={() => {
                        onButtonClickHandler();
                      }}
                    >
                      Save
                    </Button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
