export const twoFText = `Two-factor authentication is an enhanced security measure which
prompts users to enter a verification code when logging in. You
can choose whether this code is sent to your email (default) or
provided by an authentication app.`;

export const primaryEmailText = `Once you changed the email address, you will be asked to
Re-onboard into the application using the link sent to the new
email address. You must follow the setup process to complete this
change.`;

export const changeSettingsEmailMsg = `Are you sure, you want to change your email address? An email will be sent to the new email address. You must follow the setup process to complete this change.`;

export const changeSettingsEmailSuccessMsg = `The user's email address was successfully changed, and a confirmation email with an onboarding link was sent to your new email address.`;

export const successMsg = 'Tutorial uploaded successfully.';

export const editUrlSuccessMsg = 'Url has been upated successfully';

export const deleteMsg = 'Document deleted successfully.';

export const tutorialItemDeleteMsg = 'Tutorial deleted successfully.';

export const sectionDeleteMsg = 'Section deleted successfully.';

export const nameWarning = 'File name already exist.';

export const fileErrorMsg = 'Maximum file size should be 15MB';

export const fileUploadInfo =
  'File should be PDF and maximum file size is 15MB.';

export const fileFormatErrorMsg = 'File format is not supported.';

export const MAX_FILES = 20; // Maximum number of files allowed

export const MAX_SECTIONS = 5;

export const dropzoneMsg =
  'Drag and drop the file here, or click to select the file.';

export const fileLimitWarningMsg =
  'You have reached the limit of 20 files or URLs in Tutorials.';

export const fileLimitInfoMsg = 'You can upload up to 20 files or URLs.';

export const sectionLimitWarningMsg =
  'Please select an existing section from the list, as the maximum of 5 sections have already been created.';

export const sectionLimitInfoMsg =
  'Please select an existing section from the list or create a new section by typing its name. You can add up to 5 Sections';

export const addSectionMsg =
  'Please select an existing section from the list or create a new section by typing its name.';

export const fileDoesNotExistMsg =
  'The file you are trying to download does not exist. Please notify the technical team of the same. Apologies for the inconvenience caused.';

export function isValidURL(url: any) {
  const urlPattern = /^(https?:\/\/)?([\w-]+\.)+[\w-]+(\/[\w- .\/?%&=]*)?$/;
  const regex = new RegExp(
    '^(https?:\\/\\/)?' +
      '(www\\.)' +
      '(([a-zA-Z0-9-]+)\\.)+[a-zA-Z]{2,}' +
      '(\\:[0-9]{1,5})?' +
      '(\\/[-a-zA-Z0-9@:%._+~#=]*)?' +
      '(\\?[;&a-zA-Z0-9%_.,~+=-]*)?' +
      '(\\#[-a-zA-Z0-9_]*)?$',
  );

  return urlPattern.test(url);
}
