import React, {
  ReactElement,
  useState,
  Fragment,
  useCallback,
  useRef,
  useEffect,
  useMemo,
} from 'react';
import { useParams } from 'react-router-dom';
import {
  FunnelIcon,
  ArrowPathIcon,
  ArrowDownTrayIcon,
} from '@heroicons/react/20/solid';
import { Disclosure } from '@headlessui/react';

import {
  Button,
  EmptyState,
  Spinner,
  Skeleton,
  ConfirmAlert,
} from 'src/ui/components';
import {
  CallDocumentItem,
  UserActions,
  CallDocumentFilter,
  PrintPreview,
} from './components';

import { useTitle } from 'src/hooks';
import { useProgressStore } from 'src/hooks/useProgressStore';
import {
  useGetCallDocumentList,
  useGetCallTypeDescription,
  useCallDocDownloadAsCsv,
  useCallDocDownload,
  useGetTagList,
  useGetCallDocumentPreview,
  useCallDocFileDownload,
  useChangeReadStatus,
} from './api';
import { downloadCallDoc } from './store/apiStore';

import { locList } from './utils/callDocumentConstants';
import { useCallDocumentFilterReducer } from './utils/useCallDocumentFilterReducer';
import { showSuccessToast, showErrorToast } from 'src/utils/ToastNotification';
import { base64ToArrayBuffer } from 'src/utils/convertToBinaryFormat';
import {
  formatSelectOptions,
  removeDuplicates,
} from './utils/callDocumentHelperFunctions';
import { downloadCsvMsg, bulkDownloadMsg } from 'src/utils/appConstants';

import { useAuth } from 'src/hooks';

import { useCallDocStore } from './store/callDocStore';

import { CallDocumentType } from './types/callDocumentList';

export default function CallDocuments(): ReactElement {
  useTitle('Call Documents');

  const { id } = useParams();
  const { token, tokenType } = useAuth();

  const tbodyRef = useRef() as React.MutableRefObject<HTMLTableSectionElement>;

  const [selectedDocs, setSelectedDocs] = useState<Array<CallDocumentType>>([]);

  const [selectAll, setSelectAll] = useState<boolean>(false);

  const [includedStickyNotes, setIncludedStickyNotes] =
    useState<boolean>(false);

  //getting the donwload option stored in localstorage.
  const downloadOptions =
    (window.localStorage.getItem('selectedDowloadFormat') &&
      window.localStorage.getItem('selectedDowloadFormat')) ||
    'html';

  const [selectedFormat, setSelectedFormat] = useState<string>(downloadOptions);

  const [isScroll, setIsScroll] = useState<boolean>(false);

  const [previewCallId, setPreviewCallId] = useState<number | undefined>(
    id ? parseInt(id) : undefined,
  );

  const { mutations } = useProgressStore();

  const isFromEmail = useCallDocStore(
    useCallback(state => state.isFromEmail, []),
  );

  const setIsFromEmail = useCallDocStore(
    useCallback(state => state.setIsFromEmail, []),
  );

  const navigationDir = useCallDocStore(
    useCallback(state => state.navigationDir, []),
  );

  const showConfirmation = useCallDocStore(
    useCallback(state => state.showConfirmation, []),
  );

  const setShowConfirmation = useCallDocStore(
    useCallback(state => state.setShowConfirmation, []),
  );

  const setCallDocsData = useCallDocStore(
    useCallback(state => state.setCallDocsData, []),
  );

  const setAllChecked = useCallDocStore(
    useCallback(state => state.setAllChecked, []),
  );

  const isAllCheckedStatus = useCallDocStore(
    useCallback(state => state.isAllChecked, []),
  );

  const {
    data: previewContent,
    refetch: onDocumentPreview,
    isLoading: isPreviewLoading,
    isRefetching: isDocPreviewFetching,
  } = useGetCallDocumentPreview(previewCallId, isFromEmail, navigationDir);

  // preview of calldocument from email.
  useEffect(() => {
    if (id) {
      setIsFromEmail(true);
      setTimeout(() => {
        onDocumentPreview();
      }, 1000);
    }
  }, [id, onDocumentPreview, setIsFromEmail]);

  const {
    filterOptions,
    filterCount,
    setValues,
    resetFilters,
    setCallTypes,
    setLoc,
    setTagStatus,
  } = useCallDocumentFilterReducer();

  const isSelected = useMemo(() => {
    if (
      filterOptions.callType?.length === 0 ||
      filterOptions.levelOfCare?.length === 0 ||
      filterOptions.tagStatus?.length === 0
    ) {
      return false;
    } else return true;
  }, [
    filterOptions.callType?.length,
    filterOptions.levelOfCare?.length,
    filterOptions.tagStatus?.length,
  ]);

  //call description api
  const { data: callTypeDescription, isLoading: isCallTypeDescriptionLoading } =
    useGetCallTypeDescription();

  //taglist api
  const { data: tagList } = useGetTagList();

  const {
    data: callDocuments,
    isLoading: isListLoading,
    isFetching,
    isRefetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useGetCallDocumentList(filterOptions, callTypeDescription, isSelected);

  const { mutateAsync: fileDownloadData, isLoading: iscallDocDownloadLoading } =
    useCallDocFileDownload();

  const { mutate: downloadListAsCsv, isLoading: isDownloadAsCsvLoading } =
    useCallDocDownloadAsCsv();

  const { mutate: downloadCallDocs, isLoading: isDownloadLoading } =
    useCallDocDownload();

  const { mutate: changeReadStatus } = useChangeReadStatus();

  const isNonBlockingModal =
    isFetchingNextPage ||
    isRefetching ||
    isDownloadAsCsvLoading ||
    isDownloadLoading ||
    iscallDocDownloadLoading ||
    isPreviewLoading ||
    isDocPreviewFetching ||
    mutations.isSaveStickyNoteLoading ||
    mutations.isStickyNoteLoading;

  const isSkeletonLoading =
    isListLoading || isFetching || isCallTypeDescriptionLoading;

  //return calltype dropdown label list
  const callTypeOptionList = useMemo(() => {
    if (callTypeDescription)
      return formatSelectOptions(callTypeDescription, true);
  }, [callTypeDescription]);

  //return tagstatus dropdownlist
  const tagStatusOptionList = useMemo(() => {
    if (tagList) {
      return formatSelectOptions(tagList);
    }
  }, [tagList]);

  const isStickyNotes = useMemo(() => {
    let ifStickyNotes: Array<CallDocumentType | undefined> = [];
    if (selectedDocs?.length) {
      ifStickyNotes = selectedDocs?.filter(item => item?.StickyNote?.length);
    }
    if (ifStickyNotes.length) return true;
    else return false;
  }, [selectedDocs]);

  //Initializing filter dopdowns
  useEffect(() => {
    if (callTypeOptionList && !filterOptions.callType) {
      setCallTypes([...callTypeOptionList]);
    }
  }, [callTypeOptionList, filterOptions.callType, setCallTypes]);

  useEffect(() => {
    if (tagStatusOptionList && !filterOptions.tagStatus) {
      setTagStatus([...tagStatusOptionList]);
    }
  }, [filterOptions.tagStatus, setTagStatus, tagStatusOptionList]);

  useEffect(() => {
    if (locList && !filterOptions.levelOfCare) {
      setLoc([...locList]);
    }
  }, [filterOptions.levelOfCare, setLoc]);

  const handleScroll = useCallback(
    (e: any) => {
      if (tbodyRef.current === e.target) {
        const bottom =
          e.target.clientHeight - 10 <
            e.target.scrollHeight - e.target.scrollTop &&
          e.target.scrollHeight - e.target.scrollTop <
            e.target.clientHeight + 10;
        if (bottom && !isFetchingNextPage) {
          if (hasNextPage) {
            fetchNextPage();
            setIsScroll(true);
          }
          if (!hasNextPage && isScroll) setIsScroll(false);
        }
      }
    },
    [fetchNextPage, hasNextPage, isFetchingNextPage, isScroll],
  );

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, true);
    return () => {
      window.removeEventListener('scroll', handleScroll, true);
    };
  }, [handleScroll]);

  function handleRowClick(item: CallDocumentType) {
    if (!item?.IsPurged) {
      let selected: Array<CallDocumentType> = [];
      const index = selectedDocs.findIndex(doc => doc.Id === item.Id);
      selected = [...selectedDocs];
      if (index === -1) {
        selected.push(item);
      } else if (index >= 0) {
        selected.splice(index, 1);
        if (selectAll) setSelectAll(false);
      }
      setSelectedDocs(selected);
    }
  }

  function findCallType(callType: string) {
    const callTypeArray = callType?.split(',');
    const selected = callTypeArray
      ?.map((item: string) => {
        return callTypeDescription?.filter(type => {
          if (item === type.Abbreviation) return type;
        });
      })
      .flat();
    return selected;
  }

  function handleDownloadDocumentsAsCsv() {
    downloadListAsCsv(
      { filterOptions, callTypeDescription },
      {
        onSuccess: () => {
          showSuccessToast({
            message: downloadCsvMsg,
          });
        },
        onError: error => {
          showErrorToast({
            message: error.message,
          });
        },
      },
    );
  }

  const handleDownloadDocument = (stickyNotes: boolean) => {
    const commonObjects = callDocsData?.filter(objB =>
      selectedDocs?.some(objA => objA?.Id === objB?.Id),
    );
    const selectedDocIds = commonObjects?.map(item => item.Id);

    if (selectedDocIds && selectedDocIds?.length > 15) {
      downloadCallDocs(
        {
          filterOptions,
          callTypeDescription,
          includeStickyNotes: stickyNotes,
          exportFormat: selectedFormat,
          selectedDocIds,
        },
        {
          onSuccess: () => {
            showSuccessToast({
              message: bulkDownloadMsg,
            });
            setIncludedStickyNotes(false);
            setSelectedDocs([]);
          },
          onError: error => {
            showErrorToast({
              message: error.message,
            });
          },
        },
      );
    } else {
      if (selectedFormat === 'doc') {
        handleDocDownload(stickyNotes);
        return;
      }

      let fileSet: Array<any> = [];
      selectedDocIds?.map(async value => {
        let downloadUrl: string = '';
        if (stickyNotes === false) {
          downloadUrl = `CallDocuments/Download?callId=${value}&exportFormat=${selectedFormat}`;
        } else {
          downloadUrl = `CallDocuments/Download?callId=${value}&exportFormat=${selectedFormat}&includeStikyNote=${includedStickyNotes}`;
        }
        const newTab = window.open(downloadUrl);
        newTab?.addEventListener('load', async () => {
          newTab.close();
        });
        const input = {
          id: value.toString(),
          format: selectedFormat,
          includeStickyNote: stickyNotes,
        };
        const file = fileDownloadData(input).then(eachFile => {
          let blob: any;
          if (eachFile.Status === 'Success') {
            let sample = base64ToArrayBuffer(eachFile.FileContent);
            if (selectedFormat === 'pdf') {
              blob = new Blob([sample], {
                type: 'pdf',
              });
            } else if (selectedFormat === 'html') {
              blob = new Blob([sample], {
                type: 'html',
              });
            }
          }
          const link = document.createElement('a');
          const url = window.URL.createObjectURL(blob);
          link.href = url;
          const fileName = eachFile.FileName;
          link.download = fileName;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
        });
        fileSet.push(file);
      });

      !isAllCheckedStatus && setSelectedDocs([]);
    }
  };

  //for document download
  const handleDocDownload = async (stickyNotes: boolean) => {
    const commonObjects = callDocsData?.filter(objB =>
      selectedDocs?.some(objA => objA?.Id === objB?.Id),
    );
    const selectedDocIds = commonObjects?.map(item => item.Id);
    const downloadPromises: any = selectedDocIds?.map(async item => {
      let downloadUrl: string = '';
      if (stickyNotes === false) {
        downloadUrl = `CallDocuments/Download?callId=${item}&exportFormat=${selectedFormat}`;
      } else {
        downloadUrl = `CallDocuments/Download?callId=${item}&exportFormat=${selectedFormat}&includeStikyNote=${includedStickyNotes}`;
      }
      const newTab = window.open(downloadUrl);
      newTab?.addEventListener('load', async () => {
        newTab.close();
      });
      const response = await downloadCallDoc({
        token: token,
        tokenType: tokenType,
        id: item.toString(),
        format: selectedFormat,
        includeStickyNote: stickyNotes,
      });
      const contentDisposition =
        response.headers.get('Content-Disposition') || '';
      const docContent = await response.text(); // since the output is html.

      // Create a Blob and trigger a download
      const blob = new Blob([docContent], { type: 'application/msword' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = `${contentDisposition?.split('=')[1]}.doc`;
      link.click();
      URL.revokeObjectURL(link.href);
    });
    await Promise.all(downloadPromises);
    !isAllCheckedStatus && setSelectedDocs([]);
  };

  function isStickyNotePresent() {
    if (isStickyNotes) {
      setIncludedStickyNotes(true);
    } else {
      handleDownloadDocument(false);
    }
  }

  const callDocsData = useMemo(() => {
    const tempData = callDocuments?.pages?.map(page => page?.data)?.flat();
    if (tempData) {
      setCallDocsData(tempData);
      return tempData;
    }
  }, [callDocuments?.pages, setCallDocsData]);

  function isAllChecked() {
    const nonPurgedDocs =
      callDocsData && callDocsData?.filter(item => item?.IsPurged !== true);
    const selectedCallDocs = nonPurgedDocs?.filter(item => {
      const existedDocs = selectedDocs.find(
        selectedItem => selectedItem.Id === item.Id,
      );

      if (existedDocs !== undefined) return existedDocs;
    });
    if (!isListLoading) {
      if (
        nonPurgedDocs &&
        nonPurgedDocs?.length > 0 &&
        selectedDocs?.length >= nonPurgedDocs?.length &&
        (selectedCallDocs && selectedCallDocs.length) ===
          (nonPurgedDocs && nonPurgedDocs.length)
      ) {
        setAllChecked(true);
        setSelectAll(true); //for automatically checking the select all checkbox when scrolling
        return true;
      } else {
        setAllChecked(false);
        setSelectAll(false);
        return false;
      }
    }
    setAllChecked(false);
    return false;
  }

  useEffect(() => {
    if (selectAll === true && callDocsData && !isListLoading && isScroll) {
      const nonPurgedDocs =
        callDocsData && callDocsData.length > 0
          ? callDocsData.filter(item => item?.IsPurged !== true)
          : [];
      setSelectedDocs([...nonPurgedDocs]);
    }
  }, [callDocsData, selectAll, isListLoading, isScroll]);

  const lenghtVal = useMemo(() => {
    const listedData =
      callDocsData && callDocsData?.filter(item => item?.IsPurged !== true);
    const commonObjects = listedData?.filter(obj1 =>
      selectedDocs?.some(obj2 => obj2.Id === obj1.Id),
    );
    return commonObjects?.length;
  }, [callDocsData, selectedDocs]);

  const handleSelectAll = () => {
    let allDocs: Array<CallDocumentType> = [];
    const isSelect = isAllChecked();
    allDocs =
      callDocsData && callDocsData.length > 0
        ? callDocsData.filter(item => item?.IsPurged !== true)
        : [];

    const filteredArray = selectedDocs?.filter(item1 => {
      // Check if the item in array1 is not in array2
      return !allDocs?.some(item2 => item1.Id === item2.Id);
    });

    if (!isSelect) {
      setSelectAll(true);
      filterCount > 0
        ? setSelectedDocs(removeDuplicates([...selectedDocs, ...allDocs], 'Id'))
        : setSelectedDocs([...allDocs]);
    } else if (filterCount === 0) {
      allDocs = [];
      setSelectAll(false);
      setSelectedDocs([...allDocs]);
    } else {
      setSelectAll(false);
      setSelectedDocs([...filteredArray]);
    }
  };

  function renderHeader(): ReactElement {
    return (
      <Disclosure as="div" className="relative">
        {({ open }) => (
          <>
            <div className="flex items-center justify-between gap-4 border-t border-gray-200 bg-white px-2 py-1 shadow dark:border-neutral-700/50 dark:bg-neutral-800 sm:px-3 lg:px-4">
              <div className="flex min-w-0 flex-1 items-end gap-4 sm:gap-8">
                <h2 className="truncate text-lg font-semibold text-gray-950 dark:text-white sm:text-xl sm:tracking-tight">
                  Call documents
                </h2>

                <div className="mt-1 flex items-center gap-4 sm:mt-0">
                  <div className="relative inline-flex">
                    <Disclosure.Button
                      as={Button}
                      size="small"
                      variant={open ? 'primary' : 'subtle'}
                    >
                      <FunnelIcon
                        className="-ml-0.5 h-5 w-5"
                        aria-hidden="true"
                      />

                      <span className="hidden sm:inline-block">Filter</span>
                    </Disclosure.Button>

                    <span className="absolute right-0 top-0 -mr-1.5 -mt-1.5 flex h-5 w-5">
                      {filterCount > 0 ? (
                        <span
                          className={`relative inline-flex h-5 w-5 items-center justify-center rounded-full bg-red-600 text-xs font-bold leading-none text-white`}
                        >
                          {filterCount}
                        </span>
                      ) : null}
                    </span>
                  </div>

                  <Button
                    variant="subtle"
                    size="small"
                    onClick={() => {
                      setSelectedDocs([]);
                      setSelectAll(false);
                      resetFilters();
                    }}
                  >
                    <ArrowPathIcon
                      className="-ml-0.5 h-5 w-5"
                      aria-hidden="true"
                    />

                    <span className="hidden sm:block">Reset</span>
                  </Button>
                </div>
              </div>

              {selectedDocs.length === 0 ? (
                <Button
                  variant="subtle"
                  size="small"
                  onClick={() => handleDownloadDocumentsAsCsv()}
                >
                  <ArrowDownTrayIcon
                    className="-ml-0.5 h-5 w-5"
                    aria-hidden="true"
                  />

                  <span className="hidden sm:block">Download as CSV</span>
                </Button>
              ) : null}
            </div>

            <CallDocumentFilter
              setValues={setValues}
              filterOptions={filterOptions}
              setLoc={setLoc}
              setCallTypes={setCallTypes}
              setTagStatus={setTagStatus}
              tagStatusOptionList={tagStatusOptionList}
              callTypeOptionList={callTypeOptionList}
              resetFilters={resetFilters}
            />
          </>
        )}
      </Disclosure>
    );
  }

  function RenderStickyNoteAlert(): ReactElement {
    return (
      <ConfirmAlert
        message="Would you like Call Document(s) to include sticky notes?"
        showModel={includedStickyNotes}
        setShowModel={() => setIncludedStickyNotes(false)}
        buttonSet={[
          {
            label: 'No',
            handleClick: () => {
              handleDownloadDocument(false);
              setIncludedStickyNotes(false);
            },
            variant: 'secondary',
          },
          {
            label: 'Yes',
            handleClick: () => {
              handleDownloadDocument(true);
              setIncludedStickyNotes(false);
            },
            variant: 'primary',
          },
        ]}
      />
    );
  }

  return (
    <>
      {renderHeader()}
      <div className="min-h-[46px]">
        {isSelected && callDocsData && callDocsData?.length > 0 && (
          <UserActions
            selectedDocs={selectedDocs}
            handleSelectAll={handleSelectAll}
            isAllChecked={isAllChecked}
            tagList={tagList}
            setSelectedDocs={setSelectedDocs}
            selectedCount={lenghtVal ? lenghtVal : 0}
            isStickyNotePresent={isStickyNotePresent}
            setSelectedFormat={setSelectedFormat}
            selectedFormat={selectedFormat}
            changeReadStatus={changeReadStatus}
          />
        )}
      </div>
      <div
        className="relative flex-1 space-y-2 overflow-y-scroll px-2 pb-2 sm:px-3 sm:pb-3 lg:px-4 lg:pb-4"
        ref={tbodyRef}
      >
        {!isCallTypeDescriptionLoading &&
        !isListLoading &&
        callDocuments?.pages ? (
          <>
            {callDocuments?.pages.map((page: any, index: number) => (
              <Fragment key={`${page} - ${index + 1}`}>
                {isSelected && page?.data && page?.data?.length ? (
                  page?.data.map(
                    (callDocInfo: CallDocumentType, indx: number) => (
                      <CallDocumentItem
                        key={`${callDocInfo.Id}-${indx + 1}`}
                        callDocInfo={callDocInfo}
                        findCallType={findCallType}
                        handleRowClick={handleRowClick}
                        selectedDocs={selectedDocs}
                        callDocuments={callDocsData}
                        indexVal={indx}
                        onDocumentPreview={onDocumentPreview}
                        setPreviewCallId={setPreviewCallId}
                        previewContent={previewContent}
                        isDocPreviewLoading={isDocPreviewFetching}
                        setSelectedDocs={setSelectedDocs}
                        changeReadStatus={changeReadStatus}
                        reqCallDocId={id}
                      />
                    ),
                  )
                ) : (
                  <div className="absolute inset-0 grid place-content-center">
                    <EmptyState message="No information available" />
                  </div>
                )}
              </Fragment>
            ))}
          </>
        ) : (
          <>
            {isSkeletonLoading && <Skeleton count={8} />}
            {!isSelected && !isSkeletonLoading && (
              <div className="absolute inset-0 grid place-items-center">
                <EmptyState message="No information available" />
              </div>
            )}
          </>
        )}

        {isNonBlockingModal && (
          <div className="fixed inset-0 z-20 grid place-items-center">
            <Spinner size="large" />
          </div>
        )}

        {isFromEmail && (
          <>
            {!showConfirmation ? (
              <PrintPreview
                report={previewContent}
                callDocuments={callDocsData}
                isDocPreviewLoading={isPreviewLoading}
                onDocumentPreview={onDocumentPreview}
                setPreviewCallId={setPreviewCallId}
                changeReadStatus={changeReadStatus}
                reqCallDocId={id}
              />
            ) : (
              <ConfirmAlert
                message="Call is Purged. File is Not available."
                showModel={showConfirmation}
                setShowModel={setShowConfirmation}
                buttonSet={[
                  {
                    label: 'Ok',
                    handleClick: () => {
                      setShowConfirmation(false);
                    },
                    variant: 'primary',
                  },
                ]}
              />
            )}
          </>
        )}
      </div>

      {includedStickyNotes && <RenderStickyNoteAlert />}
    </>
  );
}
