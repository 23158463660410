import React, { ReactElement, SyntheticEvent, useRef, useState } from 'react';
import {
  ArrowSmallUpIcon,
  ArrowSmallDownIcon,
  XMarkIcon,
} from '@heroicons/react/20/solid';
import { Disclosure } from '@headlessui/react';

import {
  Button,
  CustomDatePicker,
  Select,
  TextField,
  DateRangePicker,
  DateOfBirthPicker,
} from 'src/ui/components';

import { sortOptions } from '../utils/personAlertConstants';

import { AlertFilterOptionType } from '../types/personAlerts';

type PersonAlertFilterType = {
  filterOptions?: AlertFilterOptionType;
  setValues: (type: keyof AlertFilterOptionType, value: string) => void;
  resetFilters: () => void;
};

function Filter({
  filterOptions,
  setValues,
  resetFilters,
}: PersonAlertFilterType): ReactElement {
  const wrapperRef = useRef<any>();
  function renderTextField({
    label,
    id,
    value,
  }: {
    label: string;
    id: keyof AlertFilterOptionType;
    value: any;
  }) {
    return (
      <TextField
        inputSize="small"
        className="w-full sm:w-auto"
        type="search"
        id={id}
        placeholder={label}
        value={value}
        onChange={e => setValues(id, e.target.value)}
      />
    );
  }

  function renderDateRangePicker({
    id,
    key,
    value,
    title,
  }: {
    id: string;
    title: string;
    key?: string;
    value?: string;
  }): ReactElement {
    return (
      <DateRangePicker
        id={id}
        rangeType="past"
        format="MM/DD/YYYY HH:mm:ss"
        value={value?.length ? value : []}
        setValues={setValues}
        keyValue={key}
        size="small"
        placeholder={title}
        placement={key === 'updatedDate' ? 'center' : undefined}
      />
    );
  }

  function renderSort(): ReactElement {
    return (
      <>
        <div className="flex items-end gap-2">
          <Select
            inputSize="small"
            label="Sort by"
            id="filter-status"
            options={sortOptions}
            className="w-full sm:w-auto"
            value={filterOptions?.sortType}
            onChange={(e: SyntheticEvent<HTMLSelectElement>) =>
              setValues('sortType', e.currentTarget.value)
            }
          />
          <Button
            variant={
              filterOptions?.sortOrder === 'asc' ? 'primary' : 'secondary'
            }
            size="small"
            onClick={() => setValues('sortOrder', 'asc')}
          >
            <ArrowSmallUpIcon className="h-5 w-5" aria-hidden="true" />
            <span className="sr-only">Sort in ascending order</span>
          </Button>
          <Button
            variant={
              filterOptions?.sortOrder === 'desc' ? 'primary' : 'secondary'
            }
            size="small"
            onClick={() => setValues('sortOrder', 'desc')}
          >
            <ArrowSmallDownIcon className="h-5 w-5" aria-hidden="true" />
            <span className="sr-only">Sort in descending order</span>
          </Button>
        </div>
      </>
    );
  }

  return (
    <>
      <Disclosure.Panel
        as="div"
        className="fixed inset-0 z-10 flex w-full flex-col bg-white pb-2 shadow-xl dark:bg-neutral-800 sm:static sm:block sm:pb-3 sm:shadow-none"
      >
        {({ close }) => (
          <>
            <div className="flex items-center border-b border-b-light-light py-1 pe-1 ps-2 dark:border-b-dark-medium sm:hidden">
              <h3 className="grow text-lg font-medium">Filter</h3>
              <Button
                variant="subtle"
                onClick={() => {
                  close();
                }}
              >
                <XMarkIcon className="h-5 w-5" />
              </Button>
            </div>
            <div
              className="min-h-0 grow overflow-y-auto p-2 pb-3 sm:overflow-y-visible sm:px-3 lg:px-4"
              ref={wrapperRef}
            >
              {renderSort()}
              <div
                className="my-4 border-b border-gray-200 dark:border-neutral-700/50"
                aria-hidden="true"
              />
              <div className="flex grow flex-wrap items-end gap-3 overflow-y-auto">
                <div className="flex w-full flex-col gap-1 sm:w-auto">
                  <label htmlFor="" className="text-xs font-medium">
                    CID - PID
                  </label>
                  {renderTextField({
                    label: 'CID',
                    id: 'cid',
                    value: filterOptions?.cid,
                  })}
                  {renderTextField({
                    label: 'PID',
                    id: 'pid',
                    value: filterOptions?.pid,
                  })}
                </div>
                <div className="flex w-full flex-col gap-1 sm:w-auto">
                  <label htmlFor="" className="text-xs font-medium">
                    Alert number
                  </label>
                  {renderTextField({
                    label: 'Alert number',
                    id: 'alertNumber',
                    value: filterOptions?.alertNumber,
                  })}
                </div>
                <div className="flex w-full flex-col gap-1 sm:w-auto">
                  <label htmlFor="" className="text-xs font-medium">
                    Name
                  </label>
                  {renderTextField({
                    label: 'First name',
                    id: 'firstName',
                    value: filterOptions?.firstName,
                  })}
                  {renderTextField({
                    label: 'Last name',
                    id: 'lastName',
                    value: filterOptions?.lastName,
                  })}
                  {renderTextField({
                    label: 'Alternate name',
                    id: 'preferredName',
                    value: filterOptions?.preferredName,
                  })}
                </div>
                <div className="flex w-full flex-col sm:w-auto">
                  <label htmlFor="" className="mb-1 text-xs font-medium">
                    Birth date
                  </label>
                  {/* <CustomDatePicker
                    value={filterOptions?.birthDate}
                    placeholder="Birth date"
                    inputSize="small"
                    onChange={e => setValues('birthDate', e)}
                  /> */}
                  <DateOfBirthPicker
                    onChange={e => setValues('birthDate', e)}
                    value={filterOptions?.birthDate}
                    container={wrapperRef}
                  />
                </div>
                <div className="flex w-full flex-col gap-1 sm:w-auto">
                  <label htmlFor="" className="text-xs font-medium">
                    Entered - Start - Expiration date
                  </label>
                  {renderDateRangePicker({
                    id: 'enteredDate',
                    title: 'Entered date',
                    key: 'enteredDate',
                    value: filterOptions?.enteredDate,
                  })}

                  {renderDateRangePicker({
                    id: 'startDate',
                    title: 'Start date',
                    key: 'startDate',
                    value: filterOptions?.startDate,
                  })}

                  {renderDateRangePicker({
                    id: 'expirationDate',
                    title: 'Expiration date',
                    key: 'expirationDate',
                    value: filterOptions?.expirationDate,
                  })}
                </div>

                <div className="flex w-full flex-col gap-1 sm:w-auto">
                  <label htmlFor="" className="text-xs font-medium">
                    Updated by - Updated date
                  </label>
                  {renderTextField({
                    label: 'Updated by',
                    id: 'updatedBy',
                    value: filterOptions?.updatedBy,
                  })}
                  {renderDateRangePicker({
                    id: 'updatedDate',
                    title: 'Updated date',
                    key: 'updatedDate',
                    value: filterOptions?.updatedDate,
                  })}
                </div>

                <div className="flex w-full flex-col gap-1 sm:w-auto">
                  <label htmlFor="" className="text-xs font-medium">
                    Situation
                  </label>
                  {renderTextField({
                    label: 'Situation Description',
                    id: 'situationDescription',
                    value: filterOptions?.situationDescription,
                  })}
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-1 border-t border-t-light-light p-2 dark:border-b-dark-medium sm:hidden">
              <Button
                className="grow"
                variant="link"
                onClick={() => {
                  resetFilters();
                }}
              >
                Reset
              </Button>
              <div className="flex gap-1">
                <Button
                  className="grow"
                  onClick={() => {
                    close();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  className="grow"
                  variant="primary"
                  onClick={() => {
                    close();
                  }}
                >
                  Apply
                </Button>
              </div>
            </div>
          </>
        )}
      </Disclosure.Panel>
    </>
  );
}

export { Filter };
