import React from 'react';
import {
  BrowserRouter,
  Link,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom';

import { Spinner } from 'src/ui/components';

import { Callback } from './authRoutes/callback';
import { Logout } from './authRoutes/logout';
import { AuthorizationRoute } from './authRoutes/authorizationRoute';
import { SilentRenew } from './authRoutes/silentRenew';

import { retrieveAndParseToken, useGetUserPermissions } from 'src/hooks';
import { userPermissionStore } from 'src/store/userPermissionStore';

import { useRenewTimer } from 'src/utils/authenticationHelper/renewTimer';
import { AuthServiceSingleton } from 'src/utils/authenticationHelper/authService';
import { userPermissionHelper } from 'src/utils/userPermissionHelper';

import Header from 'src/features/Header';
import Home from 'src/features/Home';
import Users from 'src/features/Users';
import AddEditUser from 'src/features/AddEditUsers';
import CallDocuments from 'src/features/CallDocuments';
import ActivityLog from 'src/features/ActivityLog';
import PersonAlerts from 'src/features/PersonAlerts';
import AddEditPersonAlert from 'src/features/AddEditPersonAlert';
import PrintPrevew from 'src/features/AddEditPersonAlert/components/PrintPreview';
import ExtraCoverageRequests from 'src/features/ExtraCoverageRequests';
import PortalAlert from 'src/features/PortalAlerts';
import ActivityTracker from 'src/features/ActivityTracker';
import Resources from 'src/features/Resources';
import PersonRepository from 'src/features/PersonRepository';
import About from 'src/features/About';
import Settings from 'src/features/Settings';
import Expired from 'src/features/Expired';
import UnauthorizedPage from 'src/features/Unauthorized';
import AddEditExtraCoverageRequest from 'src/features/AddEditExtraCoverageRequest';
import UserOnBoarding from 'src/features/UserOnBoarding';
import AddEditPortalAlert from 'src/features/AddEditPortalAlerts';
import AddEditResource from 'src/features/Resources/components/AddEditResource';
import DownloadZip from 'src/features/CallDocuments/components/DownloadZip';
import ResetPassword from 'src/features/ResetPassword';
import ForgotPassword from 'src/features/ForgotPassword';
import Footer from 'src/features/Footer';
import { ProviderConfiguration } from 'src/features/Resources/components';

const PrivatePage = () => {
  const location = useLocation();
  const isOnBoarding = location?.pathname.split('/').includes('onboarding');
  const isExpired = location?.pathname.split('/').includes('Expired', 2);
  const isHome =
    location?.pathname === '/' ||
    (location?.pathname.split('/').includes('Home') &&
      location?.pathname.split('/').length === 2);
  const isAbout = location?.pathname.split('/').includes('About');
  const isSettings = location?.pathname.split('/').includes('Settings');
  const isResetPassword = location?.pathname
    .split('/')
    .includes('ResetPassword');
  const isForgotEmailLink = location?.pathname
    .split('/')
    .includes('ForgotPassword');

  // Block token renew and setup when user is in onboarding,resetpassword & forgot password flow.
  const isBlockAuthflow = isOnBoarding || isResetPassword || isForgotEmailLink;

  useRenewTimer(isBlockAuthflow);

  const [authCompleted, setAuthCompleted] = React.useState<boolean>(false);

  const { data: permissions, isLoading: isUserPermissionLoading } =
    useGetUserPermissions(isBlockAuthflow);

  React.useEffect(() => {
    userPermissionStore.getState().setIsLoading(isUserPermissionLoading);
  }, [isUserPermissionLoading]);

  React.useEffect(() => {
    if (location?.pathname !== '/Home/Expired')
      localStorage.setItem('LastKnownLocation', location?.pathname);
  }, [location?.pathname]);

  const permissionArray = React.useMemo(() => {
    if (
      (permissions?.length && permissions?.length > 0) ||
      permissions?.length === 0
    ) {
      return userPermissionHelper(permissions);
    }
  }, [permissions]);

  React.useEffect(() => {
    console.log = function () {};
  }, []);

  React.useEffect(() => {
    const auth = retrieveAndParseToken(false);
    if (isBlockAuthflow) {
      setAuthCompleted(true);
      return;
    }
    if (
      (auth?.access_token &&
        auth?.expires_at &&
        auth?.expires_at * 1000 < new Date().getTime()) ||
      auth === null
    ) {
      AuthServiceSingleton.getInstance().signIn();
    } else {
      setAuthCompleted(true);
    }
  }, [isBlockAuthflow]);

  if (!authCompleted)
    return (
      <div className="fixed inset-0 z-20 grid place-content-center">
        <Spinner size="large" />
      </div>
    );

  return (
    <>
      {!isOnBoarding && <Header isBlockAuthflow={isBlockAuthflow} />}
      {isExpired && <Expired />}
      {!isUserPermissionLoading && isHome && !isExpired && <Home />}
      {!isUserPermissionLoading && isAbout && <About />}
      {isOnBoarding && <UserOnBoarding />}
      {isForgotEmailLink && <ForgotPassword />}
      {isResetPassword && <ResetPassword />}
      {!isHome &&
        !isAbout &&
        !isSettings &&
        !isExpired &&
        permissions?.length === 0 && <UnauthorizedPage />}
      {!isUserPermissionLoading && (
        <Routes>
          <Route path="/unauthorized" element={<UnauthorizedPage />} />
        </Routes>
      )}

      {permissionArray !== undefined ? (
        <Routes>
          {/* <Route path="/unauthorized" element={<UnauthorizedPage />} /> */}
          <Route path="/home/Download" element={<DownloadZip />} />
          <Route element={<AuthorizationRoute permission={true} />}>
            <Route path="/Settings" element={<Settings />} />
            <Route path="/Settings/:id" element={<Settings />} />
          </Route>

          <Route
            element={
              <AuthorizationRoute
                permission={permissionArray?.Administrators}
              />
            }
          >
            <Route path="/User" element={<Users />} />
            <Route path="/User/Create" element={<AddEditUser />} />
            <Route path="/User/Edit/:id" element={<AddEditUser />} />
            <Route path="/User/Copy/:id" element={<AddEditUser />} />
          </Route>

          <Route
            path="CallDocuments"
            element={
              <AuthorizationRoute
                permission={permissionArray?.CanViewDocumentTransmission}
              >
                <CallDocuments />
              </AuthorizationRoute>
            }
          />

          <Route
            path="CallDocuments/Preview/:id"
            element={
              <AuthorizationRoute
                permission={permissionArray?.CanViewDocumentTransmission}
              >
                <CallDocuments />
              </AuthorizationRoute>
            }
          />

          <Route
            path="CallDocuments/DocPreview/:id"
            element={
              <AuthorizationRoute
                permission={permissionArray?.CanViewDocumentTransmission}
              >
                <CallDocuments />
              </AuthorizationRoute>
            }
          />

          <Route
            path="/ActivityLog"
            element={
              <AuthorizationRoute
                permission={
                  permissionArray?.Administrators &&
                  permissionArray?.CanViewDocumentTransmission
                }
              >
                <ActivityLog />
              </AuthorizationRoute>
            }
          />

          <Route
            element={
              <AuthorizationRoute
                permission={permissionArray?.CanViewPersonAlerts}
              />
            }
          >
            <Route path="/PersonAlert" element={<PersonAlerts />} />
            <Route
              path="/PersonAlert/PrintPreview/:id"
              element={<PrintPrevew />}
            />
            <Route path="/PersonAlert/Preview/:id" element={<PersonAlerts />} />
          </Route>

          <Route
            element={
              <AuthorizationRoute
                permission={permissionArray?.CanSetUpPersonAlerts}
              />
            }
          >
            <Route
              path="/PersonAlert/Create"
              element={<AddEditPersonAlert />}
            />
            <Route
              path="/PersonAlert/Edit/:id"
              element={<AddEditPersonAlert />}
            />
          </Route>

          <Route
            path="/ExtraCoverageRequests"
            element={
              <AuthorizationRoute
                permission={permissionArray?.CanSendCoverageRequests}
              >
                <ExtraCoverageRequests />
              </AuthorizationRoute>
            }
          />

          <Route
            element={
              <AuthorizationRoute
                permission={permissionArray?.CanViewCoverageRequests}
              />
            }
          >
            <Route
              path="/ExtraCoverageRequests/Create"
              element={<AddEditExtraCoverageRequest />}
            />
            <Route
              path="/ExtraCoverageRequests/Approve/:id"
              element={<AddEditExtraCoverageRequest />}
            />
            <Route
              path="/ExtraCoverageRequests/Copy/:id"
              element={<AddEditExtraCoverageRequest />}
            />
          </Route>
          <Route
            element={
              <AuthorizationRoute
                permission={permissionArray?.CanManagePortalAlerts}
              />
            }
          >
            <Route path="/PortalAlert" element={<PortalAlert />} />
            <Route
              path="/PortalAlert/Edit/:id"
              element={<AddEditPortalAlert />}
            />
            <Route
              path="/PortalAlert/Create"
              element={<AddEditPortalAlert />}
            />
          </Route>

          <Route
            path="/ActivityTracker"
            element={
              <AuthorizationRoute
                permission={
                  permissionArray?.RelationshipManager ||
                  permissionArray?.CallCenterSupervisors
                }
              >
                <ActivityTracker />
              </AuthorizationRoute>
            }
          />

          <Route
            element={
              <AuthorizationRoute
                permission={
                  (permissionArray?.RelationshipManager ||
                    permissionArray?.Administrators) &&
                  permissionArray.CanEditForms
                }
              />
            }
          >
            <Route path="/Resources" element={<Resources />} />
            <Route
              path="Resources/FieldConfiguration/:id"
              element={<ProviderConfiguration />}
            />
            <Route
              path="/Resources/AddProvider"
              element={<AddEditResource />}
            />
            <Route
              path="/Resources/EditProvider/:id"
              element={<AddEditResource />}
            />
          </Route>

          <Route
            path="/onboarding"
            element={
              <AuthorizationRoute permission={true}>
                <UserOnBoarding />
              </AuthorizationRoute>
            }
          />

          <Route
            path="/User/ForgotPassword"
            element={
              <AuthorizationRoute permission={true}>
                <ForgotPassword />
              </AuthorizationRoute>
            }
          />

          <Route
            path="/User/ResetPassword"
            element={
              <AuthorizationRoute permission={true}>
                <ResetPassword />
              </AuthorizationRoute>
            }
          />

          <Route element={<AuthorizationRoute permission={true} />}>
            <Route path="/PersonRepository" element={<PersonRepository />} />
          </Route>
        </Routes>
      ) : (
        isUserPermissionLoading && (
          <div className="grid grow place-content-center">
            <Spinner size="large" />
          </div>
        )
      )}
      {!isOnBoarding && <Footer />}
    </>
  );
};

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<PrivatePage />} />
        <Route path="/authentication/callback" element={<Callback />} />
        <Route
          path="/authentication/silent_callback"
          element={<SilentRenew />}
        />
        <Route path="/logout" element={<Logout />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
