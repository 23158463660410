import { reportDownload } from 'src/utils/urls';

export async function downloadCallDoc({
  token,
  tokenType,
  id,
  format,
  includeStickyNote,
}: {
  token: string;
  tokenType: string;
  id: string;
  format: string;
  includeStickyNote: boolean;
}) {
  const response = await fetch(
    reportDownload(id.toString(), format, includeStickyNote),
    {
      method: 'GET',
      headers: {
        Authorization: `${tokenType} ${token}`,
      },
    },
  );

  return response;
}
