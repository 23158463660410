import { useInfiniteQuery, UseInfiniteQueryResult } from 'react-query';
import pDebounce from 'p-debounce';

import { useAuth } from 'src/hooks';

import { relatedCalls } from 'src/utils/urls';
import { sortDataOption } from '../utils/personRepoConstants';

import { RelatedCallType } from '../types/relatedCalls';

export type TResultType = {
  data: Array<RelatedCallType> | [] | null;
  draw: number;
  error: Error | null;
  isAutoRefreshEnabled: boolean;
  recordsFiltered: number;
  recordsTotal: number;
};
async function fetchRelatedCalls({
  token,
  tokenType,
  requestData,
  pageParam,
}: {
  token: string;
  tokenType: string;
  requestData: any;
  pageParam: number;
}): Promise<TResultType> {
  requestData.append('start', `${pageParam}`);
  const response = await fetch(relatedCalls, {
    method: 'POST',
    headers: {
      Authorization: `${tokenType} ${token}`,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: requestData,
  });
  return response.json();
}
const drawValue = 1;
const pageLength = 15;

function getColumnFromId(id: number) {
  const idsList = [
    'Case ID',
    'Call ID',
    'Start',
    'End',
    'Level of care',
    'Service type',
  ];
  const idNameList = sortDataOption?.find(item => item?.value === id)?.label;
  const index = idsList.findIndex(item => item === idNameList);
  return `${index + 1}`;
}

const debouncedFetch = pDebounce(fetchRelatedCalls, 500);

function setRequestData(
  entityId: string | null | undefined,
  sortData: sortDataType,
): FormData {
  const formData: any = new URLSearchParams();
  formData.append('draw', `${drawValue + 1}`);
  formData.append('length', `${pageLength}`);
  formData.append('columns[0][data]', 'PersonOfConcernEntityId');
  formData.append('columns[0][name]', '');
  formData.append('columns[0][searchable]', 'true');
  formData.append('columns[0][orderable]', 'true');
  formData.append('columns[0][search][regex]', 'false');
  formData.append('columns[0][search][value]', `${entityId}`);

  formData.append('columns[1][data]', 'CaseId');
  formData.append('columns[1][name]', '');
  formData.append('columns[1][searchable]', 'true');
  formData.append('columns[1][orderable]', 'true');
  formData.append('columns[1][search][regex]', 'false');
  formData.append('columns[1][search][value]', '');

  formData.append('columns[2][data]', 'Id');
  formData.append('columns[2][name]', '');
  formData.append('columns[2][searchable]', 'true');
  formData.append('columns[2][orderable]', 'true');
  formData.append('columns[2][search][regex]', 'false');
  formData.append('columns[2][search][value]', '');

  formData.append('columns[3][data]', 'Started');
  formData.append('columns[3][name]', '');
  formData.append('columns[3][searchable]', 'true');
  formData.append('columns[3][orderable]', 'true');
  formData.append('columns[3][search][regex]', 'false');
  formData.append('columns[3][search][value]', '');

  formData.append('columns[4][data]', 'Ended');
  formData.append('columns[4][name]', '');
  formData.append('columns[4][searchable]', 'true');
  formData.append('columns[4][orderable]', 'true');
  formData.append('columns[4][search][regex]', 'false');
  formData.append('columns[4][search][value]', '');

  formData.append('columns[5][data]', 'LevelOfCare');
  formData.append('columns[5][name]', '');
  formData.append('columns[5][searchable]', 'true');
  formData.append('columns[5][orderable]', 'true');
  formData.append('columns[5][search][regex]', 'false');
  formData.append('columns[5][search][value]', '');

  formData.append('columns[6][data]', 'ServiceType');
  formData.append('columns[6][name]', '');
  formData.append('columns[6][searchable]', 'true');
  formData.append('columns[6][orderable]', 'true');
  formData.append('columns[6][search][regex]', 'false');
  formData.append('columns[6][search][value]', '');

  formData.append('order[0][column]', getColumnFromId(sortData.sortType));
  formData.append('order[0][dir]', `${sortData.sortOrder}`);
  formData.append(
    'search[value]',
    sortData && sortData.searchValue ? sortData.searchValue : '',
  );
  formData.append('search[regex]', 'false');

  return formData;
}
type sortDataType = {
  sortOrder: string;
  sortType: number;
  searchValue?: string | null;
};
function useGetFetchRelatedCalls(
  entityId: string | null | undefined,
  sortData: sortDataType,
): UseInfiniteQueryResult<TResultType, unknown> {
  const { token, tokenType } = useAuth();

  return useInfiniteQuery(
    ['get_relatedcalls', entityId, sortData],
    ({ pageParam = 0 }) => {
      const requestData = setRequestData(entityId, sortData);
      return debouncedFetch({
        token,
        tokenType,
        requestData,
        pageParam,
      });
    },
    {
      getNextPageParam: (
        lastPage: TResultType,
        allPages: Array<TResultType>,
      ) => {
        if (lastPage !== null && lastPage?.data?.length === pageLength)
          return allPages.length * pageLength;
      },
      getPreviousPageParam: (
        firstPage: TResultType,
        allPages: Array<TResultType>,
      ) => {
        if (firstPage !== null && firstPage?.data?.length === pageLength)
          return allPages.length - 1 * pageLength;
      },
      enabled: entityId !== undefined && entityId !== null && entityId !== '',

      staleTime: 60 * 1000,
      refetchOnWindowFocus: false,
    },
  );
}

export { useGetFetchRelatedCalls };
