import dayjs from 'dayjs';

function commonFormatterFunction(store: any) {
  const updateStoreValue = {
    prefix: isNullCheck(store.personDetails?.Prefix),
    firstName: isNullCheck(store.personDetails?.FirstName),
    lastName: isNullCheck(store.personDetails?.LastName),
    alternativeName: isNullCheck(store.personDetails?.PreferredName),
    sufix: isNullCheck(store.personDetails?.Suffix),
    dateOfBirth: isNullCheck(store.personDetails?.Birthdate)
      ? dayjs(store.personDetails?.Birthdate).format('MM/DD/YYYY')
      : '',
    gender:
      store.personDetails?.GenderId === null ||
      store.personDetails?.GenderId === '-1' ||
      store.personDetails?.GenderId === -1
        ? 0
        : parseInt(store.personDetails?.GenderId),
    tags: isNullCheck(store.personDetails?.Tags),
    street1: isNullCheck(store.personDetails?.PrimaryAddress?.Street1),
    street2: isNullCheck(store.personDetails?.PrimaryAddress?.Street2),
    street3: isNullCheck(store.personDetails?.PrimaryAddress?.Street3),
    city: isNullCheck(store.personDetails?.PrimaryAddress?.City),
    postalCode: isNullCheck(store.personDetails?.PrimaryAddress?.ZipPostalCode),
    state:
      isNullCheck(store.personDetails?.PrimaryAddress?.StateProvinceId) !== ''
        ? parseInt(store.personDetails?.PrimaryAddress?.StateProvinceId)
        : '',
    countryId:
      isNullCheck(store.personDetails?.PrimaryAddress?.CountryId) !== ''
        ? parseInt(store.personDetails?.PrimaryAddress?.CountryId)
        : '',

    county: isNullCheck(store.personDetails?.PrimaryAddress?.County),
    primaryPhnType:
      store.personDetails?.PrimaryPhone?.PhoneTypeId === null ||
      store.personDetails?.PrimaryPhone?.PhoneTypeId === '-1'
        ? null
        : parseInt(store.personDetails?.PrimaryPhone?.PhoneTypeId),
    primaryPhnCountryId: isNullCheck(
      store.personDetails?.PrimaryPhone?.CountryId,
    ),
    primaryPhnNumber: isNullCheck(store.personDetails?.PrimaryPhone?.Number),
    primaryPhnOkToLeaveMsg: isNullCheck(
      store.personDetails?.PrimaryPhone?.OkToLeaveMessage,
    ),
    primaryPhnComments: isNullCheck(
      store.personDetails?.PrimaryPhone?.Comments,
    ),
    secondaryPhnType:
      store.personDetails?.SecondaryPhone?.PhoneTypeId === null ||
      store.personDetails?.SecondaryPhone?.PhoneTypeId === '-1'
        ? null
        : parseInt(store.personDetails?.SecondaryPhone?.PhoneTypeId),
    secondaryPhnCountryId: isNullCheck(
      store.personDetails?.SecondaryPhone?.CountryId,
    ),
    secondaryPhnNumber: isNullCheck(
      store.personDetails?.SecondaryPhone?.Number,
    ),
    secondaryPhnComments: isNullCheck(
      isNullCheck(store.personDetails?.SecondaryPhone?.Comments),
    ),
    secondaryPhnOkToLeaveMsg: isNullCheck(
      store.personDetails?.SecondaryPhone?.OkToLeaveMessage,
    ),
  };

  return updateStoreValue;
}

function isNullCheck(value: any) {
  if (value === undefined || value === null) return '';
  else return value;
}

export { commonFormatterFunction };
