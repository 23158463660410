import { UseMutationResult, useMutation } from 'react-query';

import { useAuth } from 'src/hooks';

import { createFeedbackForm } from 'src/utils/urls';

async function postFeedBack({
  token,
  tokenType,
  userId,
  feedback,
  comments,
}: TArguments): Promise<any> {
  return await fetch(createFeedbackForm, {
    method: 'POST',
    headers: {
      Authorization: `${tokenType} ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      UserId: userId,
      Feedback: feedback,
      Comments: comments,
    }),
  });
}

type TArguments = {
  token: string;
  tokenType: string;
  userId: string;
  feedback: string;
  comments: string;
};

type paramsType = {
  feedback: string;
  comments: string;
};

type TError = {
  message: string;
};

function useCreateFeedback(): UseMutationResult<
  void,
  TError,
  paramsType,
  unknown
> {
  const { token, tokenType, userId } = useAuth();

  return useMutation(async (params: paramsType) => {
    postFeedBack({
      token,
      tokenType,
      userId,
      feedback: params.feedback,
      comments: params.comments,
    });
  });
}
export { useCreateFeedback };
